import List from './components/List'
import Form from './components/Form'

import i18n from '../../../translation'

export default [
  {
    path: i18n.t('app.domains.registration.routes.store.path'),
    component: Form,
    name: 'registration.store',
    meta: {
      breadcrumb: {
        name: i18n.t('app.domains.registration.routes.store.title')
      }
    }
  },
  {
    path: i18n.t('app.domains.registration.routes.index.path'),
    component: List,
    name: 'registration.index',
    meta: {
      breadcrumb: {
        name: i18n.t('app.domains.registration.routes.index.title')
      }
    },
    props: true
  }
]
