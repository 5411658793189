<script>
import MatchPairResult from './MatchPairResult';
import Ball from '../../../components/general/Ball';

export default {
  components: {MatchPairResult, Ball},
  name: 'match',
  props: {
    match: {
      type: Object
    },
    scoreboard: {
      type: Object
    },
    showCourt: {
      type: Boolean,
      default: false,
    },
    showDate: {
      type: Boolean,
      default: false,
    },
    showPlayerClass: {
      type: Boolean,
      default: false,
    },
    showRound: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    pair1Names() {
      return this.match.pair1_names
          ? this.match.round === 99 ? this.match.pair1_names.toLowerCase() : this.match.pair1_names
          : ''
    },
    pair2Names() {
      return this.match.pair2_names
          ? this.match.round === 99 ? this.match.pair2_names.toLowerCase() : this.match.pair2_names
          : ''
    },
    showScoreboard() {
      return this.scoreboard && this.scoreboard.matchId === this.match.id && this.scoreboard.visible
    },
    getSetsNumber() {
      if (this.match && Array.isArray(this.match.sets) && this.match.sets.length > 1) {
        return this.match.sets[0].sets
      }
      return 0
    }
  }
}
</script>

<template>
  <div class="matches-list-item">
    <v-layout align-center class="item-match">
      <v-flex v-if="match.metadata.loading" class="text-center pt-5 pb-5">
        <v-progress-circular
            indeterminate
            color="primary"/>
      </v-flex>
      <template v-else>
        <v-flex xs10
                :class="[
                    'item-pair1', `-sets-${getSetsNumber}`,
                    {'-hide-results': match.sets.length === 0},
                    {'text-capitalize': match.round === 99}
                    ]">
          <span v-html="pair1Names"></span>
        </v-flex>
        <v-flex xs4 class="item-versus">
          <div :class="['item-infos', `-sets-${getSetsNumber}`]">
            <template v-if="!showScoreboard">
              <match-pair-result :key="match.pair1_id"
                                 class="pair-result pair1-result"
                                 :sets="match.sets"
                                 :pair-id="match.pair1_id"/>
              <match-pair-result :key="match.pair2_id"
                                 class="pair-result pair2-result"
                                 :sets="match.sets"
                                 :pair-id="match.pair2_id"/>
            </template>
            <div class="item-court" v-if="showCourt && match.court_name" :title="match.court_name">
              {{ match.court_name }}
            </div>
            <div class="item-date" v-if="showDate && match.date">
              {{ match.date | dateEnToBr }}
            </div>
            <div class="item-hour" v-if="match.hour">{{ match.hour }}</div>
            <div :class="[!showDate ? 'item-date' : 'item-player-class']" v-if="showPlayerClass">{{
                match.player_class
              }}
            </div>
            <div class="item-round" v-if="showRound && match.round_name">
              <template v-if="match.type === 'groups' && match.round === 99">
                Chave {{ match.group }}
              </template>
              <template v-else>
                <span>{{ match.round_name }}</span>
                <span class="ml-1" v-if="match.round > 2"> - J{{ match.number }}</span>
              </template>
            </div>
            <div class="item-live" v-if="showScoreboard">
              <span class="live-dot"/><span class="text">AO VIVO</span>
            </div>
          </div>
        </v-flex>
        <v-flex xs10
                :class="[
                    'item-pair2', `-sets-${getSetsNumber}`,
                    {'-hide-results': match.sets.length === 0},
                    {'text-capitalize': match.round === 99}
                    ]">
          <span v-html="pair2Names"></span>
        </v-flex>
      </template>
    </v-layout>
    <v-layout justify-center align-center v-if="showScoreboard">
      <v-flex/>
      <v-flex sm5 xs9 class="text-center">
        <div class="scoreboard">
          <div class="item">
            <div class="names"/>
            <div class="points-label">
              Placar
            </div>
            <div class="set-label" v-for="set in scoreboard.pair1.sets">
              {{ set.number }}º Set
            </div>
          </div>
          <div class="item">
            <div class="names">
              {{ scoreboard.pair1.names }}
              <div class="ball" v-if="scoreboard.pair1.service">
                <Ball/>
              </div>
            </div>
            <div class="points">
              {{ scoreboard.pair1.points }}
            </div>
            <template v-for="set in scoreboard.pair1.sets">
              <div class="set">{{ set.value }}</div>
            </template>
          </div>
          <div class="item">
            <div class="names">
              {{ scoreboard.pair2.names }}
              <div class="ball" v-if="scoreboard.pair2.service">
                <Ball/>
              </div>
            </div>
            <div class="points">
              {{ scoreboard.pair2.points }}
            </div>
            <template v-for="set in scoreboard.pair2.sets">
              <div class="set">{{ set.value }}</div>
            </template>
          </div>
        </div>
      </v-flex>
      <v-flex/>
    </v-layout>
  </div>
</template>
