<script>
import bus from '@utils/bus'
import {QrcodeStream} from 'vue-qrcode-reader'
import UserApiService from '../../../services/domains/User'
import { mapActions } from 'vuex'

const userService = UserApiService.build({})
export default {
  components: {QrcodeStream},
  data() {
    return {
      show: false,
      scannerReady: false,
    }
  },
  mounted() {
    bus.$on('menu-show-qr-code-reader', () => this.show = true)
    bus.$emit('hide-loader')
  },
  unmounted() {
    this.show = false
  },
  methods: {
    ...mapActions(['showNotification']),
    async onInit(promise) {
      this.scannerReady = false
      bus.$emit('show-loader', {message: 'Carregando câmera...'})
      try {
        await promise
        this.scannerReady = true
      } catch (error) {
        this.showNotification({
          title: this.$t('app.domains.user.components.qrCodeReader.onInit.error.title'),
          type: 'error',
          timeout: 2000
        })
      } finally {
        bus.$emit('hide-loader')
      }
    },
    onDecode(code) {
      this.show = false
      userService.qrCodeValidate({token: code})
          .then(() => {
            this.showNotification({
              title: this.$t('app.domains.user.components.qrCodeReader.onDecode.success.title'),
              timeout: 2000
            })
          })
          .catch(e => {
            console.log(e)
          })
    },
    onError(error) {
      console.log(error)
    },
    onExit() {
      this.show = false;
    }
  }
}
</script>
<template>
  <v-dialog v-model="show" fullscreen>
    <div class="qr_code_reader_container">
      <div class="qr_code_reader_screen">
        <qrcode-stream v-if="show" @decode="onDecode" @onError="onError" @init="onInit"/>
      </div>
      <div class="qr_code_reader_square_container" v-if="scannerReady">
        <div class="qr_code_reader_square">
          <span>
            {{ $t('app.domains.user.components.qrCodeReader.instruction') }}
          </span>
          <div class="border"></div>
        </div>
      </div>
      <div class="qr_code_reader_buttons">
        <v-btn @click="onExit"
               color="white"
               elevation="2"
               outlined
               x-large
               icon>
          <i class="fas fa-times"></i>
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<style scoped lang="scss">
.qr_code_reader_container {
  display: flex;
  flex: 1;
  max-height: 100dvh;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #333;
}

.qr_code_reader_screen {
  height: 100dvh;
}

.qr_code_reader_square_container {
  position: absolute;

  .qr_code_reader_square {
    position: relative;
    height: 40dvh;
    width: 40dvh;
    text-align: center;
    span {
      color: #FFF;
      font-size: 18px;
      text-shadow: 0 0 2px #333;
    }

    .border {
      &:after {
        display: block;
        content: "";
        width: 30px;
        height: 30px;
        position: absolute;
        bottom: -10px;
        right: -10px;
        border-bottom: 5px solid #FFF;
        border-right: 5px solid #FFF;
      }
      &:before {
        display: block;
        content: "";
        width: 30px;
        height: 30px;
        position: absolute;
        bottom: -10px;
        left: -10px;
        border-bottom: 5px solid #FFF;
        border-left: 5px solid #FFF;
      }
    }
    &:before {
      display: block;
      content: "";
      width: 30px;
      height: 30px;
      position: absolute;
      top: -10px;
      left: -10px;
      border-top: 5px solid #FFF;
      border-left: 5px solid #FFF;
    }
    &:after {
      display: block;
      content: "";
      width: 30px;
      height: 30px;
      position: absolute;
      top: -10px;
      right: -10px;
      border-top: 5px solid #FFF;
      border-right: 5px solid #FFF;
    }
  }
}

.qr_code_reader_buttons {
  position: absolute;
  bottom: 50px;
}
</style>

