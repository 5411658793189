<script>
import bus from '@utils/bus'

export default {
  mounted() {
    setTimeout(() => {
      bus.$emit('hide-loader')
    }, 100)
  }
}
</script>

<template>
  <div>
    <div v-if="!$vuetify.breakpoint.xs" class="page-header flex-header list-header">
      <h2 class="headline page-title">{{ $t('app.domains.common.components.privacyPolicies.pageTitle') }}</h2>
      <div></div>
    </div>
    <v-card class="page-content-card">
      <v-container fluid grid-list-md>
        <v-layout row wrap>
          <v-flex xs12 class="common-text">
            <h3>Coleta e uso das informações</h3>
            <h4>Dados pessoais</h4>

            <p>
              Coletamos seus dados através do cadastro feio em nosso app ou através do sistema de gestão utilizado
              pelos clubes parceiros. Essas informações geralmente incluem o seu nome, email, telefone e avatar.
              O campo CPF / DNI / CI pode ser coletado caso esteja nas regras do regulamento do clube ou se o pagamento online esteja ativado.
            </p>

            <h4>Uso das informações</h4>

            <p>
              Usamos suas informações tão somente para oferecer serviços melhores. Isso inclui envio de
              mensagens automáticas relacionadas às suas reservas, aulas e inscrições em torneios (futuras ou atuais),
              tais como confirmações, lembretes e pedidos de avaliação. Essas mensagens automáticas e podem
              ser canceladas nas configurações de notificações do seu usuário.

              Eventualmente enviamos também notícias e/ou promoções para você, mas isto somente se você
              concordar em receber nossas comunicações de marketing.

              As informações que coletamos através da navegação em nosso site, nos ajuda a identificar
              suas necessidades. Assim, tentamos te oferecer somente benefícios ou informações de seu
              interesse.

              Suas informações em nossa administração interna são importantes para proteger você e o Gripo
              de eventuais fraudes.
            </p>

            <ul>
              <li>Caso deseje que sua conta e informações sejam completamente removidas do Gripo, entre em
                contato pelo nosso email:
                <a href="mailto:contato@gripo.com.br">contato@gripo.com.br</a> informando o seu e-mail/telefone de
                cadastro e o motivo da solicitação.
              </li>
            </ul>
            <br>

            <h4>A quem fornecemos suas informações</h4>

            <p>Não fornecemos a ninguém informações sobre você, suas reservas e inscrições, exceto nas seguintes
              circunstâncias.</p>

            <h4>Aos clubes</h4>

            <p>
              Fornecemos ao clube onde você faz suas reservas/aulas/inscrições em torneios, os dados pessoais (nome,
              telefone e e-mail). Os clubes necessitam destas
              informações para contato em qualquer situação. Os clubes estão cientes de que não estão
              autorizados a usar para outros fins ou fornecer suas informações a terceiros. Mesmo assim, é
              aconselhável que você se informe sobre a política de privacidade de qualquer clube ou
              fornecedor de serviços que você utilizar através do nosso site. O Gripo não participa do
              conteúdo,frequência ou horário das comunições de marketing ou do seu cancelamento nos quais
              os clubes possam vir a utilizar. Para estes assuntos, entre em contato diretamente com o
              clube em questão.
            </p>

            <p>
              Caso você esteja inscrito em torneios em algum clube parceiro do Gripo e o clube divulgar as
              informações sobre inscritos, chaves e jogos, nessa situação o nome que foi preenchido na inscrição estará
              visível em nosso app.
            </p>

            <p>
              Caso você tenha realizado a reserva de quadra/aula em algum clube parceiro do Gripo, nessa situação seu
              nome poderá estar visível na grade de horários em nosso app caso o clube tenha configurado para que seja
              exibido o nome do atleta que reservou o horário.
            </p>

            <h4>Às autoridades legais</h4>

            <p>
              As autoridades legais podem solicitar ao Gripo as informações que você disponibilizou ao
              contratar nossos serviços e, em caso de suspeitas de fraudes, podemos disponibilizar às
              autoridades legais, informações que ajudem a evitar danos aos seus, nossos interesses ou de
              terceiros.
            </p>

            <h4>A outros usuários do app</h4>

            <p>
              Todos os atletas com uma conta ativa e verificada podem convidar outros atletas para serem seus amigos ou também enviar convite para partidas.
              Ao buscar por um atleta as informações como nome, telefone e avatar podem aparecer no resultado da busca.
              Essas informações também podem estar visíveis na tela dos convites confirmados/pendentes de reservas e amizades.
            </p>

            <h4>Como protegemos suas informações</h4>

            <p>
              Para garantir a confidencialidade das suas informações pessoais, usamos tecnologias líderes
              em segurança online e processos que são regularmente avaliados e atualizados.
            </p>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>
