<script>
  import EmptyList from '../../../components/general/EmptyList'

  import UserApiService from '../../../services/domains/User'

  import bus from '@utils/bus'
  import {mapGetters, mapActions} from 'vuex'
  import {debounce} from 'lodash'

  const userService = UserApiService.build({})

  export default {
    components: {EmptyList},
    data () {
      return {
        isLoadingData: true,
        currentTab: 0,
        searchUser: '',
        searchFriends: '',
        dependencies: {
          users: []
        },
        listFriends: [],
        listRequests: []
      }
    },
    computed: {
      ...mapGetters(['currentUser']),
      listFriendsFiltered () {
        return this.searchFriends ? this.listFriends.filter(item => item.name.includes(this.searchFriends)) : this.listFriends
      }
    },
    watch: {
      searchUser: debounce(function (value) {
        this.onSearchUser(value)
      }, 300)
    },
    mounted () {
      this.loadFriends()
    },
    methods: {
      ...mapActions(['showNotification']),
      loadFriends () {
        userService.getFriends()
          .then(response => {
            this.listFriends = [...response.friends]
            this.listRequests = [...response.requests]
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
      },
      onSearchUser (query) {
        this.dependencies.users.splice(0)
        if (query) {
          let data = {name: query, cpf: query, email: query, phone: query, limit: 15}
          userService.searchUsers(data)
            .then((response) => {
              this.dependencies.users = [...response.filter(item => !this.listFriends.some(friend => friend.user_id === item.id) && !this.listRequests.some(friend => friend.user_id === item.id))]
            })
            .catch(e => {
              console.log(e)
            })
        }
      },
      storeFriend (item) {
        bus.$emit('show-loader')
        const data = {user_requested_id: item.id}
        userService.storeFriend(data)
          .then(response => {
            this.showNotification({title: response.title})
            this.dependencies.users = this.dependencies.users.filter(user => user.id !== item.id)
            this.loadFriends()
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
      },
      acceptFriend (item) {
        bus.$emit('show-loader')
        userService.acceptFriend(item.id)
          .then(response => {
            this.showNotification({title: response.title})
            this.loadFriends()
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
      },
      denyFriend (item) {
        bus.$emit('show-loader')
        userService.denyFriend(item.id)
          .then(() => {
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
      }
    }
  }
</script>

<template>
    <div>
        <div v-if="!$vuetify.breakpoint.xs" class="page-header flex-header list-header">
            <h2 class="headline page-title">{{$t('app.domains.user.components.friends.pageTitle')}}</h2>
            <div></div>
        </div>
        <v-tabs grow
                centered
                show-arrows
                v-model="currentTab"
                background-color="grey lighten-3"
                slider-color="primary">
            <v-tab><i class="fas fa-user-friends"></i><span class="ml-1">{{$t('app.domains.user.components.friends.tabs.friends.title')}}</span></v-tab>
            <v-tab><i class="fas fa-paper-plane"></i><span class="ml-1">{{$t('app.domains.user.components.friends.tabs.requests.title')}}</span></v-tab>
            <v-tab><i class="fas fa-search"></i><span class="ml-1">{{$t('app.domains.user.components.friends.tabs.search.title')}}</span></v-tab>
            <v-tab-item>
                <v-card class="page-content-card">
                    <div class="search-users">
                        <v-text-field v-model="searchFriends"
                                      :placeholder="$t('app.domains.user.components.friends.tabs.friends.searchFriendsPlaceholder')"
                                      clearable
                                      hide-details
                                      solo>
                            <template v-slot:append><i class="fas fa-search"></i></template>
                        </v-text-field>
                    </div>
                    <v-list two-line
                            v-if="listFriends.length > 0">
                        <template v-for="(item, index) in listFriendsFiltered">
                            <v-list-item :key="'friends_' + index">
                                <v-list-item-avatar v-if="item.avatar">
                                    <img :src="item.avatar">
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{item.name}}</v-list-item-title>
                                    <v-list-item-subtitle v-if="item.phone">
                                        {{item.phone | phone}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action v-if="item.status === 'requested'">
                                    <v-list-item-action-text>
                                        {{$t('app.domains.user.components.friends.tabs.friends.inviteSentText')}}
                                    </v-list-item-action-text>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider :key="index * 50 + 1"/>
                        </template>
                    </v-list>
                    <empty-list v-if="listFriends.length === 0" :message="$t('app.domains.user.components.friends.tabs.friends.emptyListMessage')"/>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card class="page-content-card">
                    <v-list two-line
                            v-if="listRequests.length > 0">
                        <template v-for="(item, index) in listRequests">
                            <v-list-item :key="'requests_' + index">
                                <v-list-item-avatar v-if="item.avatar">
                                    <img :src="item.avatar">
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{item.name}}</v-list-item-title>
                                    <v-list-item-subtitle v-if="item.phone">
                                        {{item.phone | phone}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn color="success" small ripple @click="acceptFriend(item)">
                                        <i class="fas fa-check"></i>
                                        <span class="ml-2">{{$t('app.domains.user.components.friends.tabs.requests.acceptInviteBtn')}}</span>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider :key="index * 50 + 1"/>
                        </template>
                    </v-list>
                    <empty-list v-if="listRequests.length === 0" :message="$t('app.domains.user.components.friends.tabs.requests.emptyListMessage')"/>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card class="page-content-card">
                    <div class="search-users">
                        <v-text-field v-model="searchUser"
                                      :placeholder="$t('app.domains.user.components.friends.tabs.search.searchUserPlaceholder')"
                                      clearable
                                      hide-details
                                      solo>
                            <template v-slot:append><i class="fas fa-search"></i></template>
                        </v-text-field>
                    </div>
                    <v-list two-line
                            v-if="dependencies.users.length > 0">
                        <template v-for="(item, index) in dependencies.users">
                            <v-list-item :key="'search_' + index">
                                <v-list-item-avatar v-if="item.avatar">
                                    <img :src="item.avatar">
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{item.name}}</v-list-item-title>
                                    <v-list-item-subtitle v-if="item.phone">
                                        {{item.phone | phone}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn color="success" small ripple @click="storeFriend(item)">
                                        <i class="fas fa-plus"></i>
                                        <span class="ml-1">{{$t('app.domains.user.components.friends.tabs.search.inviteBtn')}}</span>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider :key="index * 50 + 1"/>
                        </template>
                    </v-list>
                    <empty-list v-if="dependencies.users.length === 0" :message="$t('app.domains.user.components.friends.tabs.search.emptyListMessage')"/>
                </v-card>
            </v-tab-item>
        </v-tabs>
    </div>
</template>
