<script>
export default {
  name: 'loading-basic',
  props: {
    image: {
      default: true,
      type: Boolean
    },
    lines: {
      default: 2,
      type: Number
    }
  }
}
</script>

<template>
  <div class="placeholder">
    <div class="placeholder-box placeholder-content">
      <div class="placeholder-row" v-if="image">
        <div class="placeholder-item -image"></div>
        <div class="placeholder-content">
          <div class="placeholder-item -half"></div>
          <div class="placeholder-item"></div>
          <div class="placeholder-item"></div>
          <div class="placeholder-item"></div>
        </div>
      </div>
      <div v-for="(line, index) in lines"
           class="placeholder-item"
           :class="{'-half': !image && index === 0}"
           :key="line"></div>
    </div>
  </div>
</template>
