export const AUTH_USER = 'AUTH_USER'
export const AUTH_PERMISSIONS = 'AUTH_PERMISSIONS'
export const AUTH_TOKEN = 'AUTH_TOKEN'
export const AUTH_CITY = 'AUTH_CITY'
export const AUTH_CLUB = 'AUTH_CLUB'
export const AUTH_TENANT_ID = 'AUTH_TENANT_ID'

export const LOCALE = 'LOCALE'
export const PAGE_TITLE = 'PAGE_TITLE'
export const PAGE_SUB_TITLE = 'PAGE_SUB_TITLE'
export const LOADER_SHOW = 'LOADER_SHOW'
export const LOADER_MESSAGE = 'LOADER_MESSAGE'
export const TOGGLE_SHOW = 'TOGGLE_SHOW'
export const NOTIFICATION = 'NOTIFICATION'
export const SHOW_HEADER = 'SHOW_HEADER'
