<script>
import UserApiService from "../../services/domains/User"

import * as notifyService from "../../services/common/notify"

import bus from '@utils/bus'
import { mapGetters, mapActions } from 'vuex'

const {DeviceUUID} = require('device-uuid')

const userService = UserApiService.build({})

export default {
  name: 'app-device-verification',
  data() {
    return {
      isSubmitted: false,
      showActivationModal: false,
      activation: {
        type: 'email',
        code: {
          show: false,
          data: '',
        },
        channel: 'whatsapp'
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    hasEmail() {
      return !!this.currentUser.email
    },
    emailVerified() {
      return !!this.currentUser.email_verified_at
    },
    hasPhone() {
      return !!this.currentUser.phone
    },
    phoneVerified() {
      return !!this.currentUser.phone_verified_at
    },
    activationCodeInputsValid() {
      return this.activation.code.data.length === 6
    }
  },
  mounted() {
    bus.$on('show-device-verification-modal', ({type}) => {
      this.activateMessage(type, true)
    })
  },
  methods: {
    ...mapActions(['setUser']),
    activateMessage(type, showCode) {
      this.resetCodeData()
      showCode = showCode || false
      this.activation.type = type
      this.activation.code.show = showCode
      this.activation.channel = 'whatsapp'
      this.showActivationModal = true
      if ('phone' === type && showCode) {
        setTimeout(() => {
          this.$refs.activationCodeData.focus()
        }, 300)
      }
    },
    resetCodeData() {
      this.activation.type = 'whatsapp'
      this.activation.code.show = false
      this.activation.code.data = ''
    },
    sendActivationMessage() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {...this.activation}
      userService.sendActivationAccount(data)
          .then(response => {
            this.isSubmitted = false
            notifyService.success({message: response.message})
            if ('email' === data.type) {
              this.showActivationModal = false
            } else if ('phone' === data.type) {
              this.resetCodeData()
              this.activation.code.show = true
            }
            bus.$emit('hide-loader')
          })
          .catch(() => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
    },
    confirmActivationCode() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {
        phone: this.currentUser.phone,
        code: this.activation.code.data,
        device: new DeviceUUID().get()
      }
      userService.checkActivationCode(data)
          .then((response) => {
            this.isSubmitted = false
            const currentUser = {...this.currentUser}
            currentUser.phone_verified_at = response.user.phone_verified_at
            this.setUser(currentUser)
            this.isSubmitted = false
            this.showActivationModal = false
            bus.$emit('hide-loader')
          })
          .catch(() => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
          })
    }
  }
}
</script>

<template>
  <div>
    <v-alert dense text outlined type="warning" v-if="!emailVerified && hasEmail">
      <span v-html="$t('components.main.app.deviceVerification.email.text')"></span>
      <template slot="prepend">
        <i class="fas fa-envelope mr-3"></i>
      </template>
      <template slot="append" v-if="!$vuetify.breakpoint.xs">
        <v-btn small color="warning"
               @click="activateMessage('email')">
          {{ $t('components.main.app.deviceVerification.email.btnText') }}
        </v-btn>
      </template>
      <template v-else>
        <v-btn small block color="warning" class="mt-2"
               @click="activateMessage('email')">
          {{ $t('components.main.app.deviceVerification.email.btnText') }}
        </v-btn>
      </template>
    </v-alert>
    <v-alert dense text outlined type="warning" v-if="!phoneVerified && hasPhone">
      <span v-html="$t('components.main.app.deviceVerification.phone.text')"></span>
      <template slot="prepend">
        <i class="fas fa-mobile mr-3"></i>
      </template>
      <template slot="append" v-if="!$vuetify.breakpoint.xs">
        <v-btn small
               class="mr-1"
               @click="activateMessage('phone', true)">
          {{ $t('components.main.app.deviceVerification.phone.btnCodeText') }}
        </v-btn>
        <v-btn small
               color="warning"
               @click="activateMessage('phone')">
          {{ $t('components.main.app.deviceVerification.phone.btnText') }}
        </v-btn>
      </template>
      <template v-else>
        <v-btn small
               block
               class="mt-2"
               @click="activateMessage('phone', true)">
          {{ $t('components.main.app.deviceVerification.phone.btnCodeText') }}
        </v-btn>
        <v-btn small block color="warning" class="mt-2" @click="activateMessage('phone')">
          {{ $t('components.main.app.deviceVerification.phone.btnText') }}
        </v-btn>
      </template>
    </v-alert>
    <v-dialog v-model="showActivationModal" max-width="500">
      <v-card>
        <v-card-title class="headline">
          {{ $t('components.main.app.deviceVerification.modal.title') }}
        </v-card-title>
        <v-card-text class="pt-5">
          <h3 v-if="activation.type === 'email'">
            {{ $t('components.main.app.deviceVerification.modal.emailText') }}:
            {{ this.currentUser.email }}</h3>
          <template v-else>
            <h3>{{ $t('components.main.app.deviceVerification.modal.phoneText') }}:
              {{ this.currentUser.phone }}</h3>
            <template v-if="!activation.code.show">
              <h4 class="mt-4">{{ $t('components.main.app.deviceVerification.modal.channelText') }}:</h4>
              <v-radio-group v-model="activation.channel" row>
                <v-radio label="Whatsapp" value="whatsapp"/>
                <v-radio label="SMS" value="sms"/>
              </v-radio-group>
            </template>
          </template>
          <div class="mt-2" v-if="activation.code.show">
            <h4 class="mt-4">{{ $t('components.main.app.deviceVerification.modal.codeText') }}:</h4>
            <v-container>
              <v-layout justify-space-around>
                <v-flex>
                  <v-otp-input ref="activationCodeData" v-model="activation.code.data" />
                </v-flex>
              </v-layout>
            </v-container>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary"
                 v-if="activation.code.show"
                 :disabled="isSubmitted || !activationCodeInputsValid"
                 @click="confirmActivationCode">
            {{ $t('components.main.app.deviceVerification.modal.confirmCodeBtnText') }}
          </v-btn>
          <v-btn color="primary"
                 v-else
                 :disabled="isSubmitted"
                 @click="sendActivationMessage">
            {{ $t('components.main.app.deviceVerification.modal.sendBtnText') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
