<script>
  import UserApiService from '../../../services/domains/User'

  import helpers from '@mixins/helper'
  import bus from '@utils/bus'
  import {mapActions} from 'vuex'

  import {required, minLength} from 'vuelidate/lib/validators'

  const {DeviceUUID} = require('device-uuid')

  const userService = UserApiService.build({})

  export default {
    mixins: [helpers],
    data () {
      return {
        isSubmitted: false,
        user: {
          phone: '',
          code: ''
        }
      }
    },
    validations: {
      user: {
        phone: {required},
        code: {required, minLength: minLength(6)}
      }
    },
    mounted () {
      if (this.$route.params.phone) {
        this.user.phone = this.$route.params.phone
        if (this.$route.params.code) {
          this.user.code = this.$route.params.code
          bus.$emit('hide-loader')
        } else {
          this.focusCode()
        }
      } else {
        this.focusPhone()
      }
    },
    methods: {
      ...mapActions(['login']),
      focusPhone () {
        setTimeout(() => {
          this.$refs.formPhone.focus()
          bus.$emit('hide-loader')
        }, 300)
      },
      focusCode () {
        setTimeout(() => {
          this.$refs.formCode.focus()
          bus.$emit('hide-loader')
        }, 300)
      },
      async onConfirm () {
        bus.$emit('show-loader', {message: this.$t('app.domains.user.components.confirmPhone.checkCodeMessage')})
        this.isSubmitted = true
        const data = {
          phone: this.user.phone,
          code: this.user.code,
          device: new DeviceUUID().get()
        }
        userService.checkActivationCode(data)
          .then(async (response) => {
            await this.login({
              user: response.user,
              token: response.token,
              permissions: response.permissions || [],
              city_id: response.city_id,
              club_id: response.club_id
            })
            this.isSubmitted = false
            this.$router.push({name: 'home'})
          })
          .catch(() => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
          })
      }
    }
  }
</script>

<template>
    <v-container fill-height justify-center class="home-screens">
        <div>
            <v-layout wrap class="home-screens-box">
                <v-flex xs24>
                    <div class="home-logo">
                        <img src="/static/imgs/logo-light.png" alt="">
                    </div>
                    <v-form>
                        <div class="pt-3 pb-3 white--text text-center">
                            {{this.$t('app.domains.user.components.confirmPhone.smsConfirmationText')}}
                        </div>
                        <v-text-field v-model="user.phone"
                                      :label="$t('app.domains.user.components.confirmPhone.form.phoneLabel')"
                                      ref="formPhone"
                                      dark
                                      :error-messages="validationMessageField($v.user.phone, ['required'])"
                                      @input="$v.user.phone.$touch()"
                                      @blur="$v.user.phone.$touch()"/>
                        <v-text-field v-model="user.code"
                                      :label="$t('app.domains.user.components.confirmPhone.form.confirmationCodeLabel')"
                                      ref="formCode"
                                      type="tel"
                                      maxlength="6"
                                      dark
                                      :error-messages="validationMessageField($v.user.code, ['required', 'minLength'])"
                                      @input="$v.user.code.$touch()"
                                      @blur="$v.user.code.$touch()"/>
                    </v-form>
                    <v-btn color="light-green darken-1 mb-5 mt-4"
                           dark
                           block
                           large
                           @click="onConfirm"
                           :disabled="$v.user.$invalid">
                        {{$t('app.domains.user.components.confirmPhone.form.confirmBtn')}}
                    </v-btn>
                    <v-btn text block small dark :to="{name: 'auth.login'}">{{$t('app.texts.backLogin')}}</v-btn>
                </v-flex>
            </v-layout>
        </div>
    </v-container>
</template>
