<script>
import LoadingBasic from './loadings/LoadingBasic'

export default {
  name: 'loading-content',
  components: {LoadingBasic},
  props: {
    image: {
      default: true,
      type: Boolean
    },
    placeholders: {
      default: 4,
      type: Number
    }
  }
}
</script>

<template>
  <div>
    <loading-basic v-for="item in placeholders"
                   :key="item"
                   :image="image"/>
  </div>
</template>
