<script>
import VueRecaptcha from 'vue-recaptcha'

import DataService from '../../../services/common/data'
import UserApiService from '../../../services/domains/User'
import * as notifyService from '../../../services/common/notify'

import helpers from '@mixins/helper'
import bus from '@utils/bus'
import {mapActions} from 'vuex'

import {required, email, minLength, maxLength} from 'vuelidate/lib/validators'
import {clone} from 'lodash'
import i18n from '../../../../translation'

const userService = UserApiService.build({})
const isProduction = process.env.NODE_ENV === 'production'

export default {
  components: {VueRecaptcha},
  mixins: [helpers],
  data() {
    return {
      isSubmitted: false,
      isRecaptchaValid: false,
      acceptTerms: false,
      phonePrefix: '+55',
      register: {
        country_code: 'BRA',
        name: '',
        email: '',
        phone: '',
        password: ''
      },
      showPassword: false,
      dependencies: {
        countries: [],
        locales: []
      }
    }
  },
  validations: {
    register: {
      country_code: {required},
      name: {required},
      email: {required, email},
      phone: {required, maxLength: maxLength(15)},
      password: {required, minLength: minLength(6)}
    }
  },
  computed: {
    recaptchaKey() {
      return process.env.VUE_APP_RECAPTCHA_KEY
    },
    isProduction() {
      return isProduction
    },
    phoneMask() {
      const country = this.dependencies.countries.find(item => item.code === this.register.country_code)
      return this.countryPhoneMask(country?.code)
    }
  },
  mounted() {
    this.getDependencies()
    this.focusName()
  },
  methods: {
    ...mapActions(['login']),
    getDependencies() {
      DataService.get([{domain: 'country'}]).then((result) => {
        this.dependencies = {...result}
      })
    },
    verifyRecaptcha(response) {
      this.isRecaptchaValid = !!response
    },
    expiredRecaptcha() {
      this.isRecaptchaValid = false
    },
    focusName() {
      setTimeout(() => {
        this.$refs.formName.focus()
        bus.$emit('hide-loader')
      }, 300)
    },
    changeCountry(code) {
      const country = this.dependencies.countries.find(item => item.code === code)
      this.phonePrefix = '+' + country.phone_prefix
      i18n.locale = country.locale
    },
    async onRegister() {
      bus.$emit('show-loader', {message: this.$t('app.domains.user.components.register.registerLoadingMessage')})
      this.isSubmitted = true
      const data = clone(this.register)
      data.phone = this.onlyNumbers(data.phone)
      userService.register(data)
          .then(response => {
            notifyService.success({message: response.message})
            this.$router.push({name: 'auth.login'})
            this.isSubmitted = false
          })
          .catch(() => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
          })
    }
  }
}
</script>

<template>
  <v-container fill-height justify-center class="home-screens">
    <div>
      <v-layout wrap class="home-screens-box">
        <v-flex xs24>
          <div class="home-logo">
            <img src="/static/imgs/logo-light.png" alt="">
          </div>
          <v-form class="user-register">
            <v-select v-model="register.country_code"
                      :label="$t('app.domains.user.components.register.form.countryLabel')"
                      ref="formCountry"
                      :items="dependencies.countries"
                      item-value="code"
                      item-text="name"
                      dark
                      @keyup.enter="onRegister"
                      :error-messages="validationMessageField($v.register.country_code)"
                      @input="$v.register.country_code.$touch()"
                      @blur="$v.register.country_code.$touch()"
                      @change="changeCountry">
              <template slot="item" slot-scope="data">
                <v-list-item-avatar>
                  <img :src="`/static/flags/${data.item.flag_icon}`" alt="">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{ item }">
                <v-list-item-avatar>
                  <img :src="`/static/flags/${item.flag_icon}`" alt="">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
            <v-text-field v-model="register.name"
                          :label="$t('app.domains.user.components.register.form.nameLabel')"
                          ref="formName"
                          dark
                          @keyup.enter="onRegister"
                          :error-messages="validationMessageField($v.register.name)"
                          @input="$v.register.name.$touch()"
                          @blur="$v.register.name.$touch()"/>
            <v-text-field v-model="register.email"
                          :label="$t('app.domains.user.components.register.form.emailLabel')"
                          dark
                          @keyup.enter="onRegister"
                          :error-messages="validationMessageField($v.register.email, ['required', 'validEmail'])"
                          @input="$v.register.email.$touch()"
                          @blur="$v.register.email.$touch()"/>
            <v-text-field v-model="register.phone"
                          :label="$t('app.domains.user.components.register.form.phoneLabel')"
                          dark
                          type="tel"
                          v-mask="phoneMask"
                          :prefix="phonePrefix"
                          :error-messages="validationMessageField($v.register.phone, ['required', 'validPhone'], this.register.phone, this.phoneMask.length)"
                          @input="$v.register.phone.$touch()"
                          @blur="$v.register.phone.$touch()"
                          @keyup.enter="onRegister"/>
            <v-text-field v-model="register.password"
                          :label="$t('app.domains.user.components.register.form.passwordLabel')"
                          dark
                          :type="showPassword ? 'text' : 'password'"
                          @keyup.enter="onRegister"
                          :error-messages="validationMessageField($v.register.password, ['required', 'minLength'])"
                          @input="$v.register.password.$touch()"
                          @blur="$v.register.password.$touch()">
              <template v-slot:prepend>
                <i class="fas fa-lock"></i>
              </template>
              <template v-slot:append>
                <div @click="showPassword = !showPassword">
                  <span v-if="showPassword" key="passwordVisibility"><i class="fas fa-eye"></i></span>
                  <span v-else key="passwordVisibilityOff"><i class="fas fa-eye-slash"></i></span>
                </div>
              </template>
            </v-text-field>
          </v-form>
          <div class="accept-terms">
            <div>
              <v-checkbox v-model="acceptTerms" dark/>
              <span>Eu li e concordo com os <a href="https://gripo.app/termos-e-condicoes">termos e condições</a> de uso e com as <a href="https://gripo.app/politicas-de-privacidade">políticas de privacidade</a> do Gripo</span>
            </div>
          </div>
          <div v-if="isProduction" class="register-recaptcha">
            <vue-recaptcha :sitekey="recaptchaKey"
                           theme="dark"
                           @verify="verifyRecaptcha"
                           @expired="expiredRecaptcha"></vue-recaptcha>
          </div>
          <v-btn color="light-green darken-1"
                 class="mb-5"
                 dark
                 block
                 large
                 @click="onRegister"
                 :disabled="$v.register.$invalid || !acceptTerms">
            {{ $t('app.domains.user.components.register.form.storeUserBtn') }}
          </v-btn>
          <v-btn text block small dark :to="{name: 'auth.login'}">{{ $t('app.texts.backLogin') }}</v-btn>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>
