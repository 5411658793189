import Vue from 'vue'
import helper from '../mixins/helper'
import StringMask from 'string-mask'

Vue.filter('dateEnToBr', function (value) {
  if (!value) return ''
  return helper.methods.dateFormat(value, 'br')
})

Vue.filter('dateTimeEnToBr', function (value) {
  if (!value) return ''
  return helper.methods.dateTimeFormat(value, 'br', true)
})

Vue.filter('dateTimeFullEnToBr', function (value) {
  if (!value) return ''
  return helper.methods.dateTimeFormat(value, 'br', false)
})

Vue.filter('timeEnToBr', function (value) {
  if (!value) return ''
  return helper.methods.dateTimeToTimeFormat(value, 'br', true)
})

Vue.filter('dayMonthEnToBr', function (value) {
  if (!value) return ''
  return helper.methods.dayMonthFormat(value, 'br')
})

Vue.filter('currencyEnToBr', function (value, showCurrency) {
  showCurrency = showCurrency || true
  if (value === 0) return '0,00'
  if (!value) return ''
  return (showCurrency ? 'R$ ' : '') + helper.methods.currencyEnToBr(value)
})

Vue.filter('phone', function (value) {
  if (value === '') return ''
  if (!value) return ''
  const formatter = new StringMask('(##) #####.####')
  return formatter.apply(value)
})

Vue.filter('cpf', function (value) {
  if (value === '') return ''
  if (!value) return ''
  const formatter = new StringMask('###.###.###-##')
  return formatter.apply(value)
})

Vue.filter('hourShort', function (value) {
  if (value === '') return ''
  if (!value) return ''
  return value.substr(0, 5)
})
