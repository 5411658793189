<script>
import {mapGetters} from 'vuex'

export default {
  name: 'app-global-loader',
  data() {
    return {
      showLoading: true
    }
  },
  computed: {
    ...mapGetters(['isLoading', 'loadingMessage']),
    loading: function () {
      return this.isLoading
    }
  },
  watch: {
    loading: function (newValue, oldValue) {
      this.showLoading = oldValue === false && newValue === true
    }
  }
}
</script>

<template>
  <div :class="['global-loader', {'-hidden': !showLoading}]">
    <div class="ball-container">
      <div class="ball-shadow"></div>
      <div class="ball">
        <img src="/static/imgs/tennis-ball.svg" alt="Ball Bouncing Loading" rel="preload">
      </div>
    </div>
    <div class="message">{{ loadingMessage }}</div>
  </div>
</template>

<style lang="scss" scoped>
.global-loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, .5);

  &.-hidden {
    z-index: 0;
    opacity: 0;
  }

  .ball-container {
    position: relative;
    height: 100px;
  }

  .message {
    text-align: center;
    color: #FFF;
    font-weight: bold;
    font-size: 14px;
    background-color: #4F4894;
    padding: 8px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
    margin-top: 65px;
  }

  .ball {
    margin: 0 auto;
    width: 50px;
    height: 50px;
    animation: bouncingBall 2s ease-in-out infinite;
    z-index: 2;
    position: relative;
    bottom: 0;
    text-align: center;

    img {
      margin: 0 auto;
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  .ball-shadow {
    background: radial-gradient(50% 50%, #000 0%, rgba(255, 255, 255, 0));
    height: 15px;
    width: 50px;
    position: absolute;
    bottom: -50px;
    z-index: 1;
    animation: bouncingBallShadow 2s ease-out infinite;
  }
}

@keyframes bouncingBall {
  0% {
    transform: translateY(0);
  }
  16%, 48%, 78%, 100% {
    transform: translateY(95px);
    animation-timing-function: ease-out;
  }
  32% {
    transform: translateY(25px);
    animation-timing-function: ease-in;
  }
  62% {
    transform: translateY(50px);
    animation-timing-function: ease-in;
  }
  92% {
    transform: translateY(75px);
    animation-timing-function: ease-in;
  }
}

@keyframes bouncingBallShadow {
  0%, 100% {
    transform: scale(0);
  }
  16%, 48%, 78% {
    transform: scale(.8);
  }
  32% {
    transform: scale(.3);
  }
  62% {
    transform: scale(.3);
  }
  90% {
    transform: scale(.3);
  }
}
</style>
