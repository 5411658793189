<script>
import helpers from '@mixins/helper'

export default {
  name: 'stage-card',
  mixins: [helpers],
  props: {
    stage: {
      default: () => {
      },
      type: Object
    },
    odd: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    firstDate() {
      return this.dateToShortDayMonth(this.stage.dates[0], true)
    },
    lastDate() {
      return this.dateToShortDayMonth(this.stage.dates[this.stage.dates.length - 1], true)
    },
    hasActions() {
      return this.stage.registration_opened || this.stage.release_registrations || this.stage.release_matches
    },
    isSmallerDevice() {
      return this.$vuetify.breakpoint.width < 340
    },
    stageCover() {
      if (!this.stage.cover_data) {
        return []
      }
      return [this.stage.cover_data?.temporary_file ? this.stage.cover_data.temporary_file : this.stage.cover]
    }
  },
  methods: {
    showCover() {
      this.$viewerApi({
        images: [...this.stageCover],
        options: {toolbar: false, navbar: false, title: false}
      })
    },
    hideCover() {
      this.$viewerApi().exit()
    }
  }
}
</script>

<template>
  <v-card color="grey lighten-2" :class="['stage-card', {'-odd': odd}]">
    <v-card-title>
      <div>{{ stage.city }}</div>
    </v-card-title>
    <v-container fill-height class="pl-1 pr-1">
      <v-layout justify-center wrap align-center>
        <v-flex xs12 :class="{'pb-3': !hasActions}">
          <div class="title mb-1 text-center">{{ stage.name_with_circuit }}</div>
          <div class="text text-center">
            <v-chip class="pl-4 pr-4 stage-dates mt-1 mb-1" label small>
              <span>{{ $t('app.domains.home.components.stageCardDatesFromText') }}</span>
              <strong class="ml-2 mr-2">{{ firstDate }}</strong>
              <span>{{ $t('app.domains.home.components.stageCardDatesToText') }}</span>
              <strong class="ml-2">{{ lastDate }}</strong>
            </v-chip>
            <v-btn color="green darken-3"
                   class="mt-1 mb-1 ml-1 mr-1 white--text"
                   small
                   v-if="stageCover.length"
                   @click="showCover">
              <i class="fas fa-images"></i>
              <span class="ml-2">{{ $t('app.domains.home.components.stageCoverText') }}</span>
            </v-btn>
          </div>
        </v-flex>
        <v-flex align-self-end>
          <div
              v-if="stage.settings?.contact_phones || (!stage.registration_opened && stage.registration_closed && !stage.release_groups && !stage.release_matches)"
              class="text-center mb-2">
            <v-chip outlined
                    color="grey darken-2"
                    small label
                    :class="['fs-12 white--text stage-contact']">
              <i class="fas fa-phone"></i>
              <span class="ml-2 font-weight-bold">{{ stage.settings?.contact_phones }}</span>
            </v-chip>
            <v-chip color="orange darken-3"
                    outlined
                    small label
                    v-if="!stage.registration_opened && stage.registration_closed && !stage.release_groups && !stage.release_matches"
                    class="fs-12 white--text ml-1">
              <span class="orange--text darken-3"><i class="fas fa-user-times"></i></span>
              <span
                  class="darken-3 ml-2 font-weight-bold">{{
                  $t('app.domains.home.components.registrationClosedText')
                }}</span>
            </v-chip>
          </div>
          <v-container class="buttons-container pb-5" v-if="hasActions">
            <v-layout justify-center wrap>
              <v-flex :xs6="!isSmallerDevice" :xs12="isSmallerDevice"
                      v-if="stage.registration_opened">
                <v-btn block
                       color="orange darken-3"
                       class="white--text"
                       small
                       :to="{name: 'registration.store', params: {slug: stageRouteSlug(stage.id, stage.tenant_id, stage.name_slug)}}">
                  <i class="fas fa-user"></i>
                  <span class="ml-2">{{ $t('app.domains.home.components.newStageRegistrationBtn') }}</span>
                </v-btn>
              </v-flex>
              <v-flex :xs6="!isSmallerDevice" :xs12="isSmallerDevice" v-if="stage.release_registrations">
                <v-btn color="primary"
                       small
                       block
                       :to="{name: 'stage.show.pairs', params: {slug: stageRouteSlug(stage.id, stage.tenant_id, stage.name_slug)}}">
                  <i class="fas fa-users"></i>
                  <span class="ml-2">{{ $t('app.domains.home.components.showStageRegistrations') }}</span>
                </v-btn>
              </v-flex>
              <v-flex :xs6="!isSmallerDevice" :xs12="isSmallerDevice" v-if="stage.release_groups">
                <v-btn color="orange lighten-1"
                       small
                       block
                       class="white--text"
                       :to="{name: 'stage.show.groups', params: {slug: stageRouteSlug(stage.id, stage.tenant_id, stage.name_slug)}}">
                  <i class="fas fa-object-ungroup"></i>
                  <span class="ml-2">{{ $t('app.domains.home.components.showStageGroups') }}</span>
                </v-btn>
              </v-flex>
              <v-flex :xs6="!isSmallerDevice" :xs12="isSmallerDevice" v-if="stage.release_matches">
                <v-btn color="secondary"
                       small
                       block
                       :to="{name: 'stage.show.matches', params: {slug: stageRouteSlug(stage.id, stage.tenant_id, stage.name_slug)}}">
                  <i class="fas fa-calendar-alt"></i>
                  <span class="ml-2">{{ $t('app.domains.home.components.showStageMatches') }}</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-container>
    <div v-viewer>
      <img v-for="src in stageCover" :key="src" :src="src" v-show="false">
    </div>
  </v-card>
</template>
