<script>
import EmptyList from '../../../components/general/EmptyList'
import StageCard from '../../../components/general/StageCard'

import DataService from '../../../services/common/data'

import StageApiService from '../../../services/domains/Stage'
import CityApiService from '../../../services/domains/City'

import bus from '@utils/bus'
import helpers from '@mixins/helper'
import {mapGetters} from 'vuex'

const stageService = StageApiService.build({})
const cityService = CityApiService.build({})

export default {
  components: {EmptyList, StageCard},
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      filters: {
        city_id: '',
        year: ''
      },
      dependencies: {
        cities: [],
        years: []
      },
      list: []
    }
  },
  computed: {
    ...mapGetters(['city'])
  },
  async mounted() {
    await this.getCities()
    await this.getDependencies()
    this.filters.year = parseInt(this.momentNow().format('YYYY'))
    if (this.city && this.city.id) {
      this.filters.city_id = this.city.id
    }
    this.loadStages()
    bus.$emit('hide-loader')
  },
  methods: {
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([{domain: 'year'}]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    getCities() {
      return new Promise(resolve => {
        cityService.getActive().then(response => {
          this.dependencies.cities = [...response]
          resolve()
        })
      })
    },
    selectCity() {
      this.loadStages()
    },
    selectYear() {
      this.loadStages()
    },
    loadStages() {
      if (!this.filters.city_id || !this.filters.year) {
        return
      }
      bus.$emit('show-loader')
      const data = {
        city_id: this.filters.city_id,
        year: this.filters.year,
      }
      stageService.list(data)
          .then(response => {
            this.list = [...response]
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    filterListFilters(item, queryText, itemText) {
      if (itemText && queryText) {
        return itemText.toLowerCase().includes(queryText.toLowerCase())
      }
      return true
    }
  }
}
</script>

<template>
  <div class="dashboard-page">
    <div v-if="!$vuetify.breakpoint.xs" class="page-header flex-header list-header">
      <h2 class="headline page-title">{{ $t('app.domains.stage.components.list.pageTitle') }}</h2>
      <div>
        <v-menu v-if="false" bottom left offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on">
              <i class="fas fa-filter"></i>
              <span class="ml-2">{{ $t('app.domains.stage.components.list.showFiltersText') }}</span>
            </v-btn>
          </template>
          <v-card>
            <v-container fluid grid-list-md>
              <v-layout row wrap>
                <v-flex xs12>

                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-menu>
      </div>
    </div>
    <v-card class="page-content-card stages-content">
      <v-container grid-list-md>
        <v-layout wrap justify-center align-start>
          <v-flex xs6 class="mb-4">
            <v-autocomplete :items="dependencies.cities"
                            :filter="filterListFilters"
                            v-model="filters.city_id"
                            :label="$t('app.domains.stage.components.list.cityLabel')"
                            :placeholder="$t('app.texts.selectPlaceholder')"
                            :no-data-text="$t('app.texts.selectEmptyMessage')"
                            item-text="name_with_state"
                            item-value="id"
                            clearable
                            hide-details
                            @change="selectCity"/>
          </v-flex>
          <v-flex xs6 class="mb-4">
            <v-select :items="dependencies.years"
                      v-model="filters.year"
                      :label="$t('app.domains.stage.components.list.yearLabel')"
                      :placeholder="$t('app.texts.selectPlaceholder')"
                      :no-data-text="$t('app.texts.selectEmptyMessage')"
                      item-text="year"
                      item-value="year"
                      hide-details
                      @change="selectYear"/>
          </v-flex>
        </v-layout>
      </v-container>
      <v-container grid-list-lg>
        <v-layout wrap justify-center align-stretch>
          <template v-if="list.length > 0">
            <v-flex lg4 sm6 xs12
                    v-for="(stage, stageIndex) in list"
                    :key="stageIndex">
              <stage-card :stage="stage"/>
            </v-flex>
          </template>
        </v-layout>
      </v-container>
      <empty-list v-if="list.length === 0" :message="$t('app.domains.stage.components.list.emptyListMessage')"/>
    </v-card>
  </div>
</template>
