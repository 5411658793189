<script>
import AuthApiService from '../../../services/domains/Auth'

import helpers from '@mixins/helper'
import bus from '@utils/bus'
import {clear} from '@utils/storage'
import {mapActions} from 'vuex'
import {login as socialAuth} from '../../../../social-auth'

import {required} from 'vuelidate/lib/validators'
import {clone} from 'lodash'

const {DeviceUUID} = require('device-uuid')

const authService = AuthApiService.build({})

export default {
  mixins: [helpers],
  data() {
    return {
      isSubmitted: false,
      auth: {
        login: '',
        password: ''
      },
      showPassword: false
    }
  },
  validations: {
    auth: {
      login: {required},
      password: {required}
    }
  },
  async mounted() {
    await clear()
    if (this.$route.params && this.$route.params.hash) {
      this.onLoginByHash(this.$route.params.hash)
    } else {
      this.focusLogin()
    }
  },
  methods: {
    ...mapActions(['login']),
    focusLogin() {
      setTimeout(() => {
        this.$refs.formLogin.focus()
        bus.$emit('hide-loader')
      }, 300)
    },
    async authenticate(provider) {
      const result = await socialAuth(provider)
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {
        access_token: result.access_token,
        name: result.name,
        email: result.email,
        picture: result.picture,
        device: new DeviceUUID().get()
      }
      authService.loginSocial(data)
          .then(async (response) => {
            if (!response.user.avatar) {
              response.user.avatar = 'https://robohash.org/' + response.id
            }
            await this.login({
              user: response.user,
              token: response.token,
              permissions: response.permissions || [],
              city_id: response.city_id,
              club_id: response.club_id
            })
            const redirectUrl = window?.gripo?.redirect_route || null
            if (redirectUrl) {
              window.gripo.redirect_route = null
              this.$router.push(redirectUrl)
            } else {
              this.$router.push({name: 'home'})
            }
            this.isSubmitted = false
          })
          .catch(() => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
          })
    },
    async onLogin() {
      bus.$emit('show-loader', {message: this.$t('app.domains.auth.components.login.defaultLoginMessage')})
      this.isSubmitted = true
      const data = clone(this.auth)
      data.device = new DeviceUUID().get()
      authService.login(data)
          .then(async (response) => {
            if (response.user) {
              if (!response.user.avatar) {
                response.user.avatar = 'https://robohash.org/' + response.id
              }
              await this.login({
                user: response.user,
                token: response.token,
                permissions: response.permissions || [],
                city_id: response.city_id,
                club_id: response.club_id
              })
              const redirectUrl = window?.gripo?.redirect_route || null
              if (redirectUrl) {
                window.gripo.redirect_route = null
                this.$router.push(redirectUrl)
              } else {
                this.$router.push({name: 'home'})
              }
            } else if (data.login && response.phone) {
              this.$router.push({name: 'user.confirm-phone', params: {phone: data.login}})
            }
            this.isSubmitted = false
          })
          .catch(() => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
          })
    },
    async onLoginByHash(hash) {
      this.isSubmitted = true
      const data = {
        hash,
        device: new DeviceUUID().get()
      }
      authService.loginByHash(data)
          .then(async (response) => {
            if (!response.user.avatar) {
              response.user.avatar = 'https://robohash.org/' + response.id
            }
            await this.login({
              user: response.user,
              token: response.token,
              permissions: response.permissions || [],
              city_id: response.city_id,
              club_id: response.club_id
            })
            if (response.update_password !== undefined && response.update_password === true) {
              this.$router.push({name: 'user.show', params: {update_password: true}})
            } else {
              const redirectUrl = window?.gripo?.redirect_route || null
              if (redirectUrl) {
                window.gripo.redirect_route = null
                this.$router.push(redirectUrl)
              } else {
                this.$router.push({name: 'home'})
              }
            }
            this.isSubmitted = false
          })
          .catch(() => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
          })
    }
  }
}
</script>

<template>
  <v-container fill-height justify-center class="home-screens">
    <div>
      <v-layout wrap class="home-screens-box">
        <v-flex xs24>
          <div class="home-logo">
            <img src="/static/imgs/logo-light.png" alt="">
          </div>

          <v-btn block color="blue darken-2" v-if="false" class="mb-2" dark @click="authenticate('facebook')">
            <i class="fab fa-facebook-f"></i>
            <span class="pl-2">{{ $t('app.domains.auth.components.login.facebookAuth') }}</span>
          </v-btn>

          <v-btn block @click="authenticate('google')">
            <i class="fab fa-google"></i>
            <span class="pl-2">{{ $t('app.domains.auth.components.login.googleAuth') }}</span>
          </v-btn>

          <div class="login-separator">
            <div class="line">{{ $t('app.domains.auth.components.login.orLoginDataMessage') }}</div>
          </div>

          <v-form>
            <v-text-field v-model="auth.login"
                          ref="formLogin"
                          :label="$t('app.domains.auth.components.login.form.loginLabel')"
                          dark
                          @keyup.enter="onLogin"
                          type="text">
              <template v-slot:prepend>
                <i class="fas fa-user"></i>
              </template>
            </v-text-field>
            <v-text-field :type="showPassword ? 'text' : 'password'"
                          v-model="auth.password"
                          :label="$t('app.domains.auth.components.login.form.passwordLabel')"
                          dark
                          @keyup.enter="onLogin">
              <template v-slot:prepend>
                <i class="fas fa-lock"></i>
              </template>
              <template v-slot:append>
                <div @click="showPassword = !showPassword">
                  <span v-if="showPassword" key="passwordVisibility"><i class="fas fa-eye"></i></span>
                  <span v-else key="passwordVisibilityOff"><i class="fas fa-eye-slash"></i></span>
                </div>
              </template>
            </v-text-field>
          </v-form>
          <v-btn color="light-green darken-1 mb-2"
                 dark
                 block
                 large
                 :disabled="isSubmitted || $v.auth.$invalid"
                 :loading="isSubmitted"
                 @click="onLogin">
            <span class="pr-2">{{ $t('app.domains.auth.components.login.form.loginBtn') }}</span>
            <i class="fas fa-chevron-right"></i>
          </v-btn>
          <v-btn text block small dark :to="{name: 'auth.trouble'}">
            <i class="fas fa-info-circle"></i>
            <span class="pl-2">{{ $t('app.domains.auth.components.login.recoveryAccessBtn') }}</span>
          </v-btn>
          <div class="mt-5">
            <v-btn block small color="secondary" dark :to="{name: 'home'}">
              <i class="fas fa-id-badge"></i>
              <span class="pl-2">{{ $t('app.domains.auth.components.login.accessWithoutAuth') }}</span>
            </v-btn>
            <v-btn block small color="primary darken-2" :to="{name: 'user.register'}" class="mt-2">
              <i class="fas fa-user-plus"></i>
              <span class="pl-2">{{ $t('app.domains.auth.components.login.userRegisterBtn') }}</span>
            </v-btn>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<style lang="scss">
.login-separator {
  margin: 30px 0 15px;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  color: #FFF;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;

  .line {
    position: relative;
    display: inline-block;

    &:before, &:after {
      content: "";
      position: absolute;
      top: 50%;
      height: 1px;
      width: 1000px;
      background: rgba(255, 255, 255, .3);
    }

    &:before {
      right: 100%;
      margin-right: 12px;
    }

    &:after {
      left: 100%;
      margin-left: 12px;
    }
  }
}
</style>
