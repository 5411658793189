<script>
  import EmptyList from '../../../components/general/EmptyList'
  import LoadingContent from "../../../components/general/placeholders/LoadingContent"

  import ScheduleApiService from '../../../services/domains/Schedule'

  import bus from '@utils/bus'
  import {mapActions} from 'vuex'

  const scheduleService = ScheduleApiService.build({})

  export default {
    components: {LoadingContent, EmptyList},
    data () {
      return {
        isLoadingData: true,
        filters: {},
        dependencies: {},
        data: {
          list: []
        }
      }
    },
    mounted () {
      this.loadInvites()
    },
    methods: {
      ...mapActions(['showNotification']),
      loadInvites () {
        bus.$emit('hide-loader')
        scheduleService.getMyInvites()
          .then(response => {
            this.data.list = [...response]
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            this.isLoadingData = false
            bus.$emit('hide-loader')
            console.log(e)
          })
      },
      changeStatus (invite, status) {
        const data = {club_id: invite.schedule.court.club_id}
        scheduleService.changeInviteStatus(`invite/${invite.id}/${status}`, data)
          .then(response => {
            this.data.list = this.data.list.filter(item => item.id !== invite.id)
            this.showNotification({
              title: response.title,
              message: response.message
            })
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
      }
    }
  }
</script>

<template>
    <div>
        <div v-if="!$vuetify.breakpoint.xs" class="page-header flex-header list-header">
            <h2 class="headline page-title">{{$t('app.domains.schedule.components.invites.pageTitle')}}</h2>
            <div></div>
        </div>
        <v-card class="page-content-card">
            <v-list two-line
                    v-if="!isLoadingData && data.list.length > 0">
                <template v-for="(item, index) in data.list">
                    <v-list-item :key="item.id">
                        <v-list-item-avatar>
                            <v-btn icon color="success" small ripple @click="changeStatus(item, 'accept')">
                                <i class="fas fa-check"></i>
                            </v-btn>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title style="font-size: 15px;">
                                {{ item.schedule.start_datetime | dateTimeEnToBr }}h /
                                {{ item.schedule.court.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ item.schedule.court.club.name || '' }} /
                                {{ item.schedule.court.club.city.name || '' }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon color="error" small @click="changeStatus(item, 'deny')">
                                <i class="fas fa-times"></i>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider :key="index * 50 + 1"/>
                </template>
            </v-list>
            <empty-list v-if="!isLoadingData && data.list.length === 0"
                        :message="$t('app.domains.schedule.components.invites.emptyListMessage')"/>
            <template v-if="isLoadingData">
                <loading-content :placeholders="3"/>
            </template>
        </v-card>
    </div>
</template>
