import axios from 'axios'
import interceptors from './interceptors'
import baseConfig from '../config'

/**
 * Cria instância do axios para as requisições ajax
 */
export const http = axios.create({
  baseURL: baseConfig.fullAPIPath,
  headers: {'content-type': 'application/json'},
  timeout: 20000
})

export function setToken (token) {
  http.defaults.headers.common.Authorization = `Bearer ${token}`
}

export default function install (Vue, {router, store}) {
  interceptors(http, router, store)
}
