<script>
  export default {
    name: 'schedule-clock',
    props: {
      time: String
    }
  }
</script>

<template>
  <div title="GMT -3" class="fs-18">
    <i class="far fa-clock"></i>
    <span class="ml-2 font-weight-bold pr-2">{{ time }}</span>
  </div>
</template>
