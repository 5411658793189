import { get } from 'lodash'
import Swal from 'sweetalert2'
// import * as Sentry from '@sentry/browser'

import i18n from '../translation'
import bus from '../utils/bus';

const devEnvironment = process.env.NODE_ENV === 'development'

/**
 * Handle API messages. *
 */
export default {
  success (response) {
    return response
  },
  async error (error, router) {
    const swalData = {
      type: 'warning',
      title: i18n.t('app.texts.warning'),
      message: i18n.t('app.texts.apiHttpError')
    }
    let logoutUser = false

    // Sentry.captureMessage(error)

    try {
      // API Timeout
      if (error.toString().includes('timeout')) {
        swalData.message = i18n.t('app.texts.apiHttpTimeout')
        if (devEnvironment) {
          console.error('[API TIMEOUT]')
        }
      } else {
        // Get status code
        const status = get(error, 'response.status')
        // Get error message
        const responseData = get(error, 'response.data') || i18n.t('app.texts.apiHttpError')

        /**
         * Authorization errors
         */
        if ([401, 403].indexOf(status) > -1) {
          const responseMessage = get(responseData, 'message')
          const responseError = get(responseData, 'error')
          logoutUser = true

          // Token expired force logout
          if (responseMessage && responseMessage === 'Token has expired') {
            swalData.message = i18n.t('app.texts.apiHttpSessionExpired')
          } else {
            swalData.message = responseError
          }
        }

        /**
         * Wrong/Missing data or Validation errors
         */
        if ([400, 422].indexOf(status) > -1) {
          const responseError = get(responseData, 'error')
          if (typeof responseError === 'string') {
            swalData.message = responseError
          }
          if (typeof responseError === 'object') {
            let errors = []
            for (error in responseError) {
              if (typeof responseError[error] === 'object' && responseError[error].length > 0) {
                errors.push(responseError[error][0])
              }
            }
            swalData.message = errors.join('<br>')
          }
        }

        /**
         * WebServer error
         */
        if ([500].indexOf(status) > -1) {
          swalData.message = i18n.t('app.texts.apiHttpError')
          swalData.type = 'error'
        }

        /**
         * WebServer in Maintenance
         */
        if ([503].indexOf(status) > -1) {
          swalData.message = 'O sistema está em manutenção, acompanhe nossas redes sociais para mais informações'
          swalData.type = 'warning'
        }
      }

      // Show alert modal
      Swal.fire({ title: swalData.title, html: swalData.message, type: swalData.type })
      bus.$emit('hide-loader')

      // Logout user
      if (logoutUser) {
        return router.push({ name: 'auth.login' })
      }
    } catch (e) {
      // Sentry.captureException(e)
      Swal.fire({
        title: swalData.title,
        html: i18n.t('app.texts.apiHttpError'),
        type: swalData.type
      })
      bus.$emit('hide-loader')
      if (devEnvironment) {
        console.error('[API CONNECTION ERROR]', e)
      }
    }
    return Promise.reject(error)
  }
}
