<script>
import AppSidebar from './Sidebar'
import AppHeader from './Header'
import AppGlobalLoader from '../general/GlobalLoader'
import AppNotification from '../general/Notification'

import bus from '@utils/bus'
import { set, get, remove } from '@utils/storage'
import { mapActions, mapGetters } from 'vuex'

import { register } from 'register-service-worker'
import AppDeviceVerification from "../general/DeviceVerification"

import Echo from "laravel-echo"
import Pusher from 'pusher-js'
window.Pusher = Pusher

const isProduction = () => process.env.NODE_ENV === 'production'
const NETWORK_EVENTS = ['online', 'offline', 'load']

// Websocket
const websocketHost = () => process.env.VUE_APP_WEBSOCKET_HOST
const websocketPort = () => process.env.VUE_APP_WEBSOCKET_PORT
const pusherKey = () => process.env.VUE_APP_PUSHER_KEY

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: pusherKey(),
  wsHost: websocketHost(),
  wsPort: websocketPort(),
  wssPort: websocketPort(),
  forceTLS: false,
  encrypted: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  cluster: 'mt1'
})

export default {
  name: 'App',
  components: {AppDeviceVerification, AppGlobalLoader, AppHeader, AppSidebar, AppNotification},
  data() {
    return {
      loaderMessage: this.$t('app.texts.defaultLoading'),
      activeNetwork: true
    }
  },
  computed: {
    ...mapGetters(['isLogged', 'currentUser']),
    showHeader() {
      return this.$route.name !== 'auth.login' &&
          this.$route.name !== 'auth.trouble' &&
          this.$route.name !== 'user.register' &&
          this.$route.name !== 'user.confirm-phone' &&
          this.$route.name !== 'user.activate-account' &&
          this.$route.name !== 'user.confirm-account' &&
          this.$route.name !== 'payment'
    },
    showSidebar() {
      return this.showHeader
    },
    isAuthPage() {
      return this.$route.name && !this.showHeader
    },
    customLayout() {
      return ['registration.store', 'stage.show.pairs', 'stage.show.groups', 'stage.show.matches']
          .includes(this.$route.name) && this.$vuetify.breakpoint.xs
    }
  },
  mounted() {
    this.registerServiceWorker()
    NETWORK_EVENTS.forEach(event => window.addEventListener(event, this.networkStatusChange))
    bus.$on('show-loader', (data) => this.showLoader(data && data.message ? data.message : null))
    bus.$on('hide-loader', () => this.hideLoader())
    this.$nextTick(() => {
      if (document.documentElement.clientWidth > 1280) {
        this.toggleSidebar()
      }
    })
  },
  methods: {
    ...mapActions(['showLoader', 'hideLoader', 'showNotification', 'toggleSidebar']),
    async registerServiceWorker() {
      if (isProduction()) {
        const appUpdated = await get('app_updated')
        if (appUpdated && appUpdated === true) {
          this.showNotification({
            title: this.$t('components.main.app.updateAvailable.textUpdated'),
            timeout: 3000
          })
          remove('app_updated')
        }
        register(`${process.env.BASE_URL}service-worker.js`, {
          async updated() {
            await set('app_updated', true)
            window.location.reload(true)
          }
        })
      }
    },
    forceUpdateApp() {
      window.location.reload(true)
    },
    networkStatusChange() {
      let localActiveNetwork = navigator.onLine || false
      if (!this.activeNetwork && localActiveNetwork) {
        this.showNotification({
          title: this.$t('components.main.app.networkStatus.activeTitle'),
          message: this.$t('components.main.app.networkStatus.activeMessage'),
          timeout: 2000
        })
      } else if (this.activeNetwork && !localActiveNetwork) {
        this.showNotification({
          title: this.$t('components.main.app.networkStatus.inactiveTitle'),
          message: this.$t('components.main.app.networkStatus.inactiveMessage'),
          type: 'error',
          timeout: 0
        })
      }
      this.activeNetwork = localActiveNetwork
    }
  },
  beforeDestroy() {
    NETWORK_EVENTS.forEach(event => window.removeEventListener(event, this.networkStatusChange))
  }
}
</script>

<template>
  <v-app>
    <AppGlobalLoader/>
    <AppNotification/>
    <AppSidebar v-if="showSidebar"/>
    <AppHeader v-if="showHeader" :custom-layout="customLayout"/>
    <v-main light
            :class="[
                 {'-custom-layout': customLayout},
                 {'-auth-false': !showHeader},
                 {'-no-padding': (!isLogged || !showHeader) && isAuthPage}
             ]">
      <v-container :fill-height="!isLogged"
                   align-content-start>
        <app-device-verification/>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
