import Api from '../common/api/Api'

export default class CommonService extends Api {
  constructor () {
    super({domain: 'common'})
  }

  async currentDatetime () {
    return this.get({route: 'current-datetime'})
  }
}
