<script>
import LoadingStageMatch from './LoadingStageMatch.vue'

export default {
  name: 'loading-stage-group',
  components: {LoadingStageMatch}
}
</script>

<template>
  <div class="skeleton-loading-group">
    <div class="placeholder-header"/>
    <div class="placeholder-item"/>
    <div class="placeholder-item"/>
    <div class="placeholder-header"/>
    <loading-stage-match/>
    <loading-stage-match/>
  </div>
</template>
