import hello from 'hellojs'

export async function login (provider) {
  hello.init({
    facebook: '344364771255909',
    google: '562236690947-gmo45rn4dl15bv7ebv0k0ks9mtstp1v4.apps.googleusercontent.com'
  })

  return new Promise((resolve, reject) => {
    hello(provider).login({ scope: 'basic,email', redirect_uri: 'https://gripo.app' }).then((response) => {
      hello(provider).api('me').then((json) => {
        json.access_token = response.authResponse.access_token
        resolve(json)
      }, (e) => {
        reject(e.error.message)
      })
    }, (e) => {
      reject(e.error.message)
    })
  })
}