<script>
import EmptyList from '../../../components/general/EmptyList'
import PlayerList from '../../../components/general/PlayerList'
import ScheduleStatus from './ScheduleStatus'
import TransactionPaymentModal from "../../../components/general/TransactionPaymentModal"

import ScheduleApiService from '../../../services/domains/Schedule'
import UserApiService from '../../../services/domains/User'
import * as notifyService from '../../../services/common/notify'

import bus from '@utils/bus'
import { mapGetters, mapActions } from 'vuex'
import { debounce } from 'lodash'
import moment from 'moment'

const scheduleService = ScheduleApiService.build({})
const userService = UserApiService.build({})

export default {
  components: {TransactionPaymentModal, EmptyList, PlayerList, ScheduleStatus},
  data() {
    return {
      isLoadingData: true,
      currentTab: 0,
      searchUser: '',
      schedule: {
        id: null
      },
      schedulePlayers: [],
      scheduleInvites: [],
      dependencies: {
        users: []
      },
      transaction: null,
      showPixModal: false
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    websocketKey() {
      return `scheduleStatusChange`
    },
    websocketChannelName() {
      return this.club ? `${this.websocketKey}.${this.club.tenant_id}` : ''
    },
    websocketChannelListen() {
      return `.${this.websocketKey}`
    },
    isOwner() {
      return this.schedule.id && this.schedule.client && this.schedule.client.user_id === this.currentUser.id
    },
    hasPayment() {
      return this.schedule.transactions.length > 0
    },
    canPay() {
      return this.hasPayment && !this.schedule.paid && this.schedule.status === 'created'
    },
    paymentInfo() {
      if (this.hasPayment && this.schedule.paid) {
        return this.schedule.transactions[0]
      }
      return null
    },
    minutesToCancel() {
      return parseInt(this.schedule?.tenant_settings?.minutes_to_cancel_schedule ?? 0)
    },
    minutesToCancelFixed() {
      return parseInt(this.schedule?.tenant_settings?.minutes_to_cancel_schedule_fixed ?? 0)
    },
    scheduleCanceled() {
      return this.schedule.status === 'canceled'
    },
    timeCancelSchedule() {
      let momentMinutes = moment.duration(this.minutesToCancel, 'minutes')
      if (this.schedule.fixed) {
        momentMinutes = moment.duration(this.minutesToCancelFixed, 'minutes')
      }
      if (this.minutesToCancel >= 60 && this.minutesToCancel < 1440) {
        return momentMinutes.hours() + ' hora(s)'
      } else if (this.minutesToCancel >= 1440) {
        return momentMinutes.days() + ' dia(s)'
      } else if (this.minutesToCancel > 0) {
        return this.minutesToCancel + ' minuto(s)'
      } else {
        return null
      }
    },
    canCancel() {
      return this.isOwner
          && (
              (!this.schedule.fixed && this.schedule.tenant_settings.cancel_enabled)
              || (this.schedule.fixed && this.schedule.tenant_settings.cancel_fixed_enabled)
          )
          && !this.scheduleCanceled
          && this.schedule.is_owner
    }
  },
  watch: {
    searchUser: debounce(function (value) {
      this.onSearchUser(value)
    }, 300)
  },
  mounted() {
    this.startWebsocket()
    this.loadSchedule()
  },
  methods: {
    ...mapActions(['showNotification']),
    startWebsocket() {
      window.Echo.channel(this.websocketChannelName)
          .listen(this.websocketChannelListen, () => {
            this.loadSchedule()
          })
    },
    loadSchedule() {
      bus.$emit('show-loader')
      const id = this.$route.params.id
      const data = {
        club_id: this.$route.params.club_id || this.$route.params.schedule.club_id,
        start_datetime: this.$route.params?.schedule?.start_datetime ?? null,
        end_datetime: this.$route.params?.schedule?.end_datetime ?? null
      }
      this.dependencies.users.splice(0)
      this.searchUser = ''
      this.currentTab = 0

      scheduleService.showSchedule(id, data)
          .then(response => {
            this.schedule = {...response}
            this.schedulePlayers = [...response.players]
            this.scheduleInvites = [...response.invites]
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isLoadingData = false
            console.log(e)
          })
    },
    onSearchUser(query) {
      this.dependencies.users.splice(0)
      if (query) {
        let data = {name: query, cpf: query, email: query, phone: query, limit: 15}
        userService.searchUsers(data)
            .then((response) => {
              this.dependencies.users = [...response]
            })
            .catch(e => {
              console.log(e)
            })
      }
    },
    invitePlayer(item) {
      bus.$emit('show-loader')
      const data = {
        club_id: this.schedule.court.club_id,
        user_id: item.id,
        schedule_id: this.schedule.id
      }
      scheduleService.invitePlayer(data)
          .then(response => {
            this.showNotification({title: response.title})
            this.loadSchedule()
          })
          .catch(err => {
            console.log(err)
            bus.$emit('hide-loader')
          })
    },
    async cancelSchedule() {
      const confirm = await notifyService.confirm({title: this.$t('app.domains.schedule.components.show.cancelScheduleMessage')})
      if (confirm) {
        bus.$emit('show-loader')
        const data = {
          club_id: this.schedule.court.club_id,
          start_datetime: this.schedule.start_datetime,
          end_datetime: this.schedule.end_datetime
        }
        scheduleService.cancel(this.schedule.id, data)
            .then(response => {
              this.showNotification({title: response.title})
              this.$router.push({name: 'schedule.index'})
            })
            .catch(err => {
              console.log(err)
              bus.$emit('hide-loader')
            })
      }
    },
    showPayment() {
      this.transaction = {...this.schedule.transactions[0]}
      this.showPixModal = true
    }
  }
}
</script>

<template>
  <div>
    <div v-if="!$vuetify.breakpoint.xs" class="page-header flex-header list-header">
      <h2 class="headline page-title">{{ $t('app.domains.schedule.components.show.pageTitle') }}</h2>
      <div></div>
    </div>
    <template v-if="!isLoadingData">
      <v-tabs grow
              centered
              show-arrows
              v-model="currentTab"
              background-color="grey lighten-3"
              slider-color="primary">
        <v-tab>
          <i class="far fa-calendar-alt"></i>
          <span class="ml-1">{{ $t('app.domains.schedule.components.show.tabs.data.title') }}</span>
        </v-tab>
        <v-tab v-if="schedule.is_owner">
          <i class="fas fa-users"></i>
          <span class="ml-1">{{ $t('app.domains.schedule.components.show.tabs.invite.title') }}</span>
        </v-tab>
        <v-tab-item>
          <v-card class="page-content-card">
            <v-container grid-list-md>
              <v-layout align-center justify-space-between wrap>
                <v-flex sm8 xs12>
                  <div class="pb-2">
                    <div class="mb-2">
                      <schedule-status :status="schedule.status"
                                       :transactions="schedule.transactions"
                                       :paid="schedule.paid"/>
                      <v-chip :value="true"
                              color="blue"
                              v-if="schedule.fixed"
                              small
                              class="ml-2"
                              outlined>
                        {{ $t('app.domains.schedule.components.list.table.items.fixedSchedule') }}
                      </v-chip>
                    </div>
                    <div>
                      <strong
                          class="body-2">{{ $t('app.domains.schedule.components.show.tabs.data.dateText') }}: </strong>
                      {{ schedule.start_datetime | dateTimeEnToBr }}h até {{ schedule.end_datetime | timeEnToBr }}h
                    </div>
                    <div>
                      <strong
                          class="body-2">{{ $t('app.domains.schedule.components.show.tabs.data.courtText') }}: </strong>
                      {{ schedule.court.name }}
                    </div>
                    <div>
                      <strong
                          class="body-2">{{ $t('app.domains.schedule.components.show.tabs.data.clubText') }}: </strong>
                      {{ schedule.court.club.name }}
                    </div>
                    <div>
                      <strong
                          class="body-2">{{ $t('app.domains.schedule.components.show.tabs.data.cityText') }}: </strong>
                      {{ schedule.court.club.city.name_with_state }}
                    </div>
                    <template v-if="paymentInfo">
                      <div>
                        <strong
                            class="body-2">{{ $t('app.domains.schedule.components.show.tabs.data.amountPaidText') }}: </strong>
                        {{ paymentInfo.amount | currencyEnToBr }}
                      </div>
                      <div>
                        <strong
                            class="body-2">{{ $t('app.domains.schedule.components.show.tabs.data.paidDateTest') }}: </strong>
                        {{ paymentInfo.pay_date | dateTimeFullEnToBr }}
                      </div>
                    </template>
                  </div>
                </v-flex>
                <v-flex sm4 xs12 v-if="canCancel || canPay">
                  <div class="text-center">
                    <div class="mb-2" v-if="canPay">
                      <v-btn :large="!$vuetify.breakpoint.xs"
                             color="primary"
                             @click="showPayment">
                        <i class="fas fa-dollar-sign"></i>
                        <span class="ml-1">{{ $t('app.domains.schedule.components.show.tabs.data.paymentBtn') }}</span>
                      </v-btn>
                    </div>
                    <v-btn :large="!$vuetify.breakpoint.xs"
                           v-if="canCancel"
                           color="error"
                           @click="cancelSchedule">
                      <i class="fas fa-times"></i>
                      <span
                          class="pl-1">{{ $t('app.domains.schedule.components.show.tabs.data.cancelScheduleBtn') }}</span>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
              <v-alert color="yellow lighten-3"
                       :value="true"
                       icon="fa-exclamation-triangle"
                       class="mt-2"
                       v-if="canCancel && timeCancelSchedule">
                <div class="black--text fs-14">
                  Você só poderá cancelar a reserva até {{ timeCancelSchedule }} antes do
                  horário, após isso o cancelamento deverá ser feito diretamente com o clube
                </div>
              </v-alert>
              <v-layout justify-space-between wrap>
                <v-flex sm6 xs12>
                  <player-list :list="schedulePlayers"
                               :title="$t('app.domains.schedule.components.show.tabs.data.confirmedPlayersTitle')"
                               :empty-list-text="$t('app.domains.schedule.components.show.tabs.data.confirmedPlayersEmptyMessage')"
                               :show-icon="false"/>
                </v-flex>
                <v-flex sm6 xs12>
                  <player-list :list="scheduleInvites"
                               :title="$t('app.domains.schedule.components.show.tabs.data.invitedPlayersTitle')"
                               :empty-list-text="$t('app.domains.schedule.components.show.tabs.data.invitedPlayersEmptyMessage')"
                               empty-list-title=""
                               :show-icon="false"/>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <div class="search-users">
            <v-text-field v-model="searchUser"
                          :placeholder="$t('app.domains.schedule.components.show.tabs.invite.searchUserPlaceHolder')"
                          clearable
                          :disabled="schedulePlayers.length === 4 || scheduleCanceled"
                          hide-details
                          solo>
              <template v-slot:append><i class="fas fa-search"></i></template>
            </v-text-field>
          </div>
          <v-list two-line
                  v-if="dependencies.users.length > 0 && schedulePlayers.length < 4">
            <template v-for="(item, index) in dependencies.users">
              <v-list-item :key="'search_' + index">
                <v-list-item-avatar v-if="item.avatar">
                  <img :src="item.avatar">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle v-if="item.phone">
                    {{ item.phone | phone }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn color="success" small ripple @click="invitePlayer(item)">
                    <i class="fas fa-plus"></i>
                    <span class="ml-1">{{ $t('app.domains.schedule.components.show.tabs.invite.inviteBtn') }}</span>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="index * 50 + 1"/>
            </template>
          </v-list>
          <empty-list v-if="dependencies.users.length === 0 && schedulePlayers.length < 4"
                      title=" "
                      :message="$t('app.domains.schedule.components.show.tabs.invite.emptyListSearchBy')"/>
          <empty-list v-if="schedulePlayers.length === 4"
                      :title="$t('app.domains.schedule.components.show.tabs.invite.scheduleFullTitle')"
                      :message="$t('app.domains.schedule.components.show.tabs.invite.scheduleFullMessage')"/>
        </v-tab-item>
      </v-tabs>
    </template>
    <transaction-payment-modal v-if="showPixModal"
                               :transaction="transaction"
                               :show.sync="showPixModal"/>
  </div>
</template>
