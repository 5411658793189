<script>
  import CityApiService from '../../services/domains/City'
  import ClubApiService from '../../services/domains/Club'

  import bus from '@utils/bus'
  import helperMixin from "@mixins/helper"
  import {mapActions, mapGetters} from 'vuex'

  const cityService = CityApiService.build({})
  const clubService = ClubApiService.build({})

  export default {
    name: 'app-global-filters',
    mixins: [helperMixin],
    data () {
      return {
        isLoadingData: false,
        showModal: false,
        isLoadingCities: false,
        isLoadingClubs: false,
        dependencies: {
          cities: [],
          clubs: []
        }
      }
    },
    computed: {
      ...mapGetters(['cityId', 'club']),
      city: {
        get: function () {
          return this.cityId
        },
        set: function (value) {
          this.setCity(value === undefined ? null : value)
        }
      },
      localClub: {
        get: function () {
          return this.club ? this.club.id : null
        },
        set: function (value) {
          if (value) {
            const club = this.dependencies.clubs.find(item => item.id === value)
            this.setClub(club)
          } else {
            this.resetClubVuex()
          }
        }
      }
    },
    async mounted () {
      await this.getCities()
      if (this.cityId) {
        this.getClubs(this.cityId)
      }
      bus.$on('open-global-filters', () => this.handleModal())
    },
    methods: {
      ...mapActions(['setCity', 'setCityName', 'setClub', 'setPageSubTitle', 'setTenant']),
      resetClubVuex () {
        this.setClub({id: null, name: ''})
      },
      getCities () {
        return new Promise(resolve => {
          this.isLoadingCities = true
          cityService.getAvailable().then(response => {
            this.dependencies.cities = [...response]
            if (response.length === 0) {
              this.setCity(null)
              this.resetClubVuex()
            }
            this.isLoadingCities = false
            resolve()
          })
        })
      },
      getClubs (cityId) {
        return new Promise(resolve => {
          this.isLoadingClubs = true
          const data = {city_id: cityId}
          clubService.getAvailable(data).then(response => {
            this.dependencies.clubs = [...response]
            if (response.length === 0) {
              this.resetClubVuex()
            } else {
              this.selectClub()
            }
            this.isLoadingClubs = false
            resolve()
          })
        })
      },
      selectCity () {
        if (this.city) {
          const city = this.dependencies.cities.find(item => item.id === this.city)
          this.setCityName(city.name_with_state)
          this.resetClubVuex()
          this.getClubs(this.city)
        } else {
          this.dependencies.clubs.splice(0)
        }
      },
      selectClub () {
        let club = null
        if (this.club) {
          club = this.dependencies.clubs.find(item => item.id === this.club.id)
        }
        if (club) {
          if (this.showModal) {
            this.handleModal()
          }
          this.setClub(club)
          if (this.$route.name === 'schedule.daily') {
            this.setPageSubTitle(this.$t('components.main.sidebar.subTitleClubLabel') + `: ${club.name}`)
          }
        } else {
          this.resetClubVuex()
        }
      },
      handleModal () {
        this.showModal = !this.showModal
      }
    }
  }
</script>

<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
                <v-btn dark icon
                       class="mr-1"
                       :title="$t('components.main.globalFilters.title')"
                       @click="handleModal()"
                       v-on="tooltip">
                    <i class="fas fa-filter"></i>
                </v-btn>
            </template>
            <span>{{$t('components.main.globalFilters.title')}}</span>
        </v-tooltip>
        <v-dialog v-model="showModal"
                  width="400px">
            <v-card color="global-filters-card">
                <h4 class="title text-center mb-2">Selecione os filtros</h4>
                <v-container fluid grid-list-xs>
                    <v-select :items="dependencies.cities"
                              v-model="city"
                              :loading="isLoadingCities"
                              :label="$t('components.main.sidebar.selectCity.label')"
                              :placeholder="$t('app.texts.selectSinglePlaceholder')"
                              :no-data-text="$t('components.main.sidebar.selectCity.emptyList')"
                              item-text="name_with_state"
                              item-value="id"
                              clearable
                              dense
                              outlined
                              hide-details
                              class="mb-5"
                              @change="selectCity"/>
                    <v-select :items="dependencies.clubs"
                              v-model="localClub"
                              :loading="isLoadingClubs"
                              :label="$t('components.main.sidebar.selectClub.label')"
                              :placeholder="$t('app.texts.selectSinglePlaceholder')"
                              :no-data-text="$t('components.main.sidebar.selectClub.emptyList')"
                              item-text="name"
                              item-value="id"
                              clearable
                              dense
                              outlined
                              hide-details
                              @change="selectClub"/>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<style lang="scss">
    .global-filters-card {
        padding: 10px;
    }
</style>