import List from './components/List'

import i18n from '../../../translation'

export default [
  {
    path: i18n.t('app.domains.order.routes.index.path'),
    component: List,
    name: 'order.index',
    meta: {
      breadcrumb: {
        name: i18n.t('app.domains.order.routes.index.title')
      }
    },
    props: true
  }
]
