<script>
import EmptyList from '../../../components/general/EmptyList'

import StageApiService from '../../../services/domains/Stage'

import bus from '@utils/bus'
import helpers from '@mixins/helper'

const stageService = StageApiService.build({})

export default {
  components: {EmptyList},
  mixins: [helpers],
  props: ['stage'],
  data() {
    return {
      isLoading: true,
      stageId: null,
      tenantId: null,
      slugName: null,
      list: [],
      selectedPlayersClassIndex: 0,
      selectedPlayersClass: null,
    }
  },
  computed: {
    registrationsOpened() {
      return this.stage.registration_opened
    },
    placeholderTitle() {
      if (this.isLoading) {
        return this.$t('app.domains.stage.components.pairs.pairsLoadingTitle')
      }
      return this.$t('app.domains.stage.components.pairs.pairsNotAvailableTitle')
    },
    placeholderMessage() {
      if (!this.registrationsOpened) {
        return ''
      }
      return this.$t('app.domains.stage.components.pairs.pairsNotAvailableMessage')
    },
    showPairRegistrationRanking() {
      return this.stage?.settings?.groups_config?.show_pair_registration_ranking || false
    }
  },
  watch: {
    '$route.params.player_class': {
      deep: true,
      immediate: true,
      handler: function (value) {
        const playerClass = this.list.find(item => this.slugify(item.name_with_sex) === value)
        if (playerClass) {
          this.selectTab(playerClass)
        }
      }
    },
    selectedPlayersClass(value) {
      if (value) {
        this.routeSlugChange(value.name_with_sex)
      }
    }
  },
  mounted() {
    bus.$emit('show-loader')
    const [slug, stage] = this.$route.params.slug.split('--')
    const [tenant, id] = stage.split('-')
    this.stageId = id
    this.tenantId = tenant
    this.slugName = slug
    this.loadPairs(this.stageId, this.tenantId)
  },
  methods: {
    loadPairs(id, tenant) {
      const data = {tenant_id: tenant}
      stageService.pairs(id, data)
          .then(response => {
            this.list = [...response]
            if (response.length) {
              if (this.$route.params.player_class) {
                const playerClass = this.list.find(item => this.slugify(item.name_with_sex) === this.$route.params.player_class)
                if (playerClass) {
                  this.selectTab(playerClass)
                }
              } else {
                this.selectTab(response[0])
              }
            }
            setTimeout(() => {
              bus.$emit('hide-loader')
              this.isLoading = false
            }, 200)
          })
          .catch(err => {
            console.log(err)
            this.isLoading = false
            bus.$emit('hide-loader')
          })
    },
    stageRouteSlug(id, tenantId, slug) {
      return `${slug}--${tenantId}-${id}`
    },
    selectTab(playerClass) {
      this.selectedPlayersClass = playerClass
      this.selectedPlayersClassIndex = this.list.findIndex(item => item.id === playerClass.id)
    },
    routeSlugChange(playerClassName) {
      if (this.$route.params.player_class !== this.slugify(playerClassName)) {
        this.$router.push({
          name: 'stage.show.pairs',
          params: {...this.$route.params.slug, player_class: this.slugify(playerClassName)}
        })
      }
    },
  }
}
</script>

<template>
  <div>
    <div v-if="!isLoading && list.length > 0">
      <v-tabs background-color="grey lighten-3"
              v-model="selectedPlayersClassIndex"
              slider-color="primary"
              class="players-class-tabs"
              centered
              show-arrows>
        <v-tab v-for="(item, index) in list"
               :key="'tab_' + index"
               @click="selectTab(item)">
          <span class="caption">{{ item.name_with_sex }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedPlayersClassIndex">
        <v-tab-item v-for="(item, index) in list"
                    :key="'tab_' + index">
          <v-list class="stage-pairs-list">
            <template v-for="(pair, pairIndex) in item.pairs">
              <v-list-item :key="pair.id" dense>
                <v-list-item-content>
                  <v-list-item-title class="text-center pair-names">
                    <div class="flex-c -jc-c gap-5">
                      <span v-if="showPairRegistrationRanking" title="Pontos do Ranking">
                        (<strong>{{pair.points}}</strong>)
                      </span>
                      <span>
                        {{ pair.player1 }} / {{ pair.player2 }}
                      </span>
                      <span class="registration-status -created"
                              v-if="pair.status === 'created'"
                              :title="$t('app.domains.stage.components.pairs.registrationStatusCreated')">
                          <i class="fas fa-info"></i>
                      </span>
                      <span class="registration-status -approved"
                              v-if="pair.status === 'approved'"
                              :title="$t('app.domains.stage.components.pairs.registrationStatusApproved')">
                          <i class="fas fa-check"></i>
                      </span>
                      <span class="registration-status -queued"
                              v-if="pair.status === 'queued'"
                              :title="$t('app.domains.stage.components.pairs.registrationStatusQueue')">
                          <i class="fas fa-list"></i>
                      </span>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="pairIndex * 50 + 1"></v-divider>
            </template>
          </v-list>
        </v-tab-item>
      </v-tabs-items>
      <v-container fluid grid-list-md>
        <h5 class="body-2 pt-1">{{ $t('app.domains.stage.components.pairs.subtitleText') }}:</h5>
        <div>
          {{ $t('app.domains.stage.components.pairs.registrationStatusCreated') }}
          <span class="registration-status -created"
                :title="$t('app.domains.stage.components.pairs.registrationStatusCreated')">
            <i class="fas fa-info"></i>
        </span>
        </div>
        <div>
          {{ $t('app.domains.stage.components.pairs.registrationStatusApproved') }}
          <span class="registration-status -approved"
                :title="$t('app.domains.stage.components.pairs.registrationStatusApproved')">
            <i class="fas fa-check"></i>
        </span>
        </div>
        <div>
          {{ $t('app.domains.stage.components.pairs.registrationStatusQueue') }}
          <span class="registration-status -queued"
                :title="$t('app.domains.stage.components.pairs.registrationStatusQueue')">
            <i class="fas fa-list"></i>
        </span>
        </div>
      </v-container>
    </div>

    <template v-if="isLoading || list.length === 0">
      <empty-list :title="placeholderTitle"
                  :message="placeholderMessage">
        <div class="text-center mt-2" v-if="registrationsOpened">
          <v-btn color="orange darken-3"
                 class="white--text"
                 large
                 :to="{name: 'registration.store', params: {slug: stageRouteSlug(stage.id, stage.tenant_id, stage.name_slug)}}">
            <i class="fas fa-user"></i>
            <span class="ml-2">{{ $t('app.domains.home.components.newStageRegistrationBtn') }}</span>
          </v-btn>
        </div>
      </empty-list>
      <v-divider></v-divider>
    </template>
  </div>
</template>
