<script>
import StageApiService from '../../../services/domains/Stage';

import { mapActions } from 'vuex';
import helpers from '@mixins/helper';

const stageService = StageApiService.build({});

export default {
  mixins: [helpers],
  data() {
    return {
      tvMode: false,
      stageId: null,
      tenantId: null,
      slugName: null,
      stage: {
        id: null,
        name_with_circuit: '',
        stage_dates: [],
        settings: {
          contact_phones: '',
        },
        registration_opened: false
      },
      tabActive: 'pairs',
    };
  },
  computed: {
    stageLoaded() {
      return this.stage.id;
    },
    firstDate() {
      return this.dateToShortDayMonth(this.stage.stage_dates[0].date, true)
    },
    lastDate() {
      return this.dateToShortDayMonth(this.stage.stage_dates[this.stage.stage_dates.length - 1].date, true)
    },
    stageMasterSponsors() {
      if (this.stageLoaded) {
        return this.stage.images.filter(item => item.json && item.json.main_sponsor);
      }
      return [];
    },
    stageSponsors() {
      if (this.stageLoaded) {
        return this.stage.images.filter(item => !item.json || (item.json && !item.json.main_sponsor));
      }
      return [];
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.resize);
  },
  mounted() {
    window.addEventListener('resize', this.resize);
    const [slug, stage] = this.$route.params.slug.split('--');
    const [tenant, id] = stage.split('-');
    this.stageId = id;
    this.tenantId = tenant;
    this.slugName = slug;
    this.loadStage(id, tenant);
  },
  methods: {
    ...mapActions(['toggleHeader', 'toggleSidebar']),
    resize() {
      setTimeout(() => {
        if (this.$vuetify.breakpoint.xs && this.$refs.stageHeaderContent) {
          this.$refs.pageContent.style.paddingTop = `${this.$refs.stageHeaderContent.clientHeight + 30}px`;
        } else {
          this.$refs.pageContent.style.paddingTop = '15px';
        }
      }, 200)
    },
    loadStage(id, tenant) {
      const data = {tenant_id: tenant};
      stageService.show(id, data)
          .then(response => {
            this.stage = {...response};
            setTimeout(() => {
              this.resize()
            }, 100)
          })
          .catch(err => {
            console.log(err);
          });
    },
    toggleTvMode() {
      this.tvMode = !this.tvMode;
      this.toggleHeader(!this.tvMode);
      this.toggleSidebar(!this.tvMode);
    },
    selectTab() {
      if (this.$route.name === 'stage.show.pairs' && this.tabActive !== 0) {
        this.tabActive = 0;
      } else if (this.$route.name === 'stage.show.groups' && this.tabActive !== 1) {
        this.tabActive = 1;
      } else if (this.$route.name === 'stage.show.matches' && this.tabActive !== 2) {
        this.tabActive = 2;
      }
    },
    goTo(routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({name: routeName, params: {slug: `${this.slugName}--${this.tenantId}-${this.stageId}`}})
      }
    }
  }
};
</script>

<template>
  <div class="stage-show" v-if="stage.id">
    <div class="tv-mode hidden-xs-only" v-if="!$vuetify.breakpoint.xs">
      <div key="tvMode" @click="toggleTvMode" :class="['fs-18', {'-active': tvMode}]">
        <i class="fas fa-tv"></i>
      </div>
    </div>
    <div class="stage-header">
      <div class="stage-card-bg" v-if="$vuetify.breakpoint.xs"></div>
      <div class="stage-card-content" ref="stageHeaderContent">
        <div class="item -full name">
          <div class="icon">
            <i class="fas fa-trophy"></i>
          </div>
          <div class="text">
            {{ stage.name_with_circuit }}
          </div>
        </div>
        <div class="item date">
          <div class="icon">
            <i class="fas fa-calendar-alt"></i>
          </div>
          <div class="text">
            <span>{{ $t('app.domains.home.components.stageCardDatesFromText') }}</span>
            <strong class="ml-2 mr-2">{{ firstDate }}</strong>
            <span>{{ $t('app.domains.home.components.stageCardDatesToText') }}</span>
            <strong class="ml-2">{{ lastDate }}</strong>
          </div>
        </div>
        <div class="item contact">
          <div class="icon">
            <i class="fas fa-phone"></i>
          </div>
          <div class="text">
            {{ stage.settings.contact_phones }}
          </div>
        </div>
      </div>
    </div>
    <div ref="pageContent">
      <v-tabs
          small
          v-model="tabActive"
          @change="selectTab"
          background-color="grey lighten-5"
          hide-slider
          grow
          :show-arrows="false"
          color="secondary"
          height="34px"
          class="stage-buttons-tabs">
        <v-tab key="pairs" @click="goTo('stage.show.pairs')" v-if="stage.release_registrations">
          <i class="fas fa-users"></i>
          <span class="ml-2 fs-12">{{ $t('app.domains.home.components.showStageRegistrations') }}</span>
        </v-tab>
        <v-tab key="groups" @click="goTo('stage.show.groups')" v-if="stage.release_groups">
          <i class="fas fa-object-ungroup"></i>
          <span class="ml-2 fs-12">{{ $t('app.domains.home.components.showStageGroups') }}</span>
        </v-tab>
        <v-tab key="matches" @click="goTo('stage.show.matches')" v-if="stage.release_matches">
          <i class="fas fa-calendar-alt"></i>
          <span class="ml-2 fs-12">{{ $t('app.domains.home.components.showStageMatches') }}</span>
        </v-tab>
      </v-tabs>
      <router-view :stage="stage" :tv-mode="tvMode"></router-view>
      <template v-if="stageLoaded">
        <v-flex class="mt-4" xs12 v-if="stageMasterSponsors.length > 0">
          <div class="subheading font-weight-bold text-center mb-4">
            {{ $t('app.domains.registration.components.form.sponsors.masterText') }}:
          </div>
          <div class="sponsors-list">
            <div v-for="(image, imageIndex) in stageMasterSponsors"
                 :key="imageIndex"
                 :class="['logo-box', {'-white-logo': image.json && image.json.white_logo}]">
              <a class="logo"
                 v-if="image.json && image.json.link"
                 target="_blank"
                 :href="image.json.link"
                 :title="image.json && image.json.name ? image.json.name : ''"
                 :style="{ backgroundImage: 'url(' + (image?.temporary_file ? image.temporary_file : image.file) + ')' }"></a>
              <div class="logo"
                   v-else
                   :title="image.json && image.json.name ? image.json.name : ''"
                   :style="{ backgroundImage: 'url(' + (image?.temporary_file ? image.temporary_file : image.file) + ')' }"></div>
            </div>
          </div>
        </v-flex>
        <v-flex class="mt-4" xs12 v-if="stageSponsors.length > 0">
          <div class="subheading font-weight-bold text-center mb-4">
            {{ $t('app.domains.registration.components.form.sponsors.othersText') }}:
          </div>
          <div class="sponsors-list -small">
            <div v-for="(image, imageIndex) in stageSponsors"
                 :key="imageIndex"
                 :class="['logo-box', {'-white-logo': image.json && image.json.white_logo}]">
              <a class="logo"
                 v-if="image.json && image.json.link"
                 target="_blank"
                 :href="image.json.link"
                 :title="image.json && image.json.name ? image.json.name : ''"
                 :style="{ backgroundImage: 'url(' + (image?.temporary_file ? image.temporary_file : image.file) + ')' }"></a>
              <div class="logo"
                   v-else
                   :title="image.json && image.json.name ? image.json.name : ''"
                   :style="{ backgroundImage: 'url(' + (image?.temporary_file ? image.temporary_file : image.file) + ')' }"></div>
            </div>
          </div>
        </v-flex>
      </template>
    </div>
  </div>
</template>
