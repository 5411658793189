<script>
export default {
  name: 'user-notification-actions',
  props: {
    enabled: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Object
    },
    updateSettings: {
      type: Function
    }
  },
  computed: {
    channelEnabled: {
      get: function () {
        return this.enabled
      },
      set: function (value) {
        this.$emit('update:enabled', value)
      }
    },
    actionsSettings: {
      get: function () {
        return this.actions
      },
      set: function (value) {
        this.$emit('update:actions', value)
      }
    }
  },
  methods: {
    onUpdateSettings () {
      this.$emit('updateSettings')
    }
  }
}
</script>

<template>
  <v-container fluid grid-list-lg>
    <v-layout row wrap>
      <v-flex md4 xs12>
        <v-card :elevation="1">
          <v-card-title class="subtitle-1 grey lighten-3">
            <v-icon left>fa-calendar-alt</v-icon>
            <span>Reservas</span>
          </v-card-title>
          <v-list dense class="notifications-permission-list">
            <v-list-item>
              <v-list-item-content>
                <v-checkbox
                    v-model="actionsSettings.schedule_store.created"
                    :disabled="!enabled"
                    color="primary"
                    hide-details
                    @change="onUpdateSettings"
                    label="Aguardando Confirmação"></v-checkbox>
              </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-list-item>
              <v-list-item-content>
                <v-checkbox
                    v-model="actionsSettings.schedule_store.scheduled"
                    :disabled="!enabled"
                    color="primary"
                    hide-details
                    @change="onUpdateSettings"
                    label="Confirmada"></v-checkbox>
              </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-list-item>
              <v-list-item-content>
                <v-checkbox
                    v-model="actionsSettings.schedule_store.canceled"
                    :disabled="!enabled"
                    color="primary"
                    hide-details
                    @change="onUpdateSettings"
                    label="Cancelada"></v-checkbox>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-flex>
      <v-flex md4 xs12>
        <v-card :elevation="1">
          <v-card-title class="subtitle-1 grey lighten-3">
            <v-icon left>fa-trophy</v-icon>
            <span>Inscrições em Torneios</span>
          </v-card-title>
          <v-list dense class="notifications-permission-list">
            <v-list-item>
              <v-list-item-content>
                <v-checkbox
                    v-model="actionsSettings.stage_registration_store.created"
                    :disabled="!enabled"
                    color="primary"
                    hide-details
                    @change="onUpdateSettings"
                    label="Registrada"></v-checkbox>
              </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-list-item>
              <v-list-item-content>
                <v-checkbox
                    v-model="actionsSettings.stage_registration_update.approved"
                    :disabled="!enabled"
                    color="primary"
                    hide-details
                    @change="onUpdateSettings"
                    label="Confirmada"></v-checkbox>
              </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-list-item>
              <v-list-item-content>
                <v-checkbox
                    v-model="actionsSettings.stage_registration_update.canceled"
                    :disabled="!enabled"
                    color="primary"
                    hide-details
                    @change="onUpdateSettings"
                    label="Cancelada"></v-checkbox>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-flex>
      <v-flex md4 xs12 v-if="false">
        <v-card :elevation="1">
          <v-card-title class="subtitle-1 grey lighten-3">
            <v-icon left>fa-user-friends</v-icon>
            <span>Amigos</span>
          </v-card-title>
          <v-list dense class="notifications-permission-list">
            <v-list-item>
              <v-list-item-content>
                <v-checkbox
                    v-model="form.settings.notifications.friend.notification"
                    color="primary"
                    hide-details
                    @change="updateSettings"
                    label="Aplicativo/Navegador"></v-checkbox>
              </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-list-item>
              <v-list-item-content>
                <v-checkbox
                    v-model="form.settings.notifications.friend.email"
                    color="primary"
                    hide-details
                    @change="updateSettings"
                    label="Email"></v-checkbox>
              </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-list-item>
              <v-list-item-content>
                <v-checkbox
                    v-model="form.settings.notifications.friend.whatsapp"
                    color="primary"
                    hide-details
                    @change="updateSettings"
                    label="Whatsapp"></v-checkbox>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-flex>
      <v-flex md4 xs12 v-if="false">
        <v-card :elevation="1">
          <v-card-title class="subtitle-1 grey lighten-3">
            <v-icon left>fa-trophy</v-icon>
            <span>Jogos de Torneio</span>
          </v-card-title>
          <v-list dense class="notifications-permission-list">
            <v-list-item>
              <v-list-item-content>
                <v-checkbox
                    v-model="form.settings.notifications.match.notification"
                    color="primary"
                    hide-details
                    @change="updateSettings"
                    label="Aplicativo/Navegador"></v-checkbox>
              </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-list-item>
              <v-list-item-content>
                <v-checkbox
                    v-model="form.settings.notifications.match.email"
                    color="primary"
                    hide-details
                    @change="updateSettings"
                    label="Email"></v-checkbox>
              </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-list-item>
              <v-list-item-content>
                <v-checkbox
                    v-model="form.settings.notifications.match.whatsapp"
                    color="primary"
                    hide-details
                    @change="updateSettings"
                    label="Whatsapp"></v-checkbox>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-flex>
      <v-flex md4 xs12 v-if="false">
        <v-card :elevation="1">
          <v-card-title class="subtitle-1 grey lighten-3">
            <v-icon left>fa-money-bill-alt</v-icon>
            <span>Pagamentos</span>
          </v-card-title>
          <v-list dense class="notifications-permission-list">
            <v-list-item>
              <v-list-item-content>
                <v-checkbox
                    v-model="form.settings.notifications.bill.notification"
                    color="primary"
                    hide-details
                    @change="updateSettings"
                    label="Aplicativo/Navegador"></v-checkbox>
              </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-list-item>
              <v-list-item-content>
                <v-checkbox
                    v-model="form.settings.notifications.bill.email"
                    color="primary"
                    hide-details
                    @change="updateSettings"
                    label="Email"></v-checkbox>
              </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-list-item>
              <v-list-item-content>
                <v-checkbox
                    v-model="actionsSettings.bill.whatsapp"
                    color="primary"
                    hide-details
                    @change="updateSettings"
                    label="Whatsapp"></v-checkbox>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>