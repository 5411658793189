const basePath = process.env.VUE_APP_API_URL
const baseAPIGroup = 'app'
const APIVersion = 'v1'

export default {
  basePath: basePath,
  baseAPIGroup: baseAPIGroup,
  APIVersion: APIVersion,
  fullAPIPath: [basePath, baseAPIGroup, APIVersion, ''].join('/')
}
