import messagesPtBr from './lang/ptBr'
import spanish from './lang/spanish'

export default {
    ...messagesPtBr,
    "es_ES": {...spanish},
    "es_AR": {...spanish},
    "es_UY": {...spanish},
    "es_PY": {...spanish},
}
