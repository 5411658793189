import Api from '../common/api/Api'

export default class ScheduleService extends Api {
  constructor () {
    super({domain: 'schedule'})
  }

  async currentTime () {
    return this.get({route: 'current-time'})
  }

  async available (data) {
    return this.post({route: 'available', cache: 'scheduleAvailable', data})
  }

  async confirm ({data}) {
    return this.post({route: 'confirm', data})
  }

  async getAmount ({data}) {
    return this.post({route: 'get-amount', data})
  }

  async cancel (id, data) {
    return this.put({route: 'cancel', id, data})
  }

  async getMySchedules (data) {
    return this.post({route: 'my', cache: 'mySchedules', data})
  }

  async getMyInvites () {
    return this.post({route: 'invites', cache: 'myInvites'})
  }

  async changeInviteStatus (route, data) {
    return this.post({route, data})
  }

  async showSchedule (id, data) {
    return this.post({id, data})
  }

  async invitePlayer (data) {
    return this.post({route: 'invite-player', data})
  }

  async getInviteByUuid (uuid) {
    return this.get({route: `invite/${uuid}`})
  }
}
