import Api from '../common/api/Api'

export default class StageService extends Api {
  constructor () {
    super({domain: 'stage'})
  }

  async get (data) {
    return this.post({route: 'get', cache: 'get', data})
  }

  async available (data) {
    return this.post({route: 'available', cache: 'stagesAvailable', data})
  }

  async list (data) {
    return this.post({route: 'list', cache: 'stagesList', data})
  }

  async show (id, data) {
    return this.post({id, data, cache: `stageShow-${data.tenant_id}-${id}`})
  }

  async pairs (id, data) {
    return this.post({id, data, route: 'pairs', cache: `stagePairs-${data.tenant_id}-${id}`})
  }

  async matches (id, data) {
    return this.post({id, data, route: 'matches', cache: `stageMatches-${data.tenant_id}-${id}`})
  }

  async groups (id, data) {
    return this.post({id, data, route: 'groups', cache: `stageGroups-${data.tenant_id}-${id}`})
  }
}
