<script>
import Match from './Match'
import MatchPairResult from './MatchPairResult.vue'
import EmptyList from '../../../components/general/EmptyList.vue'
import PlaceholderLoadingStageMatch from '../../../components/general/placeholders/loadings/LoadingStageMatch'

import StageApiService from '../../../services/domains/Stage'

import bus from '@utils/bus'
import helpers from '@mixins/helper'

import {debounce, orderBy} from 'lodash'

const stageService = StageApiService.build({})

export default {
  components: {Match, MatchPairResult, EmptyList, PlaceholderLoadingStageMatch},
  mixins: [helpers],
  props: ['stage', 'tvMode'],
  data() {
    return {
      isLoading: true,
      stageId: null,
      tenantId: null,
      slugName: null,
      searchMatchesOfPlayer: null,
      isFiltered: false,
      originalList: [],
      list: [],
      currentDate: null,
      scoreboard: {
        key: '',
        visible: false,
        showLogo: true,
        stageDescription: '',
        matchId: '',
        pair1: {
          names: 'Aguardando...',
          service: false,
          points: 0,
          sets: []
        },
        pair2: {
          names: 'Aguardando...',
          service: false,
          points: 0,
          sets: []
        }
      },
      selectedCourt: null
    }
  },
  computed: {
    websocketKey() {
      return `matchResultChange`
    },
    websocketChannelName() {
      return `${this.websocketKey}.${this.tenantId}.${this.stageId}`
    },
    websocketChannelListen() {
      return `.${this.websocketKey}`
    },
    wsScoreboardKey() {
      return `scoreboardUpdate`
    },
    wsScoreboardChannelName() {
      return `${this.wsScoreboardKey}.${this.scoreboard.key}`
    },
    wsScoreboardChannelListen() {
      return `.${this.wsScoreboardKey}`
    },
    placeholderTitle() {
      if (this.isLoading) {
        return this.$t('app.domains.stage.components.matches.matchesLoadingTitle')
      }
      if (this.searchMatchesOfPlayer && this.searchMatchesOfPlayer.length) {
        return this.$t('app.domains.stage.components.matches.matchesPlayerNotFound')
      }
      return this.$t('app.domains.stage.components.matches.matchesNotAvailableTitle')
    },
    listFiltered() {
      if (this.isFiltered && this.searchMatchesOfPlayer && this.searchMatchesOfPlayer.length) {
        let filteredMatches = []
        this.originalList.forEach(item => {
          filteredMatches = [
            ...filteredMatches,
            ...item.matches.filter(item => item.pair1_names.toLowerCase().includes(this.searchMatchesOfPlayer.toLowerCase())
                || item.pair2_names.toLowerCase().includes(this.searchMatchesOfPlayer.toLowerCase()))
          ]
        })
        return [...orderBy(filteredMatches, ['hour'], ['asc'])]
      }
      return this.list
    }
  },
  watch: {
    '$route.params.date': {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value) {
          if (!this.currentDate || this.currentDate !== value) {
            this.currentDate = value
          }
          if (this.tenantId && this.stageId) {
            this.loadMatches()
          }
        }
      }
    },
    'selectedCourt': function (value, oldValue) {
      if (oldValue !== null && oldValue !== undefined) {
        window.Echo.leaveChannel(`${this.wsScoreboardKey}.${this.list[oldValue]?.key || ''}`)
      }
      if (value !== null && value !== undefined) {
        this.scoreboard.key = this.list[value]?.key || ''
        this.startWsScoreboard()
      }
    },
    searchMatchesOfPlayer: debounce(function (value) {
      this.isFiltered = value && value.length
    }, 400)
  },
  mounted() {
    bus.$emit('show-loader')
    const [slug, stage] = this.$route.params.slug.split('--')
    const [tenant, id] = stage.split('-')
    this.stageId = id
    this.tenantId = tenant
    this.slugName = slug
    setTimeout(() => {
      if (this.stage && this.stage.stage_dates.length > 0) {
        let date = this.momentNow().format('YYYY-MM-DD')
        if (!this.stage.stage_dates.some(item => {
          return item.date === date
        })) {
          let skip = false
          this.stage.stage_dates.forEach(item => {
            if (!skip && this.dateMomentInstance(item.date).isAfter(this.dateMomentInstance(date), 'day')) {
              date = item.date
              skip = true
            }
          })
        }
        this.isLoading = true
        if (this.$route.params.date) {
          this.loadMatches()
        } else {
          this.setCurrentDate(date)
        }
        this.startWebsocket()
      }
    }, 200)
  },
  methods: {
    setCurrentDate(date) {
      if (date !== this.currentDate) {
        bus.$emit('show-loader')
        this.isLoading = true
        this.currentDate = date
        if (this.$route.params.date !== this.slugify(date)) {
          this.$router.push({
            name: 'stage.show.matches',
            params: {...this.$route.params.slug, date: this.slugify(date)}
          })
        }
      }
    },
    startWebsocket() {
      window.Echo.channel(this.websocketChannelName)
          .listen(this.websocketChannelListen, ({matches}) => {
            if (matches && matches.length) {
              const courtsMatches = [...this.originalList]
              matches.forEach(match => {
                if (match.date !== this.currentDate) {
                  return
                }
                const courtIndex = courtsMatches.findIndex(item => item.id === match.court_id)
                if (courtIndex > -1) {
                  const matchIndex = courtsMatches[courtIndex].matches.findIndex(item => {
                    if (match.id) {
                      return item.id === match.id
                    } else {
                      return item.court_id === match.court_id
                          && item.date === match.date
                          && item.hour === match.hour
                    }
                  })
                  if (matchIndex > -1) {
                    match.metadata.loading = true
                    courtsMatches[courtIndex].matches.splice(matchIndex, 1, match)
                    setTimeout(() => {
                      match.metadata.loading = false
                      courtsMatches[courtIndex].matches.splice(matchIndex, 1, match)
                    }, 1500)
                  }
                }
              })
              this.originalList = [...courtsMatches]
              this.list = [...courtsMatches]
            } else if (this.currentDate) {
              // eslint-disable-next-line no-unused-vars
              const [slug, stage] = this.$route.params.slug.split('--')
              const [tenant, id] = stage.split('-')
              this.stageId = id
              this.tenantId = tenant
              this.loadMatches()
            }
          })
    },
    startWsScoreboard() {
      window.Echo.channel(this.wsScoreboardChannelName)
          .listen(this.wsScoreboardChannelListen, ({scoreboard}) => {
            this.scoreboard = {...this.scoreboard, ...scoreboard}
          })
    },
    loadMatches() {
      return new Promise((resolve, reject) => {
        this.isLoading = true
        bus.$emit('show-loader')
        const data = {tenant_id: this.tenantId, date: this.currentDate}
        this.list.splice(0)
        this.selectedCourt = null
        stageService.matches(this.stageId, data)
            .then(response => {
              this.list = [...response]
              this.originalList = [...response]
              resolve(response)
            })
            .catch(err => {
              console.log(err)
              reject(err)
            })
            .finally(() => {
              this.isLoading = false
              bus.$emit('hide-loader')
            })
      })
    }
  },
  beforeDestroy() {
    window.Echo.leaveChannel(this.websocketChannelName)
    window.Echo.leaveChannel(this.wsScoreboardChannelName)
  }
}
</script>

<template>
  <div>
    <template v-if="!tvMode">
      <v-card color="grey lighten-4 pt-1 pb-1" flat>
        <div class="matches-dates mb-0">
          <v-btn v-for="(date, index) in stage.stage_dates"
                 :key="index"
                 small
                 class="white--text"
                 :outlined="currentDate !== date.date"
                 :color="currentDate === date.date ? 'secondary' : 'grey darken-1'"
                 @click="setCurrentDate(date.date)">
            {{ date.date | dateEnToBr }}
          </v-btn>
        </div>
      </v-card>
      <div class="mb-1 ml-1 mr-1">
        <v-text-field v-model="searchMatchesOfPlayer"
                      :placeholder="$t('app.domains.stage.components.matches.searchMatchesOfPlayer')"
                      clearable
                      dense
                      hide-details
                      class="elevation-0"
                      solo>
          <template v-slot:append><i class="fas fa-search"></i></template>
        </v-text-field>
      </div>
      <v-tabs background-color="grey lighten-3"
              v-if="list.length > 0 && !isFiltered"
              v-model="selectedCourt"
              slider-color="primary"
              centered
              height="35px"
              show-arrows>
        <v-tab v-for="(court, courtIndex) in list"
               :key="'tab_' + courtIndex">
          <div v-if="court.bg_color"
               class="court-color-circle mr-2"
               :style="`background-color: ${court.bg_color}`"></div>
          <span class="caption">{{ court.name }}</span>
        </v-tab>
        <v-tab-item v-for="(court, courtIndex) in list" :key="'tab_' + courtIndex">
          <div class="matches-live" v-if="court.live_url">
            <div class="live">
              <span class="dot-live" v-if="court.live"><i class="fas fa-circle"></i></span>
              <span class="text">ACOMPANHE{{ court.live ? ' AO VIVO' : '' }}</span>
            </div>
            <div class="link"><a :href="court.live_url" target="_blank">{{ court.live_url }}</a></div>
          </div>
          <div class="matches-list">
            <template v-for="(match, matchIndex) in court.matches">
              <match :match="match"
                     :key="match.id"
                     :scoreboard="scoreboard"
                     show-player-class
                     show-round/>
              <v-divider :key="`${match.id}-${matchIndex}`"></v-divider>
            </template>
          </div>
        </v-tab-item>
      </v-tabs>
      <div class="matches-list" v-else-if="listFiltered.length > 0 && isFiltered">
        <template v-for="(match, matchIndex) in listFiltered">
          <div class="matches-list-item" v-if="match.id">
            <v-layout align-center class="item-match">
              <v-flex v-if="match.metadata && match.metadata.loading" class="text-center pt-4 pb-4">
                <v-progress-circular
                    indeterminate
                    color="primary"/>
              </v-flex>
              <template v-else>
                <v-flex xs10
                        :class="['item-pair1', {'-hide-results': Array.isArray(match.sets) && match.sets.length === 0}]"><span
                    v-html="match.pair1_names"></span></v-flex>
                <v-flex xs4 class="item-versus">
                  <div class="item-infos">
                    <match-pair-result :key="match.pair1_id"
                                       v-if="Array.isArray(match.sets) && match.sets.length"
                                       class="pair-result pair1-result"
                                       :sets="match.sets"
                                       :pair-id="match.pair1_id"/>
                    <match-pair-result :key="match.pair2_id"
                                       v-if="Array.isArray(match.sets) && match.sets.length"
                                       class="pair-result pair2-result"
                                       :sets="match.sets"
                                       :pair-id="match.pair2_id"/>
                    <div class="item-court" v-if="match.court_name">{{ match.court_name }}</div>
                    <div class="item-hour" v-if="match.hour">{{ match.hour }}</div>
                    <div class="item-player-class">{{ match.player_class }}</div>
                    <div class="item-round" v-if="match.round_name">
                      <template v-if="match.type === 'groups' && match.round === 99">
                        Chave {{ match.group }}
                      </template>
                      <template v-else>
                        <span>{{ match.round_name }}</span>
                        <span class="ml-1" v-if="match.round > 2"> - J{{ match.number }}</span>
                      </template>
                    </div>
                  </div>
                </v-flex>
                <v-flex xs10
                        :class="['item-pair2', {'-hide-results': Array.isArray(match.sets) && match.sets.length === 0}]"><span
                    v-html="match.pair2_names"></span></v-flex>
              </template>
            </v-layout>
          </div>
          <v-divider :key="`${match.id}-${matchIndex}`"></v-divider>
        </template>
      </div>
    </template>
    <template v-else>
      <v-layout class="mt-5" justify-center>
        <v-flex v-for="(court, courtIndex) in list" :key="'tab_' + courtIndex" xs3 class="mr-1 ml-1">
          <v-card class="stage-groups-card matches-tv-mode-card">
            <v-card-title class="subheading light-green darken-3 white--text">
              {{ court.initials }}
            </v-card-title>
            <div class="matches-list">
              <template v-for="(match, matchIndex) in court.matches">
                <match :match="match"
                       :scoreboard="scoreboard"
                       :key="match.id"
                       show-player-class
                       show-round/>
                <v-divider :key="`${match.id}-${matchIndex}`"></v-divider>
              </template>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </template>
    <div v-if="isLoading || (list.length === 0 && !isFiltered) || (listFiltered.length === 0 && isFiltered)">
      <empty-list :title="placeholderTitle" message=""
                  v-if="!isLoading && (list.length === 0 && !isFiltered) || (listFiltered.length === 0 && isFiltered)"/>
      <placeholder-loading-stage-match v-else :lines="2" v-for="item in Array.from({length:5})" :key="item"/>
    </div>
  </div>
</template>
