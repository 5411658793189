import axios from 'axios'
import ApiService from './api/Api'

import moment from 'moment'

import i18n from '../../../translation'

const dataService = new ApiService({})

export default {
  get (domains) {
    const promises = []
    const responses = {}

    if (domains !== undefined && domains.length > 0) {
      domains.forEach(function (val) {
        if (val !== undefined) {
          val.route = val.route || null
          let defaultData = {
            orderBy: [
              {
                column: 'name',
                direction: 'ASC'
              }
            ]
          }
          let list = []
          let data = val.data || defaultData

          switch (val.domain) {

            case 'locale':
              list.splice(0)
              list.push({
                id: 'pt_BR',
                locale: 'pt_BR',
                name: i18n.t('app.data.locale.portuguese'),
                flag_icon: 'flag-icon-br'
              })
              list.push({id: 'es_ES', locale: 'es_ES', name: i18n.t('app.data.locale.spanish'), flag_icon: 'flag-icon-es'})
              promises.push(new Promise(resolve => resolve(list)))
              responses.locales = []
              break

            case 'country':
              dataService.setDomain(val.domain)
              promises.push(dataService.search({route: val.route, data, perPage: 9999}))
              responses.countries = []
              break

            case 'player_class':
              dataService.setDomain(val.domain)
              promises.push(dataService.search({route: val.route, data, perPage: 9999}))
              responses.players_class = []
              break

            case 'sex':
              list.splice(0)
              list.push({id: 'male', name: i18n.t('app.data.sex.male')})
              list.push({id: 'female', name: i18n.t('app.data.sex.female')})
              list.push({id: 'not_informed', name: i18n.t('app.data.sex.notInformed')})
              promises.push(new Promise(resolve => resolve(list)))
              responses.sex = []
              break

            case 'side':
              list.splice(0)
              list.push({id: 'left', name: i18n.t('app.data.side.left')})
              list.push({id: 'right', name: i18n.t('app.data.side.right')})
              list.push({id: 'both', name: i18n.t('app.data.side.both')})
              promises.push(new Promise(resolve => resolve(list)))
              responses.sides = []
              break

            case 'year':
              list.splice(0)
              for (let year = 2018; year <= parseInt(moment().format('YYYY')); year++) {
                list.push({year: year})
              }
              promises.push(new Promise(resolve => resolve(list)))
              responses.years = []
              break

            case 'schedule_status':
              list.splice(0)
              list.push({id: 'created', name: i18n.t('app.data.schedule_status.created')})
              list.push({id: 'scheduled', name: i18n.t('app.data.schedule_status.scheduled')})
              list.push({id: 'canceled', name: i18n.t('app.data.schedule_status.canceled')})
              promises.push(new Promise(resolve => resolve(list)))
              responses.schedule_status = []
              break

            case 'registration_status':
              list.splice(0)
              list.push({id: 'created', name: i18n.t('app.data.registration_status.created')})
              list.push({id: 'created_with_payment', name: i18n.t('app.data.registration_status.created_with_payment')})
              list.push({id: 'paid', name: i18n.t('app.data.registration_status.paid')})
              list.push({id: 'approved', name: i18n.t('app.data.registration_status.approved')})
              list.push({id: 'canceled', name: i18n.t('app.data.registration_status.canceled')})
              promises.push(new Promise(resolve => resolve(list)))
              responses.registration_status = []
              break

            case 'shirt_type':
              list.splice(0)
              list.push({id: 'standard', name: i18n.t('app.data.shirt_type.standard')})
              list.push({id: 'standard_female', name: i18n.t('app.data.shirt_type.standard_female')})
              list.push({id: 'tight', name: i18n.t('app.data.shirt_type.tight')})
              promises.push(new Promise(resolve => resolve(list)))
              responses.shirt_type = []
              break

            case 'shirt_size':
              list.splice(0)
              list.push({id: 'P', name: 'P'})
              list.push({id: 'M', name: 'M'})
              list.push({id: 'G', name: 'G'})
              list.push({id: 'GG', name: 'GG'})
              list.push({id: 'EG', name: 'EG'})
              list.push({id: 'EGG', name: 'EGG'})
              promises.push(new Promise(resolve => resolve(list)))
              responses.shirt_size = []
              break

          }
        }
      })
    }

    let loopCounter = 0

    /**
     * @type {Promise<any>}
     */
    const data = axios.all(promises)
      .then((result) => {
        for (let index in responses) {
          if (result[loopCounter] !== undefined) {
            if (result[loopCounter].data !== undefined) {
              responses[index] = result[loopCounter].data
            } else {
              responses[index] = result[loopCounter]
            }
          } else {
            responses[index] = []
          }
          loopCounter++
        }
        return responses
      })

    return new Promise((resolve) => {
      resolve(data)
    })
  }
}
