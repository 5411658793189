import Schedule from './components/Schedule'
import Invite from './components/Invite'
import List from './components/List'
import Show from './components/Show'
import Invites from './components/Invites'

import i18n from '../../../translation'

export default [
  {
    path: i18n.t('app.domains.schedule.routes.daily.path'),
    component: Schedule,
    name: 'schedule.daily',
    meta: {
      auth: false,
      breadcrumb: {
        name: i18n.t('app.domains.schedule.routes.daily.title')
      }
    }
  },
  {
    path: i18n.t('app.domains.schedule.routes.invite.path'),
    component: Invite,
    name: 'schedule.invite',
    meta: {
      auth: false,
      breadcrumb: {
        name: i18n.t('app.domains.schedule.routes.invite.title')
      }
    }
  },
  {
    path: i18n.t('app.domains.schedule.routes.index.path'),
    component: List,
    name: 'schedule.index',
    meta: {
      breadcrumb: {
        name: i18n.t('app.domains.schedule.routes.index.title')
      }
    }
  },
  {
    path: i18n.t('app.domains.schedule.routes.show.path'),
    component: Show,
    name: 'schedule.show',
    props: true,
    meta: {
      breadcrumb: {
        name: i18n.t('app.domains.schedule.routes.show.title')
      }
    }
  },
  {
    path: i18n.t('app.domains.schedule.routes.invites.path'),
    component: Invites,
    name: 'schedule.invites',
    meta: {
      breadcrumb: {
        name: i18n.t('app.domains.schedule.routes.invites.title')
      }
    }
  }
]
