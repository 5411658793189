<script>
import AppEnableNotifications from '../../../components/general/EnableNotifications'
import UserNotificationActions from './UserNotificationActions'
import UserApiService from '../../../services/domains/User'
import CityApiService from '../../../services/domains/City'

import DataService from '../../../services/common/data'
import * as notifyService from '../../../services/common/notify'

import helpers from '@mixins/helper'
import bus from '@utils/bus'
import * as CPF from '@utils/cpf'
import { mapGetters, mapActions } from 'vuex'

import { required, email } from 'vuelidate/lib/validators'
import { clone, cloneDeep, debounce, merge } from 'lodash'

const validCPF = (cpf) => CPF.validate(cpf)

const userService = UserApiService.build({})
const cityService = CityApiService.build({})

const defaultActions = {
  schedule_store: {
    created: true,
    scheduled: true,
    canceled: true
  },
  stage_registration_store: {
    created: true,
  },
  stage_registration_update: {
    approved: true,
    canceled: true,
  },
}

const defaultUserNotifications = {
  enabled: true,
  app: {
    enabled: true,
    actions: {...cloneDeep(defaultActions)}
  },
  push: {
    enabled: true,
    actions: {...cloneDeep(defaultActions)}
  },
  email: {
    enabled: true,
    actions: {...cloneDeep(defaultActions)}
  },
  whatsapp: {
    enabled: true,
    actions: {...cloneDeep(defaultActions)}
  }
}

const defaultUserData = {
  country_code: 'BRA',
  city_id: '',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  cpf: '',
  birth_date: '',
  sex: '',
  side: '',
  avatar: '',
  avatar_path: '',
  settings: {
    notifications: {...defaultUserNotifications}
  }
}

export default {
  components: {AppEnableNotifications, UserNotificationActions},
  mixins: [helpers],
  data() {
    return {
      isLoadingData: false,
      isSubmitted: false,
      isRecaptchaValid: false,
      isSearchingCities: false,
      showModalDatePicker: false,
      searchCity: '',
      phonePrefix: '+55',
      currentTab: 'personalData',
      notiticationTab: 'app',
      form: cloneDeep(defaultUserData),
      dependencies: {
        cities: [],
        sex: [],
        sides: [],
        countries: []
      },
      pushData: {
        endpoint: '',
        key: '',
        token: ''
      }
    }
  },
  validations: {
    form: {
      country_code: {required},
      first_name: {required},
      last_name: {required},
      email: {required, email},
      phone: {required}
    }
  },
  computed: {
    ...mapGetters(['isLogged', 'currentUser']),
    avatarUrl() {
      return this.form.avatar_path || this.getRandomAvatar
    },
    getRandomAvatar() {
      return 'https://robohash.org/' + this.currentUser.id
    },
    formBirthDateFormatted() {
      return this.form.birth_date ? this.dateFormat(this.form.birth_date, 'br') : ''
    },
    uniqueIdentityLabel() {
      const country = this.dependencies.countries.find(item => item.code === this.form.country_code)
      if (country) {
        switch (country.code) {
          case 'URY':
            return this.$t('app.domains.user.components.form.form.ciLabel')

          case 'ARG':
          case 'PRY':
            return this.$t('app.domains.user.components.form.form.dniLabel')
        }
      }
      return this.$t('app.domains.user.components.form.form.cpfLabel')
    },
    phoneMask() {
      const country = this.dependencies.countries.find(item => item.code === this.form.country_code)
      return this.countryPhoneMask(country?.code)
    }
  },
  async mounted() {
    if (this.isLogged) {
      this.isLoadingData = true
      this.getDependencies()
      let userData = await userService.account()
      const {firstName, lastName} = this.getFirstAndLastName(userData.name)
      userData.first_name = firstName
      userData.last_name = lastName
      if (userData.city) {
        this.dependencies.cities.push(userData.city)
      }
      if (userData.country) {
        userData.country_code = userData.country.code
      }
      if (!userData.settings) {
        userData.settings = {notifications: {...defaultUserData.settings.notifications}}
      } else {
        if (userData.settings.notifications === null) {
          userData.settings.notifications = merge(defaultUserData.settings.notifications)
        } else {
          userData.settings.notifications = merge(defaultUserData.settings.notifications, userData.settings.notifications)
        }
      }
      this.form = {...cloneDeep(merge(defaultUserData, userData))}
      if (this.$route.params.update_password !== undefined && this.$route.params.update_password !== null) {
        this.updatePassword()
      }
      this.isLoadingData = false
    }
    setTimeout(() => {
      bus.$emit('hide-loader')
    }, 300)
  },
  watch: {
    searchCity: debounce(function (value) {
      value && value !== this.form.city_id && this.onSearchCities(value)
    }, 300),
    showModalDatePicker(val) {
      val && setTimeout(() => (this.$refs.birthDatePicker.activePicker = 'YEAR'))
    }
  },
  methods: {
    ...mapActions(['setUser']),
    getDependencies() {
      DataService.get([{domain: 'sex'}, {domain: 'side'}, {domain: 'country'}]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    onSearchCities(query) {
      this.dependencies.cities.splice(0)
      if (query) {
        const data = {country_code: this.form.country_code, query: query}
        cityService.searchAutocomplete(data)
            .then((response) => {
              this.dependencies.cities = [...response]
            })
            .catch(e => {
              console.log(e)
            })
      }
    },
    updatePassword() {
      bus.$emit('update-password')
    },
    resizeAndUpload(file) {
      let vm = this
      let reader = new window.FileReader()
      const {name, size, type} = file
      reader.onloadend = function () {
        let tempImg = new window.Image()
        tempImg.src = reader.result
        tempImg.onload = function () {
          let MAX_WIDTH = 400
          let MAX_HEIGHT = 400
          let tempW = tempImg.width
          let tempH = tempImg.height
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW
              tempW = MAX_WIDTH
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH
              tempH = MAX_HEIGHT
            }
          }
          let canvas = document.createElement('canvas')
          canvas.width = tempW
          canvas.height = tempH
          let ctx = canvas.getContext('2d')
          ctx.drawImage(this, 0, 0, tempW, tempH)
          let base64 = canvas.toDataURL('image/jpeg')
          vm.updateAvatar(base64, name, size, type)
        }
      }
      reader.readAsDataURL(file)
    },
    selectImage(e) {
      let filesToUpload = e.target.files
      if (filesToUpload.length > 0) {
        let file = filesToUpload[0]
        this.resizeAndUpload(file)
      }
    },
    updateAvatar(avatar, filename, size, type) {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {
        id: this.currentUser.id,
        avatar: {
          base64: avatar,
          name: filename,
          type: type,
          size: size
        }
      }
      userService.changeAvatar(data)
          .then(response => {
            bus.$emit('hide-loader')
            this.form.avatar_path = avatar
            const user = clone(this.currentUser)
            user.avatar = avatar
            this.setUser(user)
            notifyService.success({message: response.message})
            this.isSubmitted = false
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
            this.isSubmitted = false
          })
    },
    changeCountry(code) {
      const country = this.dependencies.countries.find(item => item.code === code)
      this.phonePrefix = '+' + country.phone_prefix
    },
    save() {
      this.isSubmitted = true
      const data = clone(this.form)
      data.name = data.first_name + ' ' + data.last_name
      delete data.avatar
      delete data.avatar_path
      if (data.country_code === 'BRA' && data.cpf && !validCPF(data.cpf)) {
        notifyService.warning({title: this.$t('app.domains.user.components.form.invalidCPFMessage')})
        return
      }
      bus.$emit('show-loader')
      userService.update(data)
          .then(response => {
            bus.$emit('hide-loader')
            data.name = data.first_name + ' ' + data.last_name
            data.avatar = this.currentUser.avatar
            this.setUser(data)
            notifyService.success({message: response.message})
            this.isSubmitted = false
            if (response?.device_verification_modal_phone ?? false) {
              bus.$emit('show-device-verification-modal', {type: 'phone'})
            }
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
            console.log(e)
          })
    },
    async updateSettings() {
      try {
        this.isSubmitted = true
        const notifications = {...this.form.settings.notifications}
        notifications.enabled = notifications.app.enabled
            || notifications.push.enabled
            || notifications.email.enabled
            || notifications.whatsapp.enabled
        await userService.updateSettings(notifications)
        let currentUser = {...cloneDeep(this.currentUser)}
        currentUser = {...merge(currentUser, {settings: notifications})}
        await this.setUser(currentUser)
      } catch (err) {
        console.log(err)
      }
    },
    async updatePushNotifications({enabled, sub}) {
      if (enabled !== this.form.settings.notifications.push.enabled) {
        bus.$emit('show-loader')
        this.form.settings.notifications.push.enabled = enabled
        await this.updateSettings()
        await this.updateUserSubscription(sub)
        notifyService.success({message: this.$t('components.general.enableNotification.notificationPermissionUpdateText')})
        bus.$emit('hide-loader')
      }
    },
    updateUserSubscription(subData) {
      subData = subData || null
      let data = {id: this.currentUser.id}
      if (subData && subData.endpoint && subData.public_key && subData.auth_token) {
        data.endpoint = subData.endpoint
        data.public_key = subData.public_key
        data.auth_token = subData.auth_token
      }
      return userService.updateSubscription(data)
    }
  }
}
</script>

<template>
  <div class="my-account-page">
    <div v-if="!$vuetify.breakpoint.xs" class="page-header flex-header list-header">
      <h2 class="headline page-title">{{ $t('app.domains.user.components.form.pageTitle') }}</h2>
      <div></div>
    </div>
    <v-card class="page-content-card">
      <v-tabs v-model="currentTab" centered color="secondary" class="mb-4">
        <v-tab key="personalData">
          <i class="fas fa-user"></i>
          <span class="ml-2">Dados Pessoais</span>
        </v-tab>
        <v-tab key="notification">
          <i class="fas fa-bell"></i>
          <span class="ml-2">Notificações</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab">
        <v-tab-item key="personalData">
          <v-container fluid grid-list-md>
            <v-layout row wrap>
              <v-flex class="user-avatar" sm4 xs12>
                <div class="title pb-3">{{ $t('app.domains.user.components.form.avatarText') }}</div>
                <v-avatar :tile="false"
                          :size="200"
                          color="grey lighten-4">
                  <img v-if="!isLoadingData" :src="avatarUrl" alt="avatar">
                </v-avatar>
                <div class="pt-4">
                  <div class="upload-box">
                    <input type="file"
                           ref="uploadFile"
                           @change="selectImage"
                           accept="image/png,image/jpeg,image/jpg"/>
                  </div>
                  <v-btn class="btn-upload" small @click="$refs.uploadFile.click()">
                    <i class="fas fa-camera"></i>
                    <span class="pl-2">{{ $t('app.domains.user.components.form.updateAvatarBtn') }}</span>
                  </v-btn>
                </div>
                <div class="update-password pt-2">
                  <v-btn @click="updatePassword" small color="secondary">
                    <i class="fas fa-unlock-alt"></i>
                    <span class="pl-2">{{ $t('app.domains.user.components.form.updatePasswordBtn') }}</span>
                  </v-btn>
                </div>
              </v-flex>
              <v-flex sm8 xs12>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-select v-model="form.country_code"
                              :label="$t('app.domains.user.components.form.form.countryLabel')"
                              :items="dependencies.countries"
                              item-value="code"
                              item-text="name"
                              :error-messages="validationMessageField($v.form.country_code)"
                              @input="$v.form.country_code.$touch()"
                              @blur="$v.form.country_code.$touch()"
                              @change="changeCountry">
                      <template slot="item" slot-scope="data">
                        <v-list-item-avatar>
                          <img :src="`/static/flags/${data.item.flag_icon}`" alt="">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ data.item.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-list-item-avatar>
                          <img :src="`/static/flags/${item.flag_icon}`" alt="">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                    <v-text-field v-model="form.first_name"
                                  :error-messages="validationMessageField($v.form.first_name)"
                                  @input="$v.form.first_name.$touch()"
                                  @blur="$v.form.first_name.$touch()"
                                  :label="$t('app.domains.user.components.form.form.firstNameLabel')"
                                  :placeholder="$t('app.domains.user.components.form.form.firstNameLabel')"/>
                    <v-text-field v-model="form.last_name"
                                  :error-messages="validationMessageField($v.form.last_name)"
                                  @input="$v.form.last_name.$touch()"
                                  @blur="$v.form.last_name.$touch()"
                                  :label="$t('app.domains.user.components.form.form.lastNameLabel')"
                                  :placeholder="$t('app.domains.user.components.form.form.lastNameLabel')"/>
                    <v-text-field v-model="form.email"
                                  :error-messages="validationMessageField($v.form.email, ['required', 'email'])"
                                  @input="$v.form.email.$touch()"
                                  @blur="$v.form.email.$touch()"
                                  :label="$t('app.domains.user.components.form.form.emailLabel')"
                                  :placeholder="$t('app.domains.user.components.form.form.emailLabel')"/>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field v-model="form.phone"
                                  type="tel"
                                  v-mask="phoneMask"
                                  :prefix="phonePrefix"
                                  :error-messages="validationMessageField($v.form.phone, ['required', 'validPhone'], this.form.phone, this.phoneMask.length)"
                                  @input="$v.form.phone.$touch()"
                                  @blur="$v.form.phone.$touch()"
                                  :label="$t('app.domains.user.components.form.form.phoneLabel')"
                                  :placeholder="$t('app.domains.user.components.form.form.phoneLabel')"/>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field v-model="form.cpf"
                                  :label="uniqueIdentityLabel"
                                  :placeholder="uniqueIdentityLabel"/>
                  </v-flex>
                  <v-flex xs6>
                    <v-autocomplete v-model="form.city_id"
                                    :search-input.sync="searchCity"
                                    :items="dependencies.cities"
                                    no-data-text="Cidades não encontrados"
                                    item-value="id"
                                    clearable
                                    no-filter
                                    :label="$t('app.domains.user.components.form.form.cityLabel')"
                                    :placeholder="$t('app.domains.user.components.form.form.cityLabel')">
                      <template slot="item" slot-scope="data">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ data.item.name }}
                            <span v-if="data.item.state"> - {{ data.item.state.initials }}</span>
                            <span v-if="data.item.country"> / {{ data.item.country.code }}</span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.name }}
                            <span v-if="item.state"> - {{ item.state.initials }}</span>
                            <span v-if="item.country"> / {{ item.country.code }}</span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs6>
                    <v-menu ref="modalDatePicker"
                            :close-on-content-click="false"
                            v-model="showModalDatePicker"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                            :label="$t('app.domains.user.components.form.form.birthDateLabel')"
                            v-mask="'##/##/####'"
                            :value="formBirthDateFormatted"
                            clearable
                            v-on="on">
                          <template v-slot:prepend>
                            <i class="fas fa-calendar-alt"></i>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="form.birth_date"
                                     no-title
                                     ref="birthDatePicker"
                                     @input="showModalDatePicker = false"
                                     locale="pt-br"/>
                    </v-menu>
                  </v-flex>
                  <v-flex xs6>
                    <v-select v-model="form.sex"
                              :items="dependencies.sex"
                              :label="$t('app.domains.user.components.form.form.sexLabel')"
                              :placeholder="$t('app.domains.user.components.form.form.sexLabel')"
                              item-text="name"
                              item-value="id"
                              clearable/>
                  </v-flex>
                  <v-flex xs6>
                    <v-select v-model="form.side"
                              :items="dependencies.sides"
                              :label="$t('app.domains.user.components.form.form.sideLabel')"
                              :placeholder="$t('app.domains.user.components.form.form.sideLabel')"
                              item-text="name"
                              item-value="id"
                              clearable/>
                  </v-flex>
                </v-layout>
                <v-btn color="success"
                       block
                       large
                       @click="save"
                       :disabled="isSubmitted || $v.form.$invalid">
                  <i class="fas fa-check"></i>
                  <span class="pl-2">{{ $t('app.texts.saveData') }}</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
        <v-tab-item key="notification">

          <v-card flat>
            <v-tabs centered color="secondary" class="mt-2" v-model="notiticationTab" :show-arrows="false">
              <v-tab key="push">
                <i class="far fa-comment"></i>
                <span class="ml-2">Dispositivo</span>
              </v-tab>
              <v-tab key="app">
                <i class="fas fa-bell"></i>
                <span class="ml-2">In-App</span>
              </v-tab>
              <v-tab key="email">
                <i class="fas fa-envelope"></i>
                <span class="ml-2">E-mail</span>
              </v-tab>
              <v-tab key="whatsapp">
                <i class="fab fa-whatsapp"></i>
                <span class="ml-2">Whatsapp</span>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="notiticationTab">
              <v-tab-item key="push">
                <v-container fluid grid-list-lg class="pb-0">
                  <v-card :elevation="1">
                    <v-card-title class="subtitle-1 grey lighten-3">
                      <span>Notificações nativas do aplicativo</span>
                      <v-spacer/>
                      <app-enable-notifications @updatePushNotifications="updatePushNotifications"/>
                    </v-card-title>
                    <v-card-text class="text-center">
                      <div class="body-2 pt-3 pb-0">Receba informações de forma rápida e prática direto no seu
                        dispositivo
                      </div>
                    </v-card-text>
                  </v-card>
                </v-container>
                <user-notification-actions
                    :enabled.sync="form.settings.notifications.push.enabled"
                    :actions.sync="form.settings.notifications.push.actions"
                    @updateSettings="updateSettings"/>
              </v-tab-item>
              <v-tab-item key="app">
                <v-container fluid grid-list-lg class="pb-0">
                  <v-card :elevation="1">
                    <v-card-title class="subtitle-1 grey lighten-3">
                      <span>
                        Notificações na barra superior
                      </span>
                      <v-spacer></v-spacer>
                      <v-switch
                          v-model="form.settings.notifications.app.enabled"
                          color="primary"
                          hide-details
                          class="v-switch-no-margin"
                          @change="updateSettings"/>
                    </v-card-title>
                  </v-card>
                </v-container>
                <user-notification-actions
                    :enabled.sync="form.settings.notifications.app.enabled"
                    :actions.sync="form.settings.notifications.app.actions"
                    @updateSettings="updateSettings"/>
              </v-tab-item>
              <v-tab-item key="email">
                <v-container fluid grid-list-lg class="pb-0">
                  <v-card :elevation="1">
                    <v-card-title class="subtitle-1 grey lighten-3">
                      <span>
                        Notificações no seu email
                      </span>
                      <v-spacer></v-spacer>
                      <v-switch
                          v-model="form.settings.notifications.email.enabled"
                          color="primary"
                          hide-details
                          class="v-switch-no-margin"
                          @change="updateSettings"/>
                    </v-card-title>
                  </v-card>
                </v-container>
                <user-notification-actions
                    :enabled.sync="form.settings.notifications.email.enabled"
                    :actions.sync="form.settings.notifications.email.actions"
                    @updateSettings="updateSettings"/>
              </v-tab-item>
              <v-tab-item key="whatsapp">
                <v-container fluid grid-list-lg class="pb-0">
                  <v-card :elevation="1">
                    <v-card-title class="subtitle-1 grey lighten-3">
                      <span>
                        Notificações no seu Whatsapp
                      </span>
                      <v-spacer></v-spacer>
                      <v-switch
                          v-model="form.settings.notifications.whatsapp.enabled"
                          color="primary"
                          hide-details
                          class="v-switch-no-margin"
                          @change="updateSettings"/>
                    </v-card-title>
                  </v-card>
                </v-container>
                <user-notification-actions
                    :enabled.sync="form.settings.notifications.whatsapp.enabled"
                    :actions.sync="form.settings.notifications.whatsapp.actions"
                    @updateSettings="updateSettings"/>
              </v-tab-item>
            </v-tabs-items>

          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
