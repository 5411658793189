import Vue from 'vue'
import store from '../vuex'
import bus from '@utils/bus'

import menu from '../app/menu'

const getMenuInfo = (route) => {
    let menuItem = null
    if (route.name === 'home') {
        return null
    }
    menu.map(module => {
        let menuFound = module.children.find(item => {
            return item.route.includes(route.name)
        })
        if (menuFound) {
            menuItem = menuFound
        }
    })
    return menuItem
}

export default (to, from) => {
    Vue.nextTick(() => {
        if (typeof document !== 'undefined') {
            const menuItem = getMenuInfo(to)
            if (menuItem) {
                store.dispatch('setPageTitle', menuItem.text())
                document.title = `Gripo - ${menuItem.text()}`
            } else {
                store.dispatch('setPageTitle', `Gripo`)
                document.title = `Gripo`
            }
            store.dispatch('setPageSubTitle', '')
        }
    })

    if (to.name === from.name && JSON.stringify(to.params) !== JSON.stringify(from.params)) {
        bus.$emit('hide-loader')
    }
}
