<script>
import UserApiService from '../../services/domains/User'

import helperMixin from '@mixins/helper'
import { mapGetters } from 'vuex'
import { orderBy } from 'lodash'

const userService = UserApiService.build({})

export default {
  name: 'app-header-notifications',
  mixins: [helperMixin],
  data() {
    return {
      isLoadingData: false,
      notifications: [],
      showNotifications: false,
      showModalNotifications: false,
      toggle: true,
      notification: {
        notification: {}
      }
    }
  },
  watch: {
    showNotifications: function (value) {
      if (value && this.notificationsNew.length) {
        this.readNotifications()
      }
    },
    notificationsNew: function (value) {
      this.toggle = value.length > 0
    }
  },
  mounted() {
    this.startWebsocket()
    this.getNotifications()
  },
  computed: {
    ...mapGetters(['isLogged', 'currentUser']),
    notificationsNew() {
      return this.notifications.filter(item => item.sent_at && !item.read_at)
    },
    notificationsNotOpened() {
      return orderBy(this.notifications.filter(item => !item.filed_at), ['sent_at'], ['desc'])
    },
    websocketKey() {
      return `newAppNotification`
    },
    websocketChannelName() {
      return `${this.websocketKey}.${this.currentUser.id}`
    },
    websocketChannelListen() {
      return `.${this.websocketKey}`
    }
  },
  methods: {
    startWebsocket() {
      window.Echo.channel(this.websocketChannelName)
          .listen(this.websocketChannelListen, ({notification}) => {
            this.toggle = false
            this.notifications = [...this.notifications.filter(item => item.id !== notification.id)]
            this.notifications.unshift(notification)
            setTimeout(() => {
              this.toggle = true
            }, 300)
          })
    },
    async readNotifications() {
      const data = {
        notifications_id: this.notificationsNew.map(item => item.id),
        status: 'read'
      }
      await userService.updateNotifications(data)
      this.notificationsNew.map(item => {
        item.read_at = 'now'
        return item
      })
    },
    getNotifications() {
      this.isLoadingData = true
      userService.notifications()
          .then(response => {
            this.notifications.splice(0)
            if (response.length) {
              this.notifications = [...response]
            }
            this.isLoadingData = false
          })
          .catch(e => {
            console.log(e)
          })
    },
    notificationAction(notification) {
      const listNotification = this.notifications.find(item => item.id === notification.id)
      const listNotificationIndex = this.notifications.findIndex(item => item.id === notification.id)
      if (listNotification) {
        listNotification.status = 'filed'
        listNotification.filed_at = 'now'
        this.notifications.splice(listNotificationIndex, 1, listNotification)
      }
      const data = {
        notifications_id: [notification.id],
        status: 'filed'
      }
      userService.updateNotifications(data)
          .then(() => {
            if (notification.data && notification.data.extra && notification.data.extra.route) {
              this.$router.push({name: notification.data.extra.route, params: notification.data.extra.params})
            }
          })
          .catch(e => {
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <v-menu offset-y
          left
          :nudge-right="0"
          v-model="showNotifications"
          :close-on-content-click="false"
          :max-width="isMobile().any() ? '80vw' : '400px'"
          :min-width="isMobile().any() ? '80vw' : '400px'">
    <template v-slot:activator="{ on: menu }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn dark icon
                 class="mr-2"
                 :title="$t('components.main.headerNotifications.title')"
                 v-on="{ ...tooltip, ...menu }">
            <v-badge color="orange darken-3"
                     v-model="toggle"
                     :value="toggle">
              <template v-slot:badge>
                {{ notificationsNew.length }}
              </template>
              <span class="menu-icon"><i class="fas fa-bell"></i></span>
            </v-badge>
          </v-btn>
        </template>
        <span>{{ $t('components.main.headerNotifications.title') }}</span>
      </v-tooltip>
    </template>
    <v-card max-height="400">
      <v-list class="notifications-list" two-line v-if="notificationsNotOpened.length">
        <template v-for="(item, index) in notificationsNotOpened">
          <v-list-item :key="item.id"
                       subheader
                       three-line
                       ripple>
            <v-list-item-content @click="notificationAction(item)">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.message }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="item__info" :title="item.sent_at | dateTimeEnToBr">
              <v-list-item-action-text>
                {{ item.sent_at | dayMonthEnToBr }} {{ item.sent_at | timeEnToBr }}h
              </v-list-item-action-text>
              <v-btn fab depressed small dark color="error"
                     title="Remover notificação"
                     class="v-btn__mini mt-1"
                     @click="notificationAction(item)">
                <i class="fas fa-times"></i>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="index * 50 + 1" v-if="index + 1 < notificationsNotOpened.length"/>
        </template>
      </v-list>
      <v-card-text v-if="!notificationsNotOpened.length">
        {{ $t('components.main.headerNotifications.emptyListMessage') }}
      </v-card-text>
    </v-card>
  </v-menu>
</template>
