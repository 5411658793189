<script>
import DataService from '../../services/common/data'

import i18n from '../../../translation'

import { mapGetters } from 'vuex'

export default {
  name: 'locale-select',
  props: {
    fixed: {
      type: Boolean,
      default: false
    },
    sidebar: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      appLocale: null,
      dependencies: {
        locales: []
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  mounted() {
    this.getDependencies()
    this.appLocale = this.currentUser?.country?.locale || i18n.locale || 'pt_BR'
  },
  methods: {
    getDependencies() {
      DataService.get([{domain: 'locale'}]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    changeLocale(code) {
      i18n.locale = code
      this.getDependencies()
    }
  }
}
</script>

<template>
  <div :class="['locale-select-box', {'-fixed': fixed}, {'-sidebar': sidebar}]">
    <div class="locale-label">{{ $t('app.texts.localeText') }}:</div>
    <div class="locale-select">
      <v-select v-model="appLocale"
                :items="dependencies.locales"
                item-value="locale"
                :dark="dark"
                dense
                hide-details
                solo
                @change="changeLocale">
        <template slot="item" slot-scope="data">
          <div class="flex-c jcs gap-10">
            {{ data.item.name }}
          </div>
        </template>
        <template v-slot:selection="{ item }">
          <div class="flex-c jcs gap-10">
            {{ item.name }}
          </div>
        </template>
      </v-select>
    </div>
  </div>
</template>
