<script>
import DrawMatch from './Match.vue'

export default {
  name: 'draw-brackets-generator',
  components: {DrawMatch},
  props: {
    size: {
      type: Number,
      default: 16
    },
    rounds: {
      type: Array,
      default: function () {
        return []
      }
    },
    matches: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {
    roundsList() {
      return [...new Set(this.matches.slice().sort(item => item.round - item.round).map(item => item.round))]
    }
  },
  methods: {
    fixRound(round) {
      return round / 2
    },
    match(round, number) {
      const match = this.matches.find(match => {
        return round === match.round && number === match.number
      })
      if (!match) {
        return {
          id: null,
          datetime: ''
        }
      }
      return match
    }
  }
}
</script>

<template>
  <div>
    <div class="draw-brackets" v-if="matches.length > 0">
      <div class="bracket">
        <div class="round round-title" v-for="(round, index) in rounds" :class="['round-' + (round.round * 2)]"
             :key="index">
          {{ round.round_name }}
        </div>
      </div>
      <div class="bracket">
        <template v-for="(round, index) in roundsList">
          <div class="round" :class="['round-' + (round * 2)]" :key="index">
            <template v-for="(game, gameIndex) in fixRound(round)">
              <div class="match-overlay" :style="{top: (gameIndex * (100 / round) * 2) + '%'}">
                <div class="match-datetime" v-if="match(round, game).id">
                  {{ match(round, game).date | dateEnToBr }} {{ match(round, game).hour }}
                  <span v-if="match(round, game).court"> - {{ match(round, game).court.name }}</span>
                </div>
              </div>
              <div></div>
              <draw-match :key="round + '-' + game + '-pair1'"
                          :matches="matches"
                          :round="round"
                          :game="game"
                          :pair-number="1"/>
              <draw-match :key="round + '-' + game + '-pair2'"
                          :matches="matches"
                          :round="round"
                          :game="game"
                          :pair-number="2"/>
            </template>
          </div>
        </template>
        <div class="round round-1" :key="999999999">
          <div class="match">
            <div class="match-content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
