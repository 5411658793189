<script>
export default {
  name: 'draw-match-result',
  props: {
    sets: {
      type: Array,
      default: () => []
    },
    pairId: {
      type: Number
    }
  },
  computed: {
    pairSet() {
      return this.sets.find(item => item.id === this.pairId)
    },
    pairWinner() {
      return this.pairSet.winner === true
    },
    pairResults() {
      return this.pairSet.result_grid
    }
  }
}
</script>

<template>
  <div class="sets-result">
    <div class="results">
      <div :class="['result', {'winner': pairWinner}]" v-for="result in pairResults">
        {{result}}
      </div>
    </div>
  </div>
</template>
