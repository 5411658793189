import i18n from '../translation'

const requiredText = () => i18n.t('app.validation.required')
const invalidEmail = () => i18n.t('app.validation.invalidEmail')
const invalidCpf = () => i18n.t('app.validation.invalidCpf')
const minLength = (minLength) => i18n.t('app.validation.minLength', {minLength})
const maxLength = (maxLength) => i18n.t('app.validation.maxLength', {maxLength})
const sameAs = () => i18n.t('app.validation.sameAs')
const invalidPhone = () => i18n.t('app.validation.invalidPhone')

export const VALIDATION_REQUIRED = requiredText()
export const VALIDATION_EMAIL = invalidEmail()
export const VALIDATION_CPF = invalidCpf()
export const VALIDATION_MINLENGTH = (minLengthNumber) => minLength(minLengthNumber)
export const VALIDATION_MAXLENGTH = (maxLengthNumber) => maxLength(maxLengthNumber)
export const VALIDATION_EQUAL = sameAs()
export const VALIDATION_PHONE = invalidPhone()
