var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"matches-list-item"},[_c('v-layout',{staticClass:"item-match",attrs:{"align-center":""}},[(_vm.match.metadata.loading)?_c('v-flex',{staticClass:"text-center pt-5 pb-5"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):[_c('v-flex',{class:[
                  'item-pair1', `-sets-${_vm.getSetsNumber}`,
                  {'-hide-results': _vm.match.sets.length === 0},
                  {'text-capitalize': _vm.match.round === 99}
                  ],attrs:{"xs10":""}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.pair1Names)}})]),_c('v-flex',{staticClass:"item-versus",attrs:{"xs4":""}},[_c('div',{class:['item-infos', `-sets-${_vm.getSetsNumber}`]},[(!_vm.showScoreboard)?[_c('match-pair-result',{key:_vm.match.pair1_id,staticClass:"pair-result pair1-result",attrs:{"sets":_vm.match.sets,"pair-id":_vm.match.pair1_id}}),_c('match-pair-result',{key:_vm.match.pair2_id,staticClass:"pair-result pair2-result",attrs:{"sets":_vm.match.sets,"pair-id":_vm.match.pair2_id}})]:_vm._e(),(_vm.showCourt && _vm.match.court_name)?_c('div',{staticClass:"item-court",attrs:{"title":_vm.match.court_name}},[_vm._v(" "+_vm._s(_vm.match.court_name)+" ")]):_vm._e(),(_vm.showDate && _vm.match.date)?_c('div',{staticClass:"item-date"},[_vm._v(" "+_vm._s(_vm._f("dateEnToBr")(_vm.match.date))+" ")]):_vm._e(),(_vm.match.hour)?_c('div',{staticClass:"item-hour"},[_vm._v(_vm._s(_vm.match.hour))]):_vm._e(),(_vm.showPlayerClass)?_c('div',{class:[!_vm.showDate ? 'item-date' : 'item-player-class']},[_vm._v(_vm._s(_vm.match.player_class)+" ")]):_vm._e(),(_vm.showRound && _vm.match.round_name)?_c('div',{staticClass:"item-round"},[(_vm.match.type === 'groups' && _vm.match.round === 99)?[_vm._v(" Chave "+_vm._s(_vm.match.group)+" ")]:[_c('span',[_vm._v(_vm._s(_vm.match.round_name))]),(_vm.match.round > 2)?_c('span',{staticClass:"ml-1"},[_vm._v(" - J"+_vm._s(_vm.match.number))]):_vm._e()]],2):_vm._e(),(_vm.showScoreboard)?_c('div',{staticClass:"item-live"},[_c('span',{staticClass:"live-dot"}),_c('span',{staticClass:"text"},[_vm._v("AO VIVO")])]):_vm._e()],2)]),_c('v-flex',{class:[
                  'item-pair2', `-sets-${_vm.getSetsNumber}`,
                  {'-hide-results': _vm.match.sets.length === 0},
                  {'text-capitalize': _vm.match.round === 99}
                  ],attrs:{"xs10":""}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.pair2Names)}})])]],2),(_vm.showScoreboard)?_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-flex'),_c('v-flex',{staticClass:"text-center",attrs:{"sm5":"","xs9":""}},[_c('div',{staticClass:"scoreboard"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"names"}),_c('div',{staticClass:"points-label"},[_vm._v(" Placar ")]),_vm._l((_vm.scoreboard.pair1.sets),function(set){return _c('div',{staticClass:"set-label"},[_vm._v(" "+_vm._s(set.number)+"º Set ")])})],2),_c('div',{staticClass:"item"},[_c('div',{staticClass:"names"},[_vm._v(" "+_vm._s(_vm.scoreboard.pair1.names)+" "),(_vm.scoreboard.pair1.service)?_c('div',{staticClass:"ball"},[_c('Ball')],1):_vm._e()]),_c('div',{staticClass:"points"},[_vm._v(" "+_vm._s(_vm.scoreboard.pair1.points)+" ")]),_vm._l((_vm.scoreboard.pair1.sets),function(set){return [_c('div',{staticClass:"set"},[_vm._v(_vm._s(set.value))])]})],2),_c('div',{staticClass:"item"},[_c('div',{staticClass:"names"},[_vm._v(" "+_vm._s(_vm.scoreboard.pair2.names)+" "),(_vm.scoreboard.pair2.service)?_c('div',{staticClass:"ball"},[_c('Ball')],1):_vm._e()]),_c('div',{staticClass:"points"},[_vm._v(" "+_vm._s(_vm.scoreboard.pair2.points)+" ")]),_vm._l((_vm.scoreboard.pair2.sets),function(set){return [_c('div',{staticClass:"set"},[_vm._v(_vm._s(set.value))])]})],2)])]),_c('v-flex')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }