<script>
export default {
  name: 'loading-stage-match',
  props: {
    image: {
      default: true,
      type: Boolean
    },
    lines: {
      default: 2,
      type: Number
    }
  }
}
</script>

<template>
  <div class="skeleton-loading-match">
    <div class="placeholder-side">
      <div v-for="line in lines"
           class="placeholder-item"
           :key="line"></div>
    </div>
    <div class="placeholder-item placeholder-image"/>
    <div class="placeholder-side">
      <div v-for="line in lines"
           class="placeholder-item"
           :key="line"></div>
    </div>
  </div>
</template>
