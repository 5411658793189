<script>
  export default {
    name: 'schedule-status',
    props: ['status', 'transactions', 'paid'],
    computed: {
      scheduleStatus () {
        let status = this.status
        if (this.transactions.length && status !== 'canceled') {
          return status + '_with_payment'
        }
        return status
      },
      chipStatusColor () {
        switch (this.scheduleStatus) {
          case 'created':
          case 'created_with_payment':
            return {color: 'grey'}
          case 'scheduled':
          case 'scheduled_with_payment':
            return {color: 'green'}
          case 'canceled':
            return {color: 'red'}
          default:
            return {color: ''}
        }
      }
    }
  }
</script>

<template>
    <v-chip dark small :color="chipStatusColor.color"
            class="mr-1">
        {{$t(`app.domains.schedule.components.list.table.items.status.${scheduleStatus}`)}}
    </v-chip>
</template>
