import Register from './components/Register'
import ConfirmPhone from './components/ConfirmPhone'
import Form from './components/Form'
import Friends from './components/Friends'
import ActivateAccount from './components/ActivateAccount'
import ConfirmAccount from './components/ConfirmAccount'

import i18n from '../../../translation'

export default [
  {
    path: i18n.t('app.domains.user.routes.register.path'),
    component: Register,
    name: 'user.register',
    meta: {
      auth: false,
      breadcrumb: {
        name: i18n.t('app.domains.user.routes.register.title')
      }
    }
  },
  {
    path: i18n.t('app.domains.user.routes.confirmPhone.path'),
    component: ConfirmPhone,
    name: 'user.confirm-phone',
    props: true,
    meta: {
      auth: false,
      breadcrumb: {
        name: i18n.t('app.domains.user.routes.confirmPhone.title')
      }
    }
  },
  {
    path: i18n.t('app.domains.user.routes.activateAccount.path'),
    component: ActivateAccount,
    name: 'user.activate-account',
    props: true,
    meta: {
      auth: false,
      breadcrumb: {
        name: i18n.t('app.domains.user.routes.activateAccount.title')
      }
    }
  },
  {
    path: i18n.t('app.domains.user.routes.confirmAccount.path'),
    component: ConfirmAccount,
    name: 'user.confirm-account',
    props: true,
    meta: {
      auth: false,
      breadcrumb: {
        name: i18n.t('app.domains.user.routes.confirmAccount.title')
      }
    }
  },
  {
    path: i18n.t('app.domains.user.routes.show.path'),
    component: Form,
    name: 'user.show',
    meta: {
      breadcrumb: {
        name: i18n.t('app.domains.user.routes.show.title')
      }
    },
    props: true
  },
  {
    path: i18n.t('app.domains.user.routes.friends.path'),
    component: Friends,
    name: 'user.friends',
    meta: {
      breadcrumb: {
        name: i18n.t('app.domains.user.routes.friends.title')
      }
    }
  }
]
