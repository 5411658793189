import Api from '../common/api/Api'

export default class AuthService extends Api {
  constructor () {
    super({domain: 'auth'})
  }

  async login (data) {
    return this.post({route: 'login', data})
  }

  async loginSocial (data) {
    return this.post({route: 'social', data})
  }

  async loginByHash (data) {
    return this.post({route: 'hash', data})
  }

  async logout () {
    return this.get({route: 'logout'})
  }
}
