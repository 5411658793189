<script>
import helpers from '@mixins/helper'
import { mapGetters } from 'vuex'

const vapidPublicKey = () => process.env.VUE_APP_VAPID_PUBLIC_KEY

export default {
  mixins: [helpers],
  name: 'app-enable-notifications',
  data() {
    return {
      notificationsSupported: false,
      notificationsEnabled: false,
      serviceWorkerRegistration: null,
      subscription: null
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    subscriptionData() {
      if (this.subscription) {
        const key = this.subscription.getKey('p256dh')
        const token = this.subscription.getKey('auth')
        return {
          endpoint: this.subscription.endpoint,
          public_key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
          auth_token: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null
        }
      }
      return {}
    }
  },
  created() {
    if ('Notification' in window && 'serviceWorker' in navigator) {
      this.notificationsSupported = true
    }
  },
  mounted() {
    if (navigator.serviceWorker) {
      this.findSubscription()
          .then(sub => {
            if (sub === null) {
              this.subscription = null
              this.notificationsEnabled = false
              this.$emit('updatePushNotifications', {enabled: false})
            } else {
              this.subscription = sub
              this.notificationsEnabled = true
              this.$emit('updatePushNotifications', {enabled: true, sub: sub})
            }
          })
    }
  },
  methods: {
    toggleSubscription(value) {
      if (value) {
        Notification.requestPermission()
            .then(result => {
              if (result === 'granted') {
                this.createSubscription()
                    .then(sub => {
                      this.subscription = sub
                      this.notificationsEnabled = true
                      this.$emit('updatePushNotifications', {enabled: true, sub: this.subscriptionData})
                    })
              } else {
                this.subscription = null
                this.notificationsEnabled = false
                this.$emit('updatePushNotifications', {enabled: false})
                console.info('User did not granted permission')
              }
            })
      } else {
        if (this.subscription !== null) {
          this.findSubscription()
              .then(sub => {
                sub.unsubscribe()
                    .then(() => {
                      this.subscription = null
                      this.notificationsEnabled = false
                      this.$emit('updatePushNotifications', {enabled: false})
                    })
                    .catch(e => {
                      console.log('Unsubscription error: ', e)
                    })
              })
        }
      }
    },
    createSubscription() {
      if (this.serviceWorkerRegistration === null) {
        return navigator.serviceWorker.ready
            .then(swReg => {
              this.serviceWorkerRegistration = swReg
              return this.subscribe(this.serviceWorkerRegistration)
            })
      } else {
        return this.subscribe(this.serviceWorkerRegistration)
      }
    },
    getSubscription(swReg) {
      return swReg.pushManager.getSubscription()
    },
    subscribe(swReg) {
      const convertedVapidPublicKey = this.urlBase64ToUint8Array(vapidPublicKey())
      return swReg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidPublicKey
      })
    },
    findSubscription() {
      return navigator.serviceWorker.ready
          .then(swReg => {
            this.serviceWorkerRegistration = swReg
            return this.getSubscription(this.serviceWorkerRegistration)
          })
    }
  }
}
</script>

<template>
  <v-switch
      :disabled="!notificationsSupported"
      v-model="notificationsEnabled"
      color="primary"
      hide-details
      class="v-switch-no-margin"
      @change="toggleSubscription"/>
</template>
