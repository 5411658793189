import Show from './components/Show'
import Matches from './components/Matches'
import Pairs from './components/Pairs'
import Groups from './components/Groups'
import List from './components/List'

import i18n from '../../../translation'

export default [
  {
    path: i18n.t('app.domains.stage.routes.show.path'),
    component: Show,
    name: 'stage.show',
    meta: {
      auth: false,
      breadcrumb: {
        name: i18n.t('app.domains.stage.routes.show.title')
      }
    },
    children: [
      {
        path: i18n.t('app.domains.stage.routes.pairs.path'),
        component: Pairs,
        name: 'stage.show.pairs',
        meta: {
          auth: false,
          breadcrumb: {
            name: i18n.t('app.domains.stage.routes.pairs.title')
          }
        }
      },
      {
        path: i18n.t('app.domains.stage.routes.groups.path'),
        component: Groups,
        name: 'stage.show.groups',
        meta: {
          auth: false,
          breadcrumb: {
            name: i18n.t('app.domains.stage.routes.groups.title')
          }
        }
      },
      {
        path: i18n.t('app.domains.stage.routes.matches.path'),
        component: Matches,
        name: 'stage.show.matches',
        meta: {
          auth: false,
          breadcrumb: {
            name: i18n.t('app.domains.stage.routes.matches.title')
          }
        }
      }
    ]
  },
  {
    path: i18n.t('app.domains.stage.routes.list.path'),
    component: List,
    name: 'stage.list',
    meta: {
      auth: false,
      breadcrumb: {
        name: i18n.t('app.domains.stage.routes.list.title')
      }
    }
  }
]
