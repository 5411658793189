import {routes as home} from './domains/home'
import {routes as common} from './domains/common'
import {routes as auth} from './domains/auth'
import {routes as registration} from './domains/registration'
import {routes as stage} from './domains/stage'
import {routes as user} from './domains/user'
import {routes as schedule} from './domains/schedule'
import {routes as order} from './domains/order'

export default [
  ...home,
  ...common,
  ...auth,
  ...registration,
  ...stage,
  ...user,
  ...schedule,
  ...order
]
