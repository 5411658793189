<script>
export default {
  name: 'match-pair-result',
  props: ['sets', 'pairId'],
  computed: {
    pairSet() {
      return this.sets.find(item => item.id === this.pairId)
    },
    pairWinner() {
      return this.pairSet.winner === true
    },
    pairResults() {
      return this.pairSet.result_grid
    },
    setsClass() {
      return 'sets-' + this.sets[0].sets
    }
  }
}
</script>

<template>
  <div v-if="pairSet" :class="{'-winner': pairWinner}">
    <div class="result-set-box">
      <div class="result-set"
           v-for="(result, index) in pairResults"
           :key="index"
           :class="[{'-winner': pairWinner}, setsClass]">
        {{ result }}
      </div>
    </div>
  </div>
</template>
