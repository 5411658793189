import Vue from 'vue'
import Vuex from 'vuex'
import {vuex as app} from '../app'
import * as TYPES from './types'
import {setToken} from '../http'
import {set, remove} from '@utils/storage'
import i18n from '../translation'

import {isEmpty} from 'lodash'

Vue.use(Vuex)

const defaultLoadingMessage = () => i18n.t('app.texts.defaultLoading')

export default new Vuex.Store({
  state: {
    user: {
      id: '',
      name: '',
      avatar: '',
      email: '',
      phone: '',
      cpf: '',
      country_id: '',
      country: {
        code: '',
        locale: '',
      },
    },
    permissions: [],
    token: '',
    city: {
      id: '',
      name: '',
      slug: '',
    },
    club: {
      id: '',
      name: '',
      slug: '',
      tenant_id: '',
      tenant_settings: {}
    },
    tenantId: '',
    locale: 'pt_BR',
    pageTitle: 'Gripo',
    pageSubTitle: '',
    isLoading: true,
    loadingMessage: defaultLoadingMessage(),
    showSidebar: false,
    showHeader: true,
    notification: {
      type: '',
      title: '',
      message: ''
    }
  },
  mutations: {
    [TYPES.AUTH_USER] (state, payload) {
      state.user = payload
    },
    [TYPES.AUTH_PERMISSIONS] (state, payload) {
      state.permissions = payload
    },
    [TYPES.AUTH_TOKEN] (state, payload) {
      setToken(payload)
      state.token = payload
    },
    [TYPES.AUTH_CITY] (state, payload) {
      state.city = payload
    },
    [TYPES.AUTH_CLUB] (state, payload) {
      state.club = payload
    },
    [TYPES.AUTH_TENANT_ID] (state, payload) {
      state.tenantId = payload
    },
    [TYPES.LOCALE] (state, payload) {
      state.locale = payload
    },
    [TYPES.PAGE_TITLE] (state, payload) {
      state.pageTitle = payload
    },
    [TYPES.PAGE_SUB_TITLE] (state, payload) {
      state.pageSubTitle = payload
    },
    [TYPES.LOADER_SHOW] (state, payload) {
      state.isLoading = payload
    },
    [TYPES.LOADER_MESSAGE] (state, payload) {
      state.loadingMessage = payload
    },
    [TYPES.TOGGLE_SHOW] (state, payload) {
      state.showSidebar = payload
    },
    [TYPES.NOTIFICATION] (state, payload) {
      state.notification = payload
    },
    [TYPES.SHOW_HEADER] (state, payload) {
      state.showHeader = payload
    }
  },
  actions: {
    login: ({dispatch}, payload) => {
      return new Promise((resolve) => {
        const data = {
          token: payload.token,
          user: payload.user,
          permissions: payload.permissions,
          city: payload.city,
          club: payload.club
        }
        dispatch('setUpAuth', data).then(() => resolve())
      })
    },
    logout: (context) => {
      return new Promise((resolve) => {
        remove('user')
        remove('permissions')
        remove('token')
        remove('city')
        remove('club')
        remove('tenant_id')
        context.commit(TYPES.AUTH_USER, {})
        context.commit(TYPES.AUTH_PERMISSIONS, [])
        context.commit(TYPES.AUTH_TOKEN, '')
        context.commit(TYPES.AUTH_CITY, {id: '', name: ''})
        context.commit(TYPES.AUTH_CLUB, {id: '', name: ''})
        context.commit(TYPES.AUTH_TENANT_ID, '')
        resolve()
      })
    },
    setUpAuth: (context, payload) => {
      return new Promise((resolve) => {
        set('user', payload.user)
        set('permissions', payload.permissions)
        set('token', payload.token)
        set('city', payload.city)
        set('club', payload.club)
        set('tenant_id', payload.tenant_id)
        context.commit(TYPES.AUTH_USER, payload.user)
        context.commit(TYPES.AUTH_PERMISSIONS, payload.permissions)
        context.commit(TYPES.AUTH_TOKEN, payload.token)
        context.commit(TYPES.AUTH_CITY, payload.city)
        context.commit(TYPES.AUTH_CLUB, payload.club)
        context.commit(TYPES.AUTH_TENANT_ID, payload.tenant_id)
        resolve()
      })
    },
    setCity: (context, payload) => {
      return new Promise((resolve) => {
        set('city', payload)
        context.commit(TYPES.AUTH_CITY, payload)
        resolve()
      })
    },
    setClub: (context, payload) => {
      return new Promise((resolve) => {
        set('club', payload)
        context.commit(TYPES.AUTH_CLUB, payload)
        resolve()
      })
    },
    setTenant: (context, payload) => {
      return new Promise((resolve) => {
        set('tenant_id', payload)
        context.commit(TYPES.AUTH_TENANT_ID, payload)
        resolve()
      })
    },
    setUser: (context, payload) => {
      set('user', payload)
      context.commit(TYPES.AUTH_USER, payload)
    },
    setLocale: (context, payload) => {
      set('locale', payload)
      context.commit(TYPES.LOCALE, payload)
    },
    setPageTitle: (context, payload) => {
      context.commit(TYPES.PAGE_TITLE, payload)
    },
    setPageSubTitle: (context, payload) => {
      context.commit(TYPES.PAGE_SUB_TITLE, payload)
    },
    showLoader: (context, payload) => {
      context.commit(TYPES.LOADER_MESSAGE, (payload ? payload : defaultLoadingMessage()))
      context.commit(TYPES.LOADER_SHOW, true)
    },
    hideLoader: (context) => {
      setTimeout(() => {
        context.commit(TYPES.LOADER_MESSAGE, defaultLoadingMessage())
        context.commit(TYPES.LOADER_SHOW, false)
      }, 250)
    },
    toggleSidebar: (context, payload) => {
      context.commit(TYPES.TOGGLE_SHOW, payload)
    },
    showNotification: (context, payload) => {
      context.commit(TYPES.NOTIFICATION, payload)
    },
    toggleHeader: (context, payload) => {
      context.commit(TYPES.SHOW_HEADER, payload)
    }
  },
  getters: {
    isLogged: ({token}) => !isEmpty(token),
    token: ({token}) => token,
    currentUser: ({user}) => user,
    permissions: ({permissions}) => permissions,
    city: ({city}) => city,
    club: ({club}) => club,
    tenantId: ({tenantId}) => tenantId,
    locale: ({locale}) => locale,
    pageTitle: ({pageTitle}) => pageTitle,
    pageSubTitle: ({pageSubTitle}) => pageSubTitle,
    isLoading: ({isLoading}) => isLoading,
    loadingMessage: ({loadingMessage}) => loadingMessage,
    showSidebar: ({showSidebar}) => showSidebar,
    notification: ({notification}) => notification,
    showHeader: ({showHeader}) => showHeader
  },
  modules: {
    ...app
  }
})
