<script>
import TransactionPaymentModal from "./TransactionPaymentModal"

export default {
  name: 'transaction-status',
  components: {TransactionPaymentModal},
  props: ['status', 'transaction'],
  data() {
    return {
      showPixModal: false
    }
  },
  computed: {
    payDateFormatted() {
      return this.$options.filters.dateTimeEnToBr(this.transaction.pay_date)
    },
    chipStatus() {
      switch (this.status) {
        case 'created':
          return {
            label: this.$t('components.general.transactionStatus.created'),
            color: 'teal'
          }
        case 'approved':
          return {
            label: this.$t('components.general.transactionStatus.approved'),
            color: 'green'
          }
        case 'queued':
          return {
            label: this.$t('components.general.transactionStatus.queued'),
            color: 'teal'
          }
        case 'canceled':
          return {
            label: this.$t('components.general.transactionStatus.canceled'),
            color: 'red'
          }
        default:
          return {
            label: '',
            color: ''
          }
      }
    }
  },
  methods: {
    openPixModal() {
      this.showPixModal = true
    }
  }
}
</script>

<template>
  <div class="pt-1 pb-1">
    <div v-if="transaction && status !== 'canceled'">
      <v-chip outlined small color="green" v-if="transaction.status === 'paid'"
              style="height: auto; line-height: 18px;">
        <span v-html="$t('components.general.transactionStatus.confirmedAt', {pay_date: payDateFormatted})"></span>
      </v-chip>
      <v-chip outlined small color="red" v-if="transaction.status === 'canceled'">
        {{ $t('components.general.transactionStatus.canceled') }}
      </v-chip>
      <div v-if="transaction.status === 'created'">
        <div>
          <v-chip outlined small color="teal">
            {{ $t('components.general.transactionStatus.waitingPayment') }}
          </v-chip>
        </div>
        <div class="mt-1" v-if="transaction">
          <v-btn color="success"
                 :href="transaction.hash"
                 v-if="transaction.hash"
                 target="_blank"
                 small>
            <i class="fas fa-money-bill-alt"></i>
            <span class="ml-2">{{ $t('components.general.transactionStatus.doPaymentBtn') }}</span>
          </v-btn>
          <v-btn color="success"
                 v-else
                 @click="openPixModal"
                 small>
            <i class="fas fa-money-bill-alt"></i>
            <span class="ml-2">{{ $t('components.general.transactionStatus.doPaymentBtn') }}</span>
          </v-btn>
        </div>
      </div>
    </div>
    <div v-else>
      <v-chip outlined small :color="chipStatus.color">
        {{ chipStatus.label }}
      </v-chip>
    </div>
    <transaction-payment-modal :transaction="transaction"
                               :show.sync="showPixModal"/>
  </div>
</template>
