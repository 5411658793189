import Home from './components/Home'

import i18n from '../../../translation'

export default [
  {
    path: i18n.t('app.domains.home.routes.home.path'),
    component: Home,
    name: 'home',
    meta: {
      auth: false,
      breadcrumb: {
        name: i18n.t('app.domains.home.routes.home.title')
      }
    }
  }
]
