import Vue from 'vue'
import App from './app/components/main/App.vue'
import router from './router'
import store from './vuex'
import http from './http'
import i18n from './translation'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import VueAnalytics from 'vue-analytics'
import vuetify from './plugins/vuetify'
import VueViewer from 'v-viewer'

import './assets/css/all.css' // Fontawesome 5
import './assets/sass/app.scss'
import './assets/sass/responsive.scss'

import 'sweetalert2/dist/sweetalert2.css'
import 'viewerjs/dist/viewer.css'

import './app/filters'

Vue.use(http, {router})
Vue.use(Vuelidate)
Vue.use(VueTheMask)
Vue.use(VueViewer)

Vue.config.productionTip = false

Vue.use(VueAnalytics, {
    id: 'UA-76871119-2',
    router,
    disabled: process.env.NODE_ENV === 'development'
})

const {DeviceUUID} = require('device-uuid')
const {language} = new DeviceUUID().parse()
i18n.locale = ['pt_BR', 'es_ES'].includes(language) ? language : 'pt_BR'

/*
Sentry.init({
  dsn: 'https://4981182aba4b4057829f1c03b0018d9e@sentry.io/2610996',
  integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  logErrors: true,
  environment: process.env.NODE_ENV
})

Vue.config.errorHandler = err => {
  Sentry.captureException(err)
  console.log('Exception: ', err)
}
*/

new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
