import Api from '../common/api/Api'

export default class ClubService extends Api {
  constructor () {
    super({domain: 'club'})
  }

  async getAvailable (data) {
    return this.post({route: 'available', data, cache: 'clubsAvailable'})
  }

  async slug (slug, hash) {
    return this.get({route: `${slug}/${hash || ''}`})
  }
}
