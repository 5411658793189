<script>
export default {
  name: 'empty-list',
  props: {
    title: {
      default: function () {
        return this.$t('components.general.emptyList.defaultTitle')
      },
      Type: String
    },
    message: {
      default: function () {
        return this.$t('components.general.emptyList.defaultMessage')
      },
      Type: String
    },
    showIcon: {
      default: true,
      Type: Boolean
    }
  }
}
</script>

<template>
  <div class="empty-list">
    <div class="empty-icon" v-if="showIcon">
      <img src="/static/imgs/player.svg" alt="">
    </div>
    <h3 v-if="title">{{ title }}</h3>
    <p v-if="message">{{ message }}</p>
    <slot></slot>
  </div>
</template>
