<script>
import bus from '@utils/bus'

export default {
  mounted() {
    setTimeout(() => {
      bus.$emit('hide-loader')
    }, 100)
  }
}
</script>

<template>
  <div>
    <div v-if="!$vuetify.breakpoint.xs" class="page-header flex-header list-header">
      <h2 class="headline page-title">{{ $t('app.domains.common.components.termsConditions.pageTitle') }}</h2>
      <div></div>
    </div>
    <v-card class="page-content-card">
      <v-container fluid grid-list-md>
        <v-layout row wrap>
          <v-flex xs12 class="common-text">
            <h4>Geral</h4>

            <p>
              O Gripo oferece a você a possibilidade de fazer reservas de quadra, inscrição em torneios,
              acompanhamento de jogos nos clubes parceiros ao Gripo. Ao criar um cadastro e ativar sua conta no Gripo,
              você aceita
              que os clubes parceiros possam utilizar os seus dados de contato como nome, e-mail e telefone para o envio
              de informações sobre reservas, aulas, pagamentos, inscrições em torneios e débitos pendentes de pagamento.
              O Gripo não se responsabiliza pelo cumprimento nem qualidade do serviço por
              parte do clube, desligando-se de qualquer ação que possa provocar algum dano ou prejuízo à
              você. O Gripo é um mero assistente na relação comercial entre você e os clubes parceiros.
            </p>

            <v-divider class="mt-4 mb-4"/>
            <h3>Reserva de Quadra</h3>

            <h4>Alterações</h4>

            <p>Para modificar a data e/ou hora da sua reserva, comunique-se diretamente com o clube. As
              alterações estarão sujeitas à disponibilidade do clube.</p>

            <h4>Cancelamentos</h4>

            <p>O cancelamento da reserva pode ser feito pelo Gripo desde que siga as configurações determinadas pelo
              clube, caso você não consiga cancelar sua reserva pelo Gripo entre em contato com o clube para
              solicitar o cancelamento.</p>

            <h4>Não comparecimentos nas reservas de horárioo</h4>

            <p>
              Se você não for ao clube na data/hora reservada o clube pode definir isso como um não comparecimento.
              Assim, ele se reserva o direito de deixar você de fora de promoções e até bloquear suas
              futuras reservas por um período de 6 meses.</p>
            <v-divider class="mt-4 mb-4"/>

            <h3>Torneios</h3>

            <h4>Alteração de Inscrições</h4>

            <p>Para modificar a sua inscrição entre em contato diretamente com o clube.
              As alterações estarão sujeitas à disponibilidade do clube.</p>

            <h4>Cancelamento de Inscrições</h4>

            <p>O cancelamento da inscrição deve ser solicitada entrando em contato diretamente com o clube.</p>

            <h4>Regulamentos e Regras</h4>

            <p>O Gripo não possui regulamento sobre as regras gerais dos torneios, os clubes são responsáveis por
              fornecer e aplicar as regras de um regulamento definido por eles.</p>
            <v-divider class="mt-4 mb-4"/>

            <h4>Pagamentos</h4>

            <p>Os valores pagos dentro do Gripo estão diretamento ligados ao serviço solicitado ao clube de
              sua escolha. O Gripo não se responsabiliza por qualquer reembolso, mesmo em caso de
              cancelamento de reserva com antecedência. Isto deverá ser tratado diretamente com o clube.
              Também não nos responsabilizamos por qualquer custo que o clube queira cobrar, mesmo que o
              estabelecimento tente nos atribuí-lo. Relembramos que não existe custo extra algum para você
              usar nosso serviço.</p>

            <h4>Informações pessoais</h4>

            <p>Para utilizar os serviços de reservas e inscrição em torneios você precisa minimamente informar seu nome,
              telefone e e-mail. As informações devem ser precisas e verdadeiras. Qualquer informação
              pessoal fornecida, só será utilizada pelo Gripo onde se aplicar, de
              acordo com os termos da nossa Política de Privacidade. As informações fornecidas pelo
              usuário serão utilizadas expressamente com a finalidade de realizar a reserva ou a
              inscrição em torneios, esclarecemos que o Gripo não se responsabiliza por qualquer uso impróprio destas
              informações por parte dos clubes.</p>

            <h4>Cookies</h4>

            <p>O Gripo poderá utilizar cookies do seu navegador e outras tecnologias, para
              acompanhar suas interações com o nosso site e com isso te oferecer uma visita
              personalizada.</p>

            <h4>Políticas de Privcidade</h4>

            <p>Aproveite para ler e conhecer as nossas <a href="https://gripo.app/politicas-de-privacidade">políticas de privacidade</a></p>

            <h4>Fale conosco</h4>

            <p><a href="mailto:contato@gripo.com.br">contato@gripo.com.br</a></p>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>
