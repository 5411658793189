<script>
import UserApiService from '../../../services/domains/User'
import * as notifyService from '../../../services/common/notify'

import helpers from '@mixins/helper'
import bus from '@utils/bus'

import { required } from 'vuelidate/lib/validators'

const userService = UserApiService.build({})

export default {
  mixins: [helpers],
  data() {
    return {
      isSubmitted: false,
      trouble: 'recovery-password',
      troubles: [
        {
          key: 'recovery-password',
          title: this.$t('app.domains.auth.components.trouble.recoveryOptionsText.forgetPassword.title'),
          text: this.$t('app.domains.auth.components.trouble.recoveryOptionsText.forgetPassword.text'),
        },
        {
          key: 'magic-link',
          title: this.$t('app.domains.auth.components.trouble.recoveryOptionsText.magicLink.title'),
          text: this.$t('app.domains.auth.components.trouble.recoveryOptionsText.magicLink.text'),
        },
        {
          key: 'activate-account',
          title: this.$t('app.domains.auth.components.trouble.recoveryOptionsText.resendActivationCode.title'),
          text: this.$t('app.domains.auth.components.trouble.recoveryOptionsText.resendActivationCode.text'),
        },
      ],
      auth: {
        login: ''
      }
    }
  },
  validations: {
    auth: {
      login: {required}
    }
  },
  mounted() {
    this.focusLogin()
  },
  methods: {
    focusLogin() {
      setTimeout(() => {
        this.$refs.formLogin.focus()
        bus.$emit('hide-loader')
      }, 300)
    },
    recoveryAccess() {
      if (!this.auth.login) {
        notifyService.warning({message: this.$t('app.domains.auth.components.trouble.recoveryAccessValidationMessage')})
        return
      }
      this.isSubmitted = true
      bus.$emit('show-loader')
      let data = {
        trouble: this.trouble,
        login: this.auth.login
      }
      userService.recoveryAccess(data)
          .then(response => {
            if (response.message) {
              notifyService.success({message: response.message})
            }
            if (response.code) {
              this.$router.push({name: 'user.confirm-phone', params: {phone: this.auth.login}})
            }
            bus.$emit('hide-loader')
            this.isSubmitted = false
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <v-container fill-height justify-center class="home-screens">
    <div>
      <v-layout wrap class="home-screens-box">
        <v-flex xs24>
          <div class="home-logo">
            <img src="/static/imgs/logo-light.png" alt="">
          </div>
          <h2 class="title white--text mb-5 text-center">
            {{ $t('app.domains.auth.components.trouble.pageTitle') }}
          </h2>
          <div class="auth-trouble">
            <template v-for="item in troubles">
              <button type="button"
                      :class="['item', {'-active': trouble === item.key}]"
                      :key="item.key"
                      @click="trouble = item.key">
                <v-checkbox
                    class="mt-0"
                    v-model="trouble"
                    :label="item.title"
                    :value="item.key"
                    dark
                    hide-details/>
                <div class="text">
                  {{ item.text }}
                </div>
              </button>
            </template>
          </div>
          <v-text-field v-model="auth.login"
                        ref="formLogin"
                        :label="$t('app.domains.auth.components.trouble.recoveryTextLabel')"
                        dark
                        @keyup.enter="recoveryAccess"
                        type="text">
            <template v-slot:prepend>
              <i class="fas fa-user"></i>
            </template>
          </v-text-field>
          <v-btn color="light-green darken-1 mb-2"
                 dark
                 block
                 large
                 :loading="isSubmitted"
                 @click="recoveryAccess">
            <span class="pr-2">{{ $t('app.texts.sendData') }}</span>
            <i class="fas fa-chevron-right"></i>
          </v-btn>
          <div class="mt-5">
            <v-btn text block small dark :to="{name: 'auth.login'}">
              {{ $t('app.texts.backLogin') }}
            </v-btn>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>
