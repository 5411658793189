<script>
import StageCard from '../../../components/general/StageCard'
import LoadingContent from '../../../components/general/placeholders/LoadingContent'
import StageApiService from '../../../services/domains/Stage'
import CommonApiService from '../../../services/domains/Common'

const stageService = StageApiService.build({})
const commonService = CommonApiService.build({})

import {set, get} from '@utils/storage'
import bus from '@utils/bus'
import helpers from '@mixins/helper'
import {mapGetters} from 'vuex'
import moment from 'moment'

export default {
  components: {StageCard, LoadingContent},
  mixins: [helpers],
  data() {
    return {
      firstLoad: true,
      isLoadingData: true,
      currentDatetime: '',
      stages: [],
      filters: {
        registration_opened: null,
        year: null,
        country_id: null,
        state_id: null,
        city_id: null,
        type: null,
      },
      filterCount: 0
    }
  },
  computed: {
    ...mapGetters(['isLogged', 'city']),
    stagesList() {
      return this.stages
          .filter(item => {
            if (this.filters.registration_opened && this.filters.registration_opened === 'opened' && !item.registration_opened) {
              return false
            }
            if (this.filters.year && moment(item.first_date, 'YYYY-MM-DD').format('YYYY') !== this.filters.year) {
              return false
            }
            if (this.filters.type && item.type !== this.filters.type) {
              return false
            }
            if (this.filters.country_id && item.country_id !== this.filters.country_id) {
              return false
            }
            if (this.filters.state_id && item.state_id !== this.filters.state_id) {
              return false
            }
            if (this.filters.city_id && item.city_id !== this.filters.city_id) {
              return false
            }
            return true
          })
    },
    filtersSelectedCount() {
      let counter = 0
      if (this.filters.registration_opened) {
        counter++
      }
      if (this.filters.year && this.years.some(item => item === this.filters.year)) {
        counter++
      }
      if (this.filters.type && this.stageTypes.some(item => item.id === this.filters.type)) {
        counter++
      }
      if (this.filters.country_id && this.countries.some(item => item.id === this.filters.country_id)) {
        counter++
      }
      if (this.filters.state_id && this.states.some(item => item.id === this.filters.state_id)) {
        counter++
      }
      if (this.filters.city_id && this.cities.some(item => item.id === this.filters.city_id)) {
        counter++
      }
      return counter
    },
    stagesListActive() {
      return this.stagesList.filter(item => item.status === 'active' && !item.is_before_date)
    },
    stagesListFinished() {
      return this.stagesList.filter(item => item.status === 'finished' || item.is_before_date)
    },
    stageTypes() {
      return [
        {
          id: 'padel',
          name: 'Padel',
          count: this.getCountByFilter('type', 'padel')
        },
        {
          id: 'tennis',
          name: 'Tênis',
          count: this.getCountByFilter('type', 'tennis')
        },
        {
          id: 'beach_tennis',
          name: 'Beach Tennis',
          count: this.getCountByFilter('type', 'beach_tennis')
        },
        {
          id: 'other',
          name: 'Outros',
          count: this.getCountByFilter('type', 'other')
        }
      ].filter(item => item.count > 0)
    },
    years() {
      if (this.stagesListActive.length) {
        const years = []
        this.stagesListActive.map(item => {
          const firstDate = moment(item.first_date, 'YYYY-MM-DD')
          if (!years.includes(firstDate.format('YYYY'))) {
            years.push(firstDate.format('YYYY'))
          }
        })
        return years.sort()
      }
      return []
    },
    countries() {
      if (this.stagesListActive.length) {
        return [...new Map(this.stagesListActive.map(item => [item['country_id'], item])).values()].map(item => {
          return {
            id: item.country_id,
            name: item.country + ' (' + this.getCountByFilter('country_id', item.country_id) + ')',
          }
        })
      }
      return []
    },
    states() {
      if (this.stagesListActive.length) {
        return [...new Map(this.stagesListActive.map(item => [item['state_id'], item])).values()]
            .filter(item => item.state)
            .map(item => {
              return {
                id: item.state_id,
                country_id: item.country_id,
                name: item.state + ' (' + this.getCountByFilter('state_id', item.state_id) + ')',
              }
            }).filter(item => {
              if (this.filters.country_id && item.country_id !== this.filters.country_id) {
                return false
              }
              return true
            })
      }
      return []
    },
    cities() {
      if (this.stagesListActive.length) {
        return [...new Map(this.stagesListActive.map(item => [item['city_id'], item])).values()].map(item => {
          return {
            id: item.city_id,
            country_id: item.country_id,
            state_id: item.state_id,
            name: item.city + ' (' + this.getCountByFilter('city_id', item.city_id) + ')',
          }
        }).filter(item => {
          if (this.filters.country_id && item.country_id !== this.filters.country_id) {
            return false
          }
          if (this.filters.state_id && item.state_id !== this.filters.state_id) {
            return false
          }
          return true
        })
      }
      return []
    }
  },
  watch: {
    filters: {
      deep: true,
      handler(value) {
        if (!this.firstLoad) {
          set('stage.filters', value)
        }
      }
    },
    'filters.country_id': function (value) {
      if (!value) {
        this.filters.state_id = ''
        this.filters.city_id = ''
      }
    },
    'filters.state_id': function (value) {
      if (!value) {
        this.filters.city_id = ''
      }
    },
  },
  async mounted() {
    const filters = await get('stage.filters')
    if (filters) {
      this.filters = {...this.filters, ...filters}
    }
    // await this.getCurrentDatetime()
    await this.getStagesAvailable()
    this.firstLoad = false
    const scrollPosition = parseInt(await get('current-scroll-position'))
    if (scrollPosition > 0) {
      setTimeout(() => {
        document.body.scrollTop = document.documentElement.scrollTop = scrollPosition
      }, 300)
    }
    this.isLoadingData = false
    bus.$emit('hide-loader')
  },
  methods: {
    getCurrentDatetime() {
      return new Promise((resolve, reject) => {
        commonService.currentDatetime()
            .then(async (response) => {
              this.currentDatetime = response
              resolve()
            })
            .catch(err => {
              console.log(err)
              reject(err)
            })
      })
    },
    getStagesAvailable() {
      return new Promise((resolve, reject) => {
        bus.$emit('show-loader', {message: this.$t('app.domains.home.components.searchingStagesMessage')})
        const data = {
          city_id: null
        }
        stageService.available(data)
            .then(async (response) => {
              this.stages = [...response]
              resolve()
            })
            .catch(err => {
              console.log(err)
              this.isLoadingData = false
              bus.$emit('hide-loader')
              reject(err)
            })
      })
    },
    getCountByFilter(filter, value) {
      return this.stagesListActive.filter(item => item[filter] === value).length
    },
    filterListFilters(item, queryText, itemText) {
      return itemText.toLowerCase().includes(queryText.toLowerCase())
    }
  }
}
</script>

<template>
  <div class="dashboard-page">
    <div class="page-content-card stages-content">
      <v-container fluid grid-list-xs class="pr-0 pl-0 pt-1">
        <h3 :class="['pb-2 text-center', (!$vuetify.breakpoint.mobile ? 'body-1' : 'body-2')]">
          {{ $t('app.domains.home.components.scheduleMessage') }}
        </h3>
        <v-layout align-center justify-center>
          <v-spacer/>
          <v-flex lg4 sm6 xs12>
            <v-btn color="success" block :to="{name: 'schedule.daily'}">
              <i class="far fa-calendar-alt"></i>
              <span class="ml-2">{{ $t('app.domains.home.components.scheduleBtn') }}</span>
            </v-btn>
          </v-flex>
          <v-spacer/>
        </v-layout>
      </v-container>
      <v-divider class="mt-2 mb-2"/>
      <template v-if="!isLoadingData && stages.length">
        <div class="stages-header-filters">
          <h3 class="subheading pt-1 pb-1 text-center">
            {{ $t('app.domains.home.components.availableStagesText') }}
          </h3>
          <div class="filter-dropdown">
            <v-menu offset-y :close-on-content-click="false" min-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" small>
                  <i class="fas fa-filter"></i>
                  <span class="ml-1" v-if="!$vuetify.breakpoint.xs">
                    {{ $t('app.domains.home.components.filterText') }}
                  </span>
                  <span class="filters-counter" v-if="filtersSelectedCount">{{ filtersSelectedCount }}</span>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-checkbox :label="$t('app.domains.home.components.registrationOpenedText')"
                              value="opened"
                              hide-details
                              class="mt-0 mb-3"
                              v-model="filters.registration_opened"/>
                  <v-select :items="years"
                            v-model="filters.year"
                            :label="$t('app.domains.home.components.filters.yearLabel')"
                            :placeholder="$t('app.domains.home.components.filters.yearLabel')"
                            :no-data-text="$t('app.texts.listEmptyMessage')"
                            clearable
                            hide-details
                            persistent-placeholder
                            :menu-props="{maxHeight: '280px'}">
                    <template slot="item" slot-scope="data">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                  <v-select :items="stageTypes"
                            v-model="filters.type"
                            :label="$t('app.domains.home.components.filters.typeLabel')"
                            :placeholder="$t('app.domains.home.components.filters.typeLabel')"
                            :no-data-text="$t('app.texts.listEmptyMessage')"
                            item-text="name"
                            item-value="id"
                            clearable
                            hide-details
                            persistent-placeholder
                            class="mt-3"
                            :menu-props="{maxHeight: '280px'}">
                    <template slot="item" slot-scope="data">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.name }} ({{ data.item.count }})
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.name }} ({{ item.count }})
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                  <v-select :items="countries"
                            v-if="false"
                            v-model="filters.country_id"
                            :label="$t('app.domains.home.components.filters.countryLabel')"
                            :placeholder="$t('app.domains.home.components.filters.countryLabel')"
                            :no-data-text="$t('app.texts.listEmptyMessage')"
                            item-text="name"
                            item-value="id"
                            clearable
                            hide-details
                            persistent-placeholder
                            class="mt-3"
                            :menu-props="{maxHeight: '280px'}"/>
                  <v-autocomplete
                      :items="states"
                      v-model="filters.state_id"
                      :filter="filterListFilters"
                      item-value="id"
                      item-text="name"
                      :label="$t('app.domains.home.components.filters.stateLabel')"
                      :placeholder="$t('app.domains.home.components.filters.stateLabel')"
                      :no-data-text="$t('app.texts.listEmptyMessage')"
                      clearable
                      hide-details
                      persistent-placeholder
                      class="mt-3"
                      :menu-props="{maxHeight: '280px'}"
                  ></v-autocomplete>
                  <v-autocomplete
                      :items="cities"
                      v-model="filters.city_id"
                      :filter="filterListFilters"
                      item-value="id"
                      item-text="name"
                      :label="$t('app.domains.home.components.filters.cityLabel')"
                      :placeholder="$t('app.domains.home.components.filters.cityLabel')"
                      :no-data-text="$t('app.texts.listEmptyMessage')"
                      clearable
                      hide-details
                      persistent-placeholder
                      class="mt-3"
                      :menu-props="{maxHeight: '280px'}"
                  ></v-autocomplete>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
        </div>
        <template v-if="stagesListActive.length">
          <v-container grid-list-md
                       class="pt-0 pr-0 pb-0 pl-0">
            <v-layout wrap justify-center align-stretch>
              <v-flex lg4 sm6 xs12
                      class="mb-1"
                      v-for="(stage, stageIndex) in stagesListActive"
                      :key="stageIndex">
                <stage-card :stage="stage" :current-datetime="currentDatetime"/>
              </v-flex>
            </v-layout>
          </v-container>
        </template>
        <template v-else>
          <h3 class="headline text-center">
            {{ $t('app.domains.home.components.availableStagesWithFiltersNotFoundMessage') }}
          </h3>
        </template>
        <template v-if="stagesListFinished.length">
          <div style="margin-top: 40px;">
            <div>
              <h3 class="subheading pt-1 text-center mb-0">
                {{ $t('app.domains.home.components.finishedStagesText') }}
              </h3>
              <h4 class="headline text-center sub-header">
                {{ $t('app.domains.home.components.finishedStagesSubText') }}
              </h4>
            </div>
            <v-container grid-list-md
                         class="pt-0 pr-0 pb-0 pl-0">
              <v-layout wrap justify-center align-stretch>
                <v-flex lg4 sm6 xs12
                        class="mb-1"
                        v-for="(stage, stageIndex) in stagesListFinished"
                        :key="stageIndex">
                  <stage-card :stage="stage" odd/>
                </v-flex>
              </v-layout>
            </v-container>
          </div>
        </template>
      </template>
      <template v-else-if="isLoadingData">
        <v-container grid-list-md
                     class="pt-0 pr-0 pb-0 pl-0">
          <v-layout wrap justify-center align-stretch>
            <v-flex lg4 sm6 xs12
                    v-for="item in 3"
                    :key="item"
                    class="mb-1">
              <loading-content :placeholders="1"/>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
      <template v-else>
        <v-container fluid grid-list-lg>
          <h3 class="headline text-center">
            {{ $t('app.domains.home.components.availableStagesNotFoundMessage') }}
          </h3>
          <template v-if="!isLogged">
            <h3 class="subheading pt-2 pb-5 text-center">
              {{ $t('app.domains.home.components.availableStagesNotFoundDoRegisterMessage') }}
            </h3>
            <div class="text-center">
              <v-btn class="mr-2" color="success" :to="{name: 'user.register'}">
                {{ $t('app.domains.home.components.doRegisterBtn') }}
              </v-btn>
              <v-btn :to="{name: 'auth.login'}">
                {{ $t('app.domains.home.components.hasAccountBtn') }}
              </v-btn>
            </div>
          </template>
        </v-container>
      </template>
      <v-container fluid grid-list-lg class="mt-5">
        <v-layout wrap justify-center align-stretch>
          <v-flex sm6 xs12>
            <v-card color="blue-grey lighten-5">
              <v-card-text class="pt-5 pr-5 pb-5 pl-5">
                <div class="home-contacts">
                  <h3 class="sub-heading text-center pb-3">
                    {{ $t('app.domains.home.components.clubCardTitle') }}
                  </h3>
                  <h4 :class="['pb-2 text-center', (!$vuetify.breakpoint.mobile ? 'body-1' : 'body-2')]">
                    <span v-html="$t('app.domains.home.components.clubCardText')"/>
                  </h4>
                  <div class="pt-2">
                    <div class="home-contacts-item -site">
                      <i class="fas fa-laptop-house"></i>
                      <span class="ml-2">
                        <a href="https://www.gripo.com.br" target="_blank">www.gripo.com.br</a>
                      </span>
                    </div>
                    <div class="home-contacts-item -email">
                      <i class="fas fa-envelope"></i>
                      <span class="ml-2"><a
                          href="mailto:contato@gripo.com.br">contato@gripo.com.br</a></span>
                    </div>
                    <div class="home-contacts-item -instagram">
                      <i class="fab fa-instagram"></i>
                      <span class="ml-2"><a href="https://www.instagram.com/gripobr"
                                            target="_blank">@gripobr</a></span>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex sm6 xs12>
            <v-card color="blue-grey lighten-5">
              <v-card-text class="pt-5 pr-5 pb-5 pl-5">
                <div class="home-contacts">
                  <h3 class="sub-heading text-center pb-3">
                    {{ $t('app.domains.home.components.contactCardTitle') }}
                  </h3>
                  <h4 :class="['pb-2 text-center', (!$vuetify.breakpoint.mobile ? 'body-1' : 'body-2')]">
                    {{ $t('app.domains.home.components.contactCardText') }}
                  </h4>
                  <div class="pt-2">
                    <div class="home-contacts-item -email">
                      <i class="fas fa-envelope"></i>
                      <span class="ml-2"><a
                          href="mailto:contato@gripo.com.br">contato@gripo.com.br</a></span>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>