<script>
import UserApiService from '../../../services/domains/User'
import bus from '@utils/bus'
import { mapActions } from 'vuex';

const userService = UserApiService.build({})

export default {
  mounted() {
    if (this.$route.params.hash) {
      bus.$emit('show-loader', {message: this.$t('app.domains.user.components.activateAccount.checkActivation')})
      this.onActivateAccount(this.$route.params.hash)
    } else {
      bus.$emit('hide-loader')
    }
  },
  methods: {
    ...mapActions(['showNotification']),
    onActivateAccount(hash) {
      userService.activateAccount(hash)
          .then(async (response) => {
            this.showNotification({
              title: response.message,
              timeout: 4000
            })
            this.$router.push({name: 'auth.login', params: {hash: response.hash}})
          })
          .catch(() => {
            bus.$emit('hide-loader')
          })
    }
  }
}
</script>

<template>
  <v-container fill-height justify-center class="home-screens">
    <div>
      <v-layout wrap class="home-screens-box">
        <v-flex xs24>
          <div class="home-logo">
            <img src="/static/imgs/logo-light.png" alt="">
          </div>
          <v-btn text block small dark :to="{name: 'auth.login'}">{{ $t('app.texts.backLogin') }}</v-btn>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>
