<script>
import EmptyList from '../../../components/general/EmptyList'
import LoadingContent from '../../../components/general/placeholders/LoadingContent'
import TransactionPayment from '../../../components/general/TransactionPayment'
import ScheduleClock from './ScheduleClock'

import * as notifyService from '../../../services/common/notify'

import ScheduleApiService from '../../../services/domains/Schedule'
import CityApiService from '../../../services/domains/City'
import ClubApiService from '../../../services/domains/Club'

import bus from '@utils/bus'
import helpers from '@mixins/helper'
import {mapActions, mapGetters} from 'vuex'
import moment from 'moment'
import {cloneDeep, orderBy} from 'lodash'

const scheduleService = ScheduleApiService.build({})
const cityService = CityApiService.build({})
const clubService = ClubApiService.build({})

export default {
  components: {TransactionPayment, LoadingContent, EmptyList, ScheduleClock},
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      showScheduleModal: false,
      showModalDatePicker: false,
      showScheduleRedirectModal: false,
      showScheduleRedirectData: {
        schedule: {
          id: '',
          club_id: '',
          payment_enabled: '',
          gateway_payment_url: '',
          transaction: {},
          payment_minutes_to_cancel: 0
        }
      },
      selectedCourt: '',
      isLoadingCities: true,
      isLoadingClubs: false,
      filters: {
        date: this.dateFormat(this.momentNow(), 'en'),
        city_slug: '',
        club_slug: '',
        lesson: false,
        coach_id: '',
        sports_key: []
      },
      dependencies: {
        cities: [],
        clubs: []
      },
      list: [],
      coachesList: [],
      scheduleNotes: '',
      maxDateSchedule: null,
      scheduleSettings: {},
      currentSchedule: {
        id: null,
        coach_id: '',
        court: '',
        date: '',
        start_hour: '',
        end_hour: '',
        notes: '',
        sport: '',
        price: null,
        schedules_count: 1,
        sports: [],
      },
      currentTimeInterval: null,
      currentTime: '',
      selectedSchedules: [],
      clubLink: {
        enabled: false,
        city: {},
        club: {},
      },
    }
  },
  computed: {
    ...mapGetters(['isLogged', 'city', 'club', 'tenantId']),
    filtersDateFormatted() {
      return this.filters.date ? moment(this.filters.date).format('dddd, DD/MM/YYYY') : ''
    },
    websocketKey() {
      return `scheduleStatusChange`
    },
    websocketChannelName() {
      return this.club ? `${this.websocketKey}.${this.club.tenant_id}` : ''
    },
    websocketChannelListen() {
      return `.${this.websocketKey}`
    },
    enableLessonSchedule() {
      return this.club?.tenant_settings?.app_schedule?.enable_lesson_schedule ?? false
    },
    clubShowClock() {
      return this.club?.tenant_settings?.app_schedule?.opening_schedules?.show_clock ?? false
    },
    contactPhone() {
      return this.club?.tenant_settings?.app_schedule?.contact?.phone ?? ''
    },
    contactEmail() {
      return this.club?.tenant_settings?.app_schedule?.contact?.email ?? ''
    },
    sports() {
      return this.list.reduce((items, court) => [...new Set([...items, ...court.tags])], [])
    },
    listFiltered() {
      return cloneDeep(this.list).filter(item => {
        let available = true
        if (this.filters.sports_key.length) {
          available = item.tags.some(tag => this.filters.sports_key.includes(tag.id))
        }
        if (available && this.filters.coach_id) {
          const coach = this.coachesListFiltered.find(item => item.id === this.filters.coach_id)
          const courts = [...new Set((coach?.coach_config?.available_grid || []).map(item => parseInt(item.court_id)))]
          available = courts.includes(item.id)
          if (available) {
            const coachGrid = coach?.coach_config?.available_grid || []
            item.hours.map(hour => {
              hour.lesson_available = coachGrid.some(gridItem => gridItem.start_hour === hour.start_hour && gridItem.end_hour === hour.end_hour)
              return hour
            })
            if (item.hours.every(hour => !hour.lesson_available)) {
              available = false
            }
          }
        }
        return available
      })
    },
    coachesListFiltered() {
      return this.coachesList.filter(item => {
        if (this.filters.sports_key.length) {
          return (item?.coach_config?.sports || []).filter(sport => this.filters.sports_key.includes(sport))
        }
        return true
      })
    },
    canSelectCoach() {
      return this.sports.length === 0 || (this.sports.length > 0 && this.filters.sports_key.length > 0)
    },
    coachSelected() {
      return this.coachesListFiltered.find(item => item.id === this.filters.coach_id)
    },
    currentSchedulePrice() {
      if (this.coachSelected) {
        return this.coachSelected.coach_config?.price || 0
      }
      return this.currentSchedule.price
    }
  },
  watch: {
    'filters.date': function (value) {
      if (this.$route.params.hash) {
        this.$router.push({name: 'schedule.daily', params: {slug: this.$route.params.slug}})
      }
      this.coachesList.splice(0)
      if (value && this.filters.club_slug) {
        this.loadSchedules()
      }
    },
    '$route': function (to) {
      if (!this.showScheduleModal && !!to.params.hash) {
        this.showScheduleModal = true
        bus.$emit('hide-loader')
      }
      if (this.showScheduleModal && !to.params.hash) {
        this.showScheduleModal = false
        bus.$emit('hide-loader')
      }
      this.clubLink.enabled = !!to.params.slug
      if (!this.clubLink.enabled && !this.isLoadingCities && !this.currentSchedule.hash) {
        this.list.splice(0)
        this.coachesList.splice(0)
        this.filters.city_slug = ''
        this.filters.club_slug = ''
        this.getCities()
      }
    },
    'filters.lesson': function (value) {
      if (!value) {
        this.filters.coach_id = ''
      }
    }
  },
  async mounted() {
    this.startWebsocket()
    this.startCurrentTimeInterval()
    this.clubLink.enabled = false
    if (this.$route.params.slug !== undefined && this.$route.params.slug) {
      const [slug, restrictedHash] = this.$route.params.slug.split('--')
      const club = await this.getClubSlug(slug, restrictedHash || '')
      if (club) {
        await this.setCity(club.city)
        await this.setClub(club.club)
        this.clubLink.enabled = true
        this.isLoadingCities = false
      }
    }
    if (!this.clubLink.enabled) {
      await this.getCities()
    }
    if (this.city && this.city.slug && this.club && this.club.slug) {
      this.filters.city_slug = this.city.slug
      await this.selectCity(this.filters.city_slug)
      this.filters.club_slug = this.club.slug
      await this.loadSchedules()
    } else {
      this.isLoadingData = false
      bus.$emit('hide-loader')
    }
    if (this.$route.params.hash !== undefined && this.$route.params.hash) {
      const redirectRouteFilters = window?.gripo?.redirect_route_filters || null
      if (redirectRouteFilters) {
        window.gripo.redirect_route_filters = null
        this.filters = {...redirectRouteFilters?.filters || {}}
        if (this.filters.city_slug) {
          await this.selectCity(this.filters.city_slug)
        }
        if (this.filters.club_slug) {
          await this.selectClub(this.filters.club_slug)
        }
      }
      this.showScheduleHash(this.$route.params.hash)
    }
    document.addEventListener('visibilitychange', this.visibilityChange)
  },
  methods: {
    ...mapActions(['setPageSubTitle', 'showNotification', 'setCity', 'setClub']),
    startWebsocket() {
      window.Echo.channel(this.websocketChannelName)
          .listen(this.websocketChannelListen, () => {
            this.loadSchedules()
          })
    },
    async startCurrentTimeInterval() {
      if (this.currentTimeInterval) {
        clearInterval(this.currentTimeInterval)
      }
      await this.getTime()
      this.currentTimeInterval = setInterval(() => {
        this.updateTime()
      }, 1000)
    },
    async visibilityChange() {
      if (!document.hidden) {
        this.startCurrentTimeInterval()
      }
    },
    filterListFilters(item, queryText, itemText) {
      if (itemText && queryText) {
        return itemText.toLowerCase().includes(queryText.toLowerCase())
      }
      return true
    },
    getCities() {
      return new Promise(resolve => {
        this.isLoadingCities = true
        cityService.getAvailable().then(response => {
          this.dependencies.cities = [...response]
          this.dependencies.clubs.splice(0)
          this.isLoadingCities = false
          resolve()
        })
      })
    },
    getClubs(cityId) {
      return new Promise(resolve => {
        this.isLoadingClubs = true
        const data = {city_id: cityId}
        clubService.getAvailable(data).then(response => {
          this.dependencies.clubs = [...response]
          this.isLoadingClubs = false
          resolve()
        })
      })
    },
    async selectCity(value) {
      return new Promise(async (resolve) => {
        this.dependencies.clubs.splice(0)
        this.coachesList.splice(0)
        if (value && !this.clubLink.enabled) {
          const city = this.dependencies.cities.find(item => item.slug === value)
          await this.setCity(city)
          await this.getClubs(city.id)
        }
        resolve()
      })
    },
    selectClub(value) {
      return new Promise(async (resolve) => {
        this.coachesList.splice(0)
        if (value && !this.clubLink.enabled) {
          this.list.splice(0)
          const club = this.dependencies.clubs.find(item => item.slug === value)
          await this.setClub(club)
          await this.loadSchedules()
        }
        resolve()
      })
    },
    prepareSearchData() {
      const search = {}
      if (this.filters.date) {
        search.date = moment(this.filters.date).format('YYYY-MM-DD')
      }
      search.city_id = this.city?.id || null
      search.club_id = this.club?.id || null
      return search
    },
    getClubSlug(slug, hash) {
      return new Promise((resolve, reject) => {
        clubService.slug(slug, hash)
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              console.log(err)
              reject(err)
            })
      })
    },
    showScheduleHash(hash) {
      let schedule = null
      this.list.map(court => {
        let scheduleFound = court.hours.find((item => item.hash === hash))
        if (scheduleFound) {
          schedule = {...scheduleFound}
        }
      })
      if (schedule) {
        this.currentSchedule = {...schedule}
        this.currentSchedule.court_id = schedule.court_id
        if (this.currentSchedule.sports.length) {
          this.currentSchedule.sport = this.currentSchedule.sports[0].key
        }
        this.showScheduleModal = !this.showScheduleModal
      }
    },
    loadSchedules() {
      return new Promise((resolve, reject) => {
        bus.$emit('show-loader')
        const data = this.prepareSearchData()
        this.isLoadingData = true
        this.list.splice(0)
        scheduleService.available(data)
            .then(response => {
              this.list = [...response.courts]
              this.coachesList = [...response.coaches]
              this.scheduleNotes = response.schedule_notes
              this.maxDateSchedule = response.limit_date_schedule
              this.scheduleSettings = response.settings
              this.isLoadingData = false
              bus.$emit('hide-loader')
              resolve()
            })
            .catch(err => {
              console.log(err)
              this.isLoadingData = false
              bus.$emit('hide-loader')
              reject(err)
            })
      })
    },
    getTime() {
      return new Promise(resolve => {
        scheduleService.currentTime()
            .then(response => {
              this.currentTime = response
              resolve()
            })
            .catch(err => {
              console.log(err)
            })
      })
    },
    updateTime() {
      this.currentTime = moment(this.currentTime, 'HH:mm:ss').add(1, 'seconds').format('HH:mm:ss')
    },
    hasMembersSchedule(hours) {
      return hours.some(item => item.members_schedule)
    },
    async handleScheduleModal(schedule, courtId) {
      if (this.filters.lesson && !this.coachSelected) {
        this.showNotification({
          type: 'warning',
          title: this.$t('app.domains.schedule.components.schedule.selectedCoachText')
        })
        return
      }
      if (!this.isLogged) {
        if (!window.hasOwnProperty('gripo')) {
          window.gripo = {}
        }
        window.gripo.redirect_route = {
          name: this.$route.name,
          params: {slug: this.$route.params.slug, hash: schedule.hash}
        }
        window.gripo.redirect_route_filters = {
          filters: this.filters
        }
        this.$router.push({name: 'auth.login'})
      } else {
        if (!this.showScheduleModal) {
          if (schedule) {
            this.currentSchedule = {...schedule}
            const {amount} = await this.getAmount()
            this.currentSchedule.price = amount
            this.currentSchedule.court_id = courtId
            if (this.currentSchedule.sports.length) {
              this.currentSchedule.sport = this.currentSchedule.sports[0].key
            }
            this.$router.push({name: 'schedule.daily', params: {slug: this.$route.params.slug, hash: schedule.hash}})
          }
        } else {
          if (this.$route.name === 'schedule.daily' && !this.$route.params.hash) {
            this.showScheduleModal = false
          } else {
            this.$router.push({name: 'schedule.daily', params: {slug: this.$route.params.slug}})
          }
        }
      }
    },
    confirmSchedule() {
      bus.$emit('show-loader')
      const [, restrictedHash] = this.$route.params.slug?.split('--') || [null, null]
      const data = {
        club_id: this.club.id,
        restricted_hash: restrictedHash || null,
        court_id: this.currentSchedule.court_id,
        coach_id: this.filters.lesson && this.coachSelected ? this.coachSelected.id : null,
        date: this.dateFormat(this.currentSchedule.date, 'en'),
        start_hour: this.currentSchedule.start_hour,
        start_datetime: this.dateFormat(this.currentSchedule.date, 'en') + ' ' + this.currentSchedule.start_hour,
        end_hour: this.currentSchedule.end_hour,
        end_datetime: this.dateFormat(this.currentSchedule.date, 'en') + ' ' + this.currentSchedule.end_hour,
        notes: this.currentSchedule.notes,
        sport: this.currentSchedule.sport,
      }
      scheduleService.confirm({data})
          .then(response => {
            this.clearSelection()
            this.loadSchedules()
            this.showNotification({
              title: response.title,
              message: response.message
            })
            this.handleScheduleModal()
            this.showScheduleRedirectData = {...response}
            this.showScheduleRedirectModal = true
          })
          .catch(err => {
            console.log(err)
            bus.$emit('hide-loader')
          })
    },
    getAmount() {
      return new Promise((resolve, reject) => {
        const data = {
          club_id: this.club.id,
          court_id: this.currentSchedule.court_id,
          coach_id: this.filters.lesson && this.coachSelected ? this.coachSelected.id : null,
          date: this.dateFormat(this.currentSchedule.date, 'en'),
          start_hour: this.currentSchedule.start_hour,
          start_datetime: this.dateFormat(this.currentSchedule.date, 'en') + ' ' + this.currentSchedule.start_hour,
          end_hour: this.currentSchedule.end_hour,
          end_datetime: this.dateFormat(this.currentSchedule.date, 'en') + ' ' + this.currentSchedule.end_hour,
          notes: this.currentSchedule.notes,
          sport: this.currentSchedule.sport,
        }
        scheduleService.getAmount({data})
            .then(response => {
              resolve(response)
            })
            .catch(err => {
              console.log(err)
              reject(err)
            })
      })
    },
    async handleScheduleRedirect(redirect) {
      redirect = redirect || false
      if (!redirect) {
        this.showScheduleRedirectModal = !this.showScheduleRedirectModal
      } else {
        this.$router.push({
          name: 'schedule.show',
          params: {
            id: this.showScheduleRedirectData.schedule.id,
            club_id: this.showScheduleRedirectData.schedule.club_id,
            schedule: this.showScheduleRedirectData.schedule
          }
        })
      }
    },
    async clearSelection() {
      this.selectedSchedules.splice(0)
    },
    async selectSchedule(scheduleHour) {
      if (this.selectedSchedules.length) {
        if (scheduleHour.court_id !== this.selectedSchedules[0].court_id) {
          const index = this.selectedSchedules.findIndex(item => item.start_hour === scheduleHour.start_hour && item.court_id === scheduleHour.court_id)
          this.selectedSchedules.splice(index, 1)
          notifyService.warning({
            title: this.$t('app.texts.warning'),
            message: this.$t('app.domains.schedule.components.schedule.multipleSelectionErrorMessage')
          })
          return
        }
      }
      if (this.selectedSchedules.length) {
        const selectedSchedules = orderBy(this.selectedSchedules, ['start_hour'])
        const firstSchedule = selectedSchedules[0]
        const lastSchedule = selectedSchedules[selectedSchedules.length - 1]
        let currentHour = moment(`${firstSchedule.date} ${firstSchedule.start_hour}`, 'YYYY-MM-DD HH:mm:ss')
        const lastHour = moment(`${firstSchedule.date} ${lastSchedule.end_hour}`, 'YYYY-MM-DD HH:mm:ss')
        let intervalCounter = 0
        while (currentHour.isBefore(lastHour)) {
          intervalCounter++
          currentHour.add(firstSchedule.interval, 'minutes')
        }
        if (intervalCounter > this.selectedSchedules.length) {
          const index = this.selectedSchedules.findIndex(item => item.start_hour === scheduleHour.start_hour)
          this.selectedSchedules.splice(index, 1)
          notifyService.warning({
            title: this.$t('app.texts.warning'),
            message: this.$t('app.domains.schedule.components.schedule.multipleSelectionSequenceErrorMessage')
          })
        }
        this.currentSchedule.schedules_count = intervalCounter
      }
    },
    async confirmMultipleSchedule() {
      if (!this.isLogged) {
        if (!window.hasOwnProperty('gripo')) {
          window.gripo = {}
        }
        window.gripo.redirect_route = {
          name: this.$route.name,
          params: {slug: this.$route.params.slug}
        }
        window.gripo.redirect_route_filters = {
          filters: this.filters
        }
        this.$router.push({name: 'auth.login'})
      } else {
        if (!this.showScheduleModal) {
          const selectedSchedules = orderBy(this.selectedSchedules, ['start_hour'], ['asc'])
          let price = 0
          selectedSchedules.map(item => {
            price += item.price
          })
          this.currentSchedule = {...selectedSchedules[0]}
          this.currentSchedule.end_hour = selectedSchedules[selectedSchedules.length - 1].end_hour
          this.currentSchedule.price = price
          if (this.currentSchedule.sports.length) {
            this.currentSchedule.sport = this.currentSchedule.sports[0].key
          }
          const {amount} = await this.getAmount()
          this.currentSchedule.price = amount
          this.showScheduleModal = true
        } else {
          this.$router.push({name: 'schedule.daily', params: {slug: this.$route.params.slug}})
        }
      }
    },
    scheduleItemPrice(item) {
      if (this.coachSelected) {
        return this.coachSelected.coach_config?.price || 0
      }
      return item.price
    },
    scheduleUnavailable(scheduleHour) {
      if (this.filters.lesson && !scheduleHour.available_to_lesson) {
        return true
      } else if (!this.filters.lesson && !scheduleHour.available_to_schedule) {
        return true
      } else {
        return this.filters.coach_id && !scheduleHour.lesson_available
      }
    },
    scheduleUnavailableText(scheduleHour) {
      if (this.filters.lesson && !scheduleHour.available_to_lesson) {
        return this.$t('app.domains.schedule.components.schedule.list.items.lessonUnavailableText')
      } else if (!this.filters.lesson && !scheduleHour.available_to_schedule) {
        return this.$t('app.domains.schedule.components.schedule.list.items.scheduleUnavailableText')
      } else {
        return this.$t('app.domains.schedule.components.schedule.list.items.lessonCoachUnavailableText')
      }
    }
  },
  destroyed() {
    window.Echo.leaveChannel(this.websocketChannelName)
    clearInterval(this.currentTimeInterval)
    document.removeEventListener('visibilitychange', this.visibilityChange)
  }
}
</script>

<template>
  <div class="schedule-page">
    <div v-if="!$vuetify.breakpoint.xs" class="page-header flex-header list-header">
      <h2 class="headline page-title">{{ $t('app.domains.schedule.components.schedule.pageTitle') }}</h2>
      <div>
        <schedule-clock v-if="clubShowClock && currentTime" :time="currentTime"/>
      </div>
    </div>
    <v-card class="page-content-card">
      <v-container fluid grid-list-md class="pt-0">
        <v-layout row wrap align-center>
          <template v-if="clubLink.enabled">
            <v-flex md7 xs12 align-self-center class="text-center" v-if="club">
              <v-chip :value="true"
                      class="mb-1 fs-18 pl-5 pr-5 pt-5 pb-5 ml-1 mr-1"
                      color="secondary">
                {{ club.name }} | {{ city?.name_with_state }}
              </v-chip>
              <div>
                <v-chip :value="contactPhone"
                        v-if="contactPhone"
                        outlined
                        class="mb-1 ml-1 mr-1"
                        color="grey darken-1">
                  <v-avatar left>
                    <i class="fas fa-phone"></i>
                  </v-avatar>
                  <strong>{{ contactPhone }}</strong>
                </v-chip>
                <v-chip :value="contactEmail"
                        v-if="contactEmail"
                        outlined
                        class="mb-1 ml-1 mr-1"
                        color="grey darken-1">
                  <v-avatar left>
                    <i class="fas fa-envelope"></i>
                  </v-avatar>
                  <strong>{{ contactEmail }}</strong>
                </v-chip>
              </div>
            </v-flex>
          </template>

          <template v-if="!clubLink.enabled">
            <v-flex md3 xs12>
              <v-autocomplete
                  :items="dependencies.cities"
                  v-model="filters.city_slug"
                  :filter="filterListFilters"
                  :loading="isLoadingCities"
                  item-value="slug"
                  item-text="name_with_state"
                  :label="$t('components.main.sidebar.selectCity.label')"
                  :placeholder="$t('app.texts.selectSinglePlaceholder')"
                  :no-data-text="$t('components.main.sidebar.selectCity.emptyList')"
                  clearable
                  hide-details
                  :menu-props="{maxHeight: '500px'}"
                  @change="selectCity"/>
            </v-flex>
            <v-flex md3 xs12>
              <v-autocomplete
                  :items="dependencies.clubs"
                  v-model="filters.club_slug"
                  :filter="filterListFilters"
                  :loading="isLoadingCities || isLoadingClubs"
                  item-value="slug"
                  item-text="name"
                  :label="$t('components.main.sidebar.selectClub.label')"
                  :placeholder="$t('app.texts.selectSinglePlaceholder')"
                  :no-data-text="$t('components.main.sidebar.selectClub.emptyList')"
                  clearable
                  hide-details
                  :menu-props="{maxHeight: '500px'}"
                  @change="selectClub"/>
            </v-flex>
          </template>
          <v-flex md6 xs7>
            <v-menu ref="modalDatePicker"
                    :close-on-content-click="false"
                    v-model="showModalDatePicker"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field :label="$t('app.domains.schedule.components.schedule.currentDateLabel')"
                              readonly
                              v-model="filtersDateFormatted"
                              hide-details
                              v-on="on">
                  <template v-slot:prepend>
                    <i class="fas fa-calendar-alt"></i>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="filters.date"
                             @input="showModalDatePicker = false"
                             @change="loadSchedules"
                             :max="maxDateSchedule"
                             locale="pt-br"/>
            </v-menu>
          </v-flex>
          <v-flex xs5 v-if="!enableLessonSchedule && clubShowClock && $vuetify.breakpoint.xs && currentTime"
                  class="text-center">
            <schedule-clock :time="currentTime"/>
          </v-flex>
          <v-flex md3 xs5 v-if="enableLessonSchedule">
            <v-switch v-model="filters.lesson"
                      hide-details
                      inset
                      :label="$t('components.main.sidebar.selectType.label')"/>
          </v-flex>
          <v-flex md4 xs12 v-if="sports.length > 0">
            <v-select :items="sports"
                      v-model="filters.sports_key"
                      :label="$t('components.main.sidebar.selectSport.label')"
                      :placeholder="$t('app.texts.selectSinglePlaceholder')"
                      :no-data-text="$t('components.main.sidebar.selectSport.emptyList')"
                      item-text="name"
                      item-value="id"
                      clearable
                      multiple
                      hide-details
                      :menu-props="{maxHeight: '500px'}"/>
          </v-flex>
          <v-flex md5 xs12 v-if="enableLessonSchedule && filters.lesson">
            <v-autocomplete
                :readonly="!canSelectCoach"
                :items="coachesListFiltered"
                v-model="filters.coach_id"
                :filter="filterListFilters"
                item-value="id"
                item-text="name"
                :label="$t('components.main.sidebar.selectCoach.label')"
                :placeholder="$t('app.texts.selectSinglePlaceholder')"
                :no-data-text="$t('components.main.sidebar.selectCoach.emptyList')"
                clearable
                hide-details
                :menu-props="{maxHeight: '500px'}">
              <template slot="item" slot-scope="data">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{ item }">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex xs12 v-if="enableLessonSchedule && clubShowClock && $vuetify.breakpoint.xs && currentTime"
                  class="text-center">
            <schedule-clock :time="currentTime"/>
          </v-flex>
        </v-layout>
      </v-container>
      <v-divider></v-divider>
      <template v-if="!isLoadingData && listFiltered.length > 0">
        <v-tabs background-color="grey lighten-3"
                v-model="selectedCourt"
                slider-color="primary"
                centered
                show-arrows
                class="schedule-court-tabs">
          <v-tab v-for="court in listFiltered"
                 :disabled="selectedSchedules.length > 0"
                 :key="court.id">
            <div v-if="court.bg_color"
                 class="court-color-circle mr-2"
                 :style="`background-color: ${court.bg_color}`"></div>
            <span class="schedule-court-name">{{ court.name }}</span>
          </v-tab>
          <v-tabs-items v-model="selectedCourt">
            <v-tab-item v-for="court in listFiltered"
                        :key="court.id"
                        :disabled="selectedSchedules.length > 0"
                        :class="{'pb-5': selectedSchedules.length > 0}">
              <div class="multiple-schedule-box text-center" v-if="selectedSchedules.length > 0">
                <v-btn ripple
                       :class="['primary', {'mb-2': $vuetify.breakpoint.xs}, {'mr-2': !$vuetify.breakpoint.xs}]"
                       @click="confirmMultipleSchedule">
                  <i class="fas fa-check"></i>
                  <span class="ml-1">
                    {{ $t('app.domains.schedule.components.schedule.multipleScheduleBtn') }}
                  </span>
                </v-btn>
                <v-btn ripple @click="clearSelection()">
                  <i class="fas fa-trash"></i>
                  <span class="ml-1">
                    {{ $t('app.domains.schedule.components.schedule.multipleScheduleClearBtn') }}
                  </span>
                </v-btn>
              </div>
              <v-list subheader class="hours-list">
                <div v-if="court.type || court.place || court.tags.length > 0"
                     class="pt-2 pb-2 text-center grey lighten-4">
                  <div class="flex-c -jc-sa">
                    <div v-if="court.type || court.place">
                      <h5 class="secondary-text">Tipo de Quadra</h5>
                      <v-chip :value="true"
                              color="secondary"
                              class="ml-1 mr-1"
                              small
                              outlined>
                        <i class="fas fa-border-style"></i>
                        <span class="ml-2">{{ court.type }}</span>
                      </v-chip>
                      <v-chip :value="true"
                              color="secondary"
                              class="ml-1 mr-1"
                              small
                              outlined>
                        <i class="fas fa-landmark"></i>
                        <span class="ml-2">{{ court.place }}</span>
                      </v-chip>
                    </div>
                    <div v-if="court.tags.length > 0">
                      <h5 class="secondary-text">Esportes</h5>
                      <v-chip :value="true"
                              v-for="(tag, index) in court.tags"
                              :key="index"
                              color="primary"
                              class="ml-1 mr-1"
                              small
                              outlined>
                        <span>{{ tag.name || tag }}</span>
                      </v-chip>
                    </div>
                  </div>
                </div>
                <template v-for="(scheduleHour, index) in court.hours">
                  <v-list-item ripple
                               :key="scheduleHour.start_hour"
                               :class="{'lesson_unavailable': scheduleUnavailable(scheduleHour)}">
                    <div class="lesson-unavailable-text" v-if="scheduleUnavailable(scheduleHour)">
                      <span>{{ scheduleUnavailableText(scheduleHour) }}</span>
                    </div>
                    <v-list-item-content>
                      <v-list-item-title>
                        <template v-if="scheduleSettings.multiple_scheduled && scheduleHour.available">
                          <v-checkbox class="mt-0"
                                      multiple
                                      hide-details
                                      @change="selectSchedule(scheduleHour)"
                                      v-model="selectedSchedules"
                                      :value="scheduleHour">
                            <template slot="label">
                              {{ scheduleHour.start_hour | hourShort }}h
                              <span class="caption ml-1 mr-1">até</span>
                              {{ scheduleHour.end_hour | hourShort }}h
                            </template>
                          </v-checkbox>
                        </template>
                        <template v-else>
                          {{ scheduleHour.start_hour | hourShort }}h
                          <span class="caption">até</span>
                          {{ scheduleHour.end_hour | hourShort }}h
                          <v-tooltip bottom v-if="scheduleHour.members_schedule">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" class="warning--text ml-2"><i class="fas fa-user-clock"></i></span>
                            </template>
                            <span>
                              {{ $t('app.domains.schedule.components.schedule.list.items.membersScheduleText') }}
                            </span>
                          </v-tooltip>
                        </template>
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="scheduleHour.available && scheduleHour.price">
                        <div class="mt-1">
                          <div class="white-space-normal" v-if="filters.lesson && !coachSelected">
                            {{ $t('app.domains.schedule.components.schedule.list.items.selectedCoachText') }}
                          </div>
                          <span v-else>
                            <span v-if="coachSelected">{{ coachSelected?.name }} | </span>
                            {{ $t('app.domains.schedule.components.schedule.list.items.courtPriceText') }}
                            {{ scheduleItemPrice(scheduleHour) | currencyEnToBr }}
                          </span>
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div class="text-center" v-if="scheduleHour.available">
                        <v-btn small ripple class="primary"
                               @click="handleScheduleModal(scheduleHour, court.id)"
                               v-if="selectedSchedules.length === 0">
                          <i class="fas fa-check"></i>
                          <span class="ml-1">
                            {{
                              filters.lesson
                                  ? $t('app.domains.schedule.components.schedule.list.items.scheduleLessonBtn')
                                  : $t('app.domains.schedule.components.schedule.list.items.scheduleBtn')
                            }}
                          </span>
                        </v-btn>
                        <div class="mt-2" v-if="scheduleHour.payment_enabled">
                          <v-chip :value="true"
                                  color="blue"
                                  small
                                  outlined>
                            <i class="fas fa-dollar-sign"></i>
                            <span class="ml-2">{{
                                $t('app.domains.schedule.components.schedule.list.items.onlinePaymentText')
                              }}</span>
                          </v-chip>
                        </div>
                      </div>
                      <template v-else>
                        <v-list-item-action-text>
                          {{ $t('app.domains.schedule.components.schedule.list.items.scheduledText') }}
                        </v-list-item-action-text>
                        <div v-if="scheduleHour.client">
                          {{ scheduleHour.client.name }}
                        </div>
                      </template>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="index * 50 + 1"/>
                </template>
              </v-list>
              <template v-if="list.length > 0 && hasMembersSchedule(court.hours)">
                <empty-list v-if="!isLoadingData && listFiltered.length === 0 && filters.city_slug && filters.club_slug"
                            :title="$t('app.domains.schedule.components.schedule.emptyListTitle')"
                            :message="$t('app.domains.schedule.components.schedule.emptyListMessage')"/>
                <div class="warning--text pl-4 pb-2 pt-4">
                  <i class="fas fa-user-clock"></i>
                  <span class="ml-2">{{
                      $t('app.domains.schedule.components.schedule.list.items.membersScheduleText')
                    }}</span>
                </div>
                <div class="pl-4 pb-4">
                  {{ $t('app.domains.schedule.components.schedule.membersScheduleText') }}
                </div>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </template>
      <empty-list v-if="!isLoadingData && listFiltered.length === 0 && filters.city_slug && filters.club_slug"
                  :title="$t('app.domains.schedule.components.schedule.emptyListTitle')"
                  :message="$t('app.domains.schedule.components.schedule.emptyListMessage')"/>
      <empty-list v-else-if="!isLoadingData && listFiltered.length === 0 && (!filters.city_slug || !filters.club_slug)"
                  :message="$t('app.domains.schedule.components.schedule.emptyListMessageNoClub')"/>
      <template v-if="isLoadingData">
        <loading-content :placeholders="3"/>
      </template>
    </v-card>
    <v-dialog v-model="showScheduleModal"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="secondary">
          <v-toolbar-title>{{ $t('app.domains.schedule.components.schedule.dialog.title') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="handleScheduleModal()">
            <i class="fas fa-times"></i>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-alert color="yellow lighten-3"
                   :value="true"
                   icon="fa-exclamation-triangle"
                   v-if="scheduleNotes">
            <div class="black--text fs-16 schedule-notes" v-html="scheduleNotes"></div>
          </v-alert>
          <v-list subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><span
                    class="grey--text darken-1">{{
                    $t('app.domains.schedule.components.schedule.dialog.form.clubText')
                  }}</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="club">
                <span class="subheading font-weight-medium">{{ club.name }}</span>
              </v-list-item-action>
            </v-list-item>
            <v-divider/>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><span
                    class="grey--text darken-1">{{
                    $t('app.domains.schedule.components.schedule.dialog.form.courtText')
                  }}</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <span class="subheading font-weight-medium">{{ currentSchedule.court }}</span>
              </v-list-item-action>
            </v-list-item>
            <v-divider/>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><span
                    class="grey--text darken-1">{{
                    $t('app.domains.schedule.components.schedule.dialog.form.dateText')
                  }}</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <span class="subheading font-weight-medium">{{ currentSchedule.date }}</span>
              </v-list-item-action>
            </v-list-item>
            <v-divider/>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><span
                    class="grey--text darken-1">{{
                    $t('app.domains.schedule.components.schedule.dialog.form.hourText')
                  }}</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <span class="subheading font-weight-medium">
                  {{ currentSchedule.start_hour | hourShort }}h
                  <span class="caption">
                    {{ $t('app.domains.schedule.components.schedule.dialog.form.hourBetweenText') }}
                  </span>
                  {{ currentSchedule.end_hour | hourShort }}h
                </span>
              </v-list-item-action>
            </v-list-item>
            <template v-if="coachSelected">
              <v-divider/>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="grey--text darken-1">
                      {{ $t('app.domains.schedule.components.schedule.dialog.form.coachText') }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <span class="subheading font-weight-medium">{{ coachSelected ? coachSelected.name : '' }}</span>
                </v-list-item-action>
              </v-list-item>
            </template>
            <template v-if="currentSchedule.price">
              <v-divider/>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title><span
                      class="grey--text darken-1">{{
                      $t('app.domains.schedule.components.schedule.dialog.form.courtPriceText')
                    }}</span>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <span class="subheading font-weight-medium">{{ currentSchedulePrice | currencyEnToBr }}</span>
                </v-list-item-action>
              </v-list-item>
            </template>
            <template v-if="currentSchedule.sports.length > 1">
              <v-divider/>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="grey--text darken-1">
                    {{ $t('app.domains.schedule.components.schedule.dialog.form.sportText') }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <div class="subheading font-weight-medium">
                    <v-radio-group v-model="currentSchedule.sport" :row="!$vuetify.breakpoint.xs">
                      <v-radio
                          v-for="sport in currentSchedule.sports"
                          :label="sport.label"
                          :value="sport.key"
                          :key="sport.key"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </template>
            <v-divider/>
            <div class="pl-3 pr-3">
              <v-textarea v-model="currentSchedule.notes"
                          :label="$t('app.domains.schedule.components.schedule.dialog.form.notesLabel')"
                          :rows="4"></v-textarea>
            </div>
          </v-list>
          <div class="text-center">
            <v-btn color="primary" large @click="confirmSchedule()">
              <i class="fas fa-check"></i>
              <span class="ml-2">{{ $t('app.texts.confirm') }}</span>
            </v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showScheduleRedirectModal" persistent max-width="500">
      <v-card>
        <v-card-title class="headline" v-if="!showScheduleRedirectData.schedule.payment_enabled">
          O que você quer fazer agora?
        </v-card-title>
        <v-card-text class="pt-5">
          <transaction-payment
              v-if="showScheduleRedirectData.schedule.id && showScheduleRedirectData.schedule.transaction"
              :transaction="showScheduleRedirectData.schedule.transaction"
              :minutes-to-pay="showScheduleRedirectData.schedule.payment_minutes_to_cancel"/>
          <v-btn color="default darken-1" block @click="handleScheduleRedirect(false)" class="mt-5 mb-5">
            <i class="fas fa-calendar-alt"></i>
            <span class="ml-2">Reservar outro horário</span>
          </v-btn>
          <v-btn color="info darken-1" block text @click="handleScheduleRedirect(true)" class="mt-5">
            <i class="fas fa-arrow-right"></i>
            <span class="ml-2">Ir para a reserva</span>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
.schedule-page {
  width: 100%;

  .hours-list {
    .v-list__tile {
      padding: 6px 10px !important;
      height: 62px !important;
    }
  }

  .schedule-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.schedule-notes p {
  margin: 0 !important;
}

.schedule-court-tabs {
  .v-slide-group__next, .v-slide-group__prev {
    min-width: 32px !important;
  }
}

.schedule-court-name {
  font-size: 13px !important;
  letter-spacing: normal !important;
}

.v-tab--active {
  .schedule-court-name {
    font-weight: bold;
  }
}
</style>
