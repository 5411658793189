<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'app-notification',
    data () {
      return {
        showSnackbar: false,
        title: this.$t('app.texts.success'),
        message: '',
        type: 'success',
        timeout: 2000
      }
    },
    computed: {
      ...mapGetters(['notification'])
    },
    watch: {
      notification: function (newValue) {
        this.title = newValue.title || this.$t('app.texts.success')
        this.message = newValue.message || ''
        this.type = newValue.type || 'success'
        this.timeout = (newValue.timeout === 0 || !newValue.timeout ? 4000 : (newValue.timeout || 30000))
        this.showSnackbar = true
      }
    }
  }
</script>

<template>
    <v-snackbar v-model="showSnackbar"
                :color="type"
                :bottom="true"
                :multi-line="!!message"
                :timeout="timeout">
        <div>{{ title }}</div>
        <div v-if="!!message">{{ message}}</div>
        <template v-slot:action="{ attrs }">
            <v-btn dark
                   text
                   small
                   v-bind="attrs"
                   @click="showSnackbar = false">
                <i class="fas fa-times"></i>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<style lang="scss">
    .notification-alert {
        .notification-alert-title {
            font-size: 15px;
            font-weight: 500;
            padding-bottom: 8px;
            color: rgba(255, 255, 255, .8);
        }

        .notification-alert-title,
        .notification-alert-message {
            display: block;
        }
    }
</style>
