import {merge} from 'lodash'
import bus from '@utils/bus'
import Swal from "sweetalert2"

import i18n from '../../../translation'

/**
 * @param options
 * @returns Object
 */
function _handleData (options) {
  const defaultOptions = {
    title: i18n.t('app.texts.success') + '!',
    message: i18n.t('app.texts.dataSaved')
  }
  options = merge(defaultOptions, options)
  if (options.hideLoader !== undefined && options.hideLoader) {
    bus.$emit('hide-loader')
  }
  return options
}

function _showNotification (options) {
  options.html = options.message
  delete options.message
  Swal.fire(options)
}

/**
 * Mostra notificação de sucesso
 * @param options
 */
function _success (options) {
  options = _handleData(options)
  options.type = 'success'
  _showNotification(options)
}

/**
 * @param options
 */
function _warning (options) {
  if (!options.title) {
    options.title = i18n.t('app.texts.warning') + '!'
  }
  options = _handleData(options)
  options.type = 'warning'
  _showNotification(options)
}

/**
 * @param options
 */
function _confirm (options) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    if (!options.title) {
      options.title = i18n.t('app.texts.confirmActionQuestion')
    }
    if (!options.confirmButtonText) {
      options.confirmButtonText = i18n.t('app.texts.confirmActionText')
    }
    if (!options.cancelButtonText) {
      options.cancelButtonText = i18n.t('app.texts.no').toUpperCase()
    }
    const {value: confirm} = await Swal.fire({
      ...{
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#59ae3d',
        cancelButtonColor: '#ff5252',
      },
      ...options
    })
    resolve(confirm)
  })
}

export const success = _success
export const warning = _warning
export const confirm = _confirm
