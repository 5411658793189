<script>
import DrawMatchResult from "./MatchResult.vue";

export default {
  name: 'draw-match',
  components: {DrawMatchResult},
  props: {
    matches: {
      type: Array,
      default: () => []
    },
    round: {
      type: Number
    },
    game: {
      type: Number
    },
    pairNumber: {
      type: Number
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    isByeMatch() {
      return this.match.datetime === null && this.match.winner_pair !== null && this.match.pair_id === null
    },
    match() {
      const match = this.matches.find(item => {
        return item.round === this.round & item.number === this.game
      })
      if (match) {
        return {
          id: match.id,
          pair_id: match['pair' + this.pairNumber + '_id'],
          pair_names: match['pair' + this.pairNumber + '_id'] ? match[`pair${this.pairNumber}_names`] : null,
          pair_number: this.pairNumber,
          datetime: match.datetime,
          winner_pair: match.winner_pair,
          winner: match.winner_pair === match['pair' + this.pairNumber + '_id'],
          list: [],
          sets: Array.isArray(match.sets) ? match.sets : [],
        }
      }
      return {
        id: null,
        pair_id: null,
        pair_names: '',
        pair_number: this.pairNumber,
        winner_pair: null,
        winner: false,
        list: []
      }
    }
  }
}
</script>

<template>
  <div :class="['match', {'-bye': isByeMatch}]">
    <div :class="[
        'match-content',
        {'-winner': match.winner && !!match.winner_pair},
        {'-has-sets': match.sets.length > 0},
        {'-bye': isByeMatch}
      ]">
      <div class="match-box">
        <div class="bye" v-if="isByeMatch">
          <span>BYE</span>
        </div>
        <div v-else v-html="match.pair_names"></div>
      </div>
      <draw-match-result v-if="match.sets.length > 0"
                         :sets="match.sets"
                         :pair-id="match.pair_id"/>
    </div>
  </div>
</template>
