<script>
import ScheduleApiService from '../../../services/domains/Schedule'
import * as notifyService from '../../../services/common/notify'

import bus from '@utils/bus'
import {mapGetters, mapActions} from 'vuex'

const scheduleService = ScheduleApiService.build({})

export default {
  data() {
    return {
      isLoadingData: true,
      invite: {
        id: null,
        schedule: {
          club_id: '',
          client: '',
          start_datetime: '',
          end_datetime: '',
          court: '',
          club: '',
        }
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  mounted() {
    if (this.$route.params.uuid) {
      this.loadInvite(this.$route.params.uuid)
    } else {
      notifyService.warning()
    }
  },
  methods: {
    ...mapActions(['showNotification']),
    loadInvite(uuid) {
      bus.$emit('show-loader')
      scheduleService.getInviteByUuid(uuid)
          .then(response => {
            this.invite = {...response}
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isLoadingData = false
            console.log(e)
          })
    },
    changeStatus(status) {
      const data = {club_id: this.invite.schedule.club_id}
      scheduleService.changeInviteStatus(`invite/${this.invite.id}/${status}`, data)
          .then(response => {
            this.showNotification({
              title: response.title,
              message: response.message
            })
            this.$router.push({name: 'schedule.show', params: {id: response.schedule_id, club_id: response.club_id}})
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div style="width: 100%;">
    <div v-if="!$vuetify.breakpoint.xs" class="page-header flex-header list-header">
      <h2 class="headline page-title">{{ $t('app.domains.schedule.components.invite.pageTitle') }}</h2>
      <div></div>
    </div>
    <v-card class="page-content-card" v-if="invite.id">
      <v-container grid-list-md>
        <v-layout align-center justify-space-between wrap>
          <v-flex sm8 xs12>
            <div class="pb-2">
              <div>
                <strong class="body-2">{{ $t('app.domains.schedule.components.invite.data.invitePlayerText') }}: </strong>
                {{ invite.schedule.client }}
              </div>
              <div>
                <strong class="body-2">{{ $t('app.domains.schedule.components.invite.data.dateText') }}: </strong>
                {{ invite.schedule.start_datetime | dateTimeEnToBr }}h até {{
                  invite.schedule.end_datetime | timeEnToBr
                }}h
              </div>
              <div>
                <strong class="body-2">{{ $t('app.domains.schedule.components.invite.data.courtText') }}: </strong>
                {{ invite.schedule.court }}
              </div>
              <div>
                <strong class="body-2">{{ $t('app.domains.schedule.components.invite.data.clubText') }}: </strong>
                {{ invite.schedule.club }}
              </div>
            </div>
          </v-flex>
          <v-flex sm4 xs12>
            <div class="text-center">
              <div class="mb-5">
                <v-btn large
                       color="primary"
                       @click="changeStatus('accept')">
                  <i class="fas fa-check"></i>
                  <span class="ml-1">{{ $t('app.domains.schedule.components.invite.data.inviteAcceptBtn') }}</span>
                </v-btn>
              </div>
              <v-btn color="error"
                     small
                     @click="changeStatus('deny')">
                <i class="fas fa-times"></i>
                <span class="pl-1">{{ $t('app.domains.schedule.components.invite.data.declineAcceptBtn') }}</span>
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>
