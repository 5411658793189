import Api from '../common/api/Api'

export default class OrderService extends Api {
  constructor () {
    super({domain: 'order'})
  }

  async clubs (data) {
    return this.post({route: 'clubs', cache: 'clubsAvailable', data})
  }

  async my (data) {
    return this.post({route: 'my', data})
  }

  async generateTransaction (data) {
    return this.post({route: 'generate-transaction', data})
  }
}
