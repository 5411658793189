<script>
import LoadingStageGroup from './loadings/LoadingStageGroup.vue'

export default {
  name: 'loading-stage-groups',
  components: {LoadingStageGroup}
}
</script>

<template>
  <div class="skeleton-loading-groups">
    <loading-stage-group/>
    <loading-stage-group/>
    <loading-stage-group/>
  </div>
</template>
