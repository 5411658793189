<script>
  import EmptyList from './EmptyList'

  export default {
    components: {EmptyList},
    name: 'player-list',
    props: {
      list: {
        default: () => [],
        Type: Array
      },
      title: {
        default: function() {
          return this.$t('components.general.playerList.defaultTitle')
        },
        Type: String
      },
      emptyListTitle: {
        default: function() {
          return this.$t('components.general.emptyList.defaultTitle')
        },
        Type: String
      },
      emptyListText: {
        default: function() {
          return this.$t('components.general.playerList.defaultEmptyListText')
        },
        Type: String
      },
      showIcon: {
        default: true,
        Type: Boolean
      }
    }
  }
</script>

<template>
    <div>
        <div class="page-subheader" v-if="title">{{title}}</div>
        <v-list two-line
                v-if="list.length > 0">
            <template v-for="(item, index) in list">
                <v-list-item :key="item.id" v-ripple>
                    <v-list-item-avatar v-if="item.client.user && item.client.user.avatar">
                        <img :src="item.client.user.avatar_path">
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{item.client.name}}</v-list-item-title>
                        <v-list-item-subtitle v-if="item.client.user && item.client.user.phone">
                            {{item.client.user.phone | phone}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider :key="index" v-if="index < list.length - 1"/>
            </template>
        </v-list>
        <empty-list v-if="list.length === 0"
                    :title="emptyListTitle"
                    :message="emptyListText"
                    :show-icon="showIcon"/>
    </div>
</template>
