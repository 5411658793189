import TermsConditions from './components/TermsConditions'
import PrivacyPolicies from './components/PrivacyPolicies'

import i18n from '../../../translation'

export default [
  {
    path: i18n.t('app.domains.common.routes.termsConditions.path'),
    component: TermsConditions,
    name: 'common.terms-conditions',
    meta: {
      auth: false,
      breadcrumb: {
        name: i18n.t('app.domains.common.routes.termsConditions.title')
      }
    }
  },
  {
    path: i18n.t('app.domains.common.routes.privacyPolicies.path'),
    component: PrivacyPolicies,
    name: 'common.privacy-policies',
    meta: {
      auth: false,
      breadcrumb: {
        name: i18n.t('app.domains.common.routes.privacyPolicies.title')
      }
    }
  }
]
