import Login from './components/Login'
import AuthTrouble from './components/AuthTrouble'

import i18n from '../../../translation'

export default [
  {
    path: i18n.t('app.domains.auth.routes.login.path'),
    component: Login,
    name: 'auth.login',
    meta: {
      auth: false,
      breadcrumb: {
        name: i18n.t('app.domains.auth.routes.login.title')
      }
    }
  },
  {
    path: i18n.t('app.domains.auth.routes.trouble.path'),
    component: AuthTrouble,
    name: 'auth.trouble',
    meta: {
      auth: false,
      breadcrumb: {
        name: i18n.t('app.domains.auth.routes.trouble.title')
      }
    }
  }
]
