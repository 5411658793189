<script>
import TransactionPayment from "./TransactionPayment"

export default {
  name: 'transaction-payment-modal',
  components: {TransactionPayment},
  props: ['transaction', 'show'],
  computed: {
    showPixModal: {
      get() {
        return this.show
      },
      set(value) {
        this.$emit('update:show', value)
      }
    },
    minutesToPay() {
      if (this.transaction && this.transaction.payment_limit_minutes !== null) {
        return this.transaction.payment_limit_minutes
      }
      return null
    },
  }
}
</script>

<template>
  <v-dialog v-if="showPixModal" v-model="showPixModal" max-width="450" :persistent="!!minutesToPay">
    <v-card>
      <v-card-text>
        <h4 class="text-center pt-3 mb-2">
          {{ $t('components.general.transactionPayment.text') }}
        </h4>
        <transaction-payment v-if="show"
                             @closeModal="showPixModal = false"
                             :transaction="transaction"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
