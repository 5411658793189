<script>
import AppHeaderNotifications from "./HeaderNotificacoes"
import AppGlobalFilters from "./GlobalFilters"

import UserApiService from "../../services/domains/User"
import AuthApiService from "../../services/domains/Auth"
import * as notifyService from "../../services/common/notify"

import bus from "@utils/bus"
import helperMixin from "@mixins/helper"
import { mapGetters, mapActions } from "vuex"

import { required, minLength } from "vuelidate/lib/validators"

const userService = UserApiService.build({})
const authService = AuthApiService.build({})

export default {
  name: "app-header",
  mixins: [helperMixin],
  components: {AppHeaderNotifications, AppGlobalFilters},
  props: ['customLayout'],
  data() {
    return {
      isSubmitted: false,
      showModalUpdatePassword: false,
      showPassword: false,
      showPasswordConfirmation: false,
      form: {
        password: "",
        password_confirmation: ''
      }
    }
  },
  validations: {
    form: {
      password: {required, minLength: minLength(6)},
      password_confirmation: {required, minLength: minLength(6)}
    }
  },
  computed: {
    ...mapGetters([
      "isLogged",
      "currentUser",
      "showSidebar",
      "pageTitle",
      "pageSubTitle",
      "showHeader"
    ]),
    userFullName() {
      if (this.isLogged) {
        return this.currentUser.name
      }
      return ""
    },
    initials() {
      if (this.userFullName) {
        if (this.userFullName.indexOf(" ") !== -1) {
          const name = this.userFullName.split(" ")
          return (
              name[0].charAt(0).toUpperCase() +
              (name[name.length - 1].charAt(0).toUpperCase() || "")
          )
        }
        return this.userFullName.charAt(0).toUpperCase()
      }
      return "U"
    },
    avatar() {
      if (this.isLogged) {
        if (this.currentUser.avatar) {
          return this.currentUser.avatar
        }
      }
      return ""
    }
  },
  mounted() {
    bus.$on("update-password", () => this.handleModalUpdatePassword())
  },
  methods: {
    ...mapActions(["logout", "toggleSidebar"]),
    onLogout() {
      authService.logout().then(() => {
        this.logout().then(() => {
          this.$router.push({name: "home"})
        })
      })
    },
    handleModalUpdatePassword() {
      this.showModalUpdatePassword = !this.showModalUpdatePassword
      if (this.showModalUpdatePassword) {
        this.$v.form.$reset()
        setTimeout(() => {
          this.$refs.currentPassword.focus()
        }, 300)
      }
    },
    updatePassword() {
      bus.$emit("show-loader")
      const data = {
        id: this.currentUser.id,
        password: this.form.password,
        password_confirmation: this.form.password_confirmation
      }
      userService
          .updatePassword(data)
          .then(response => {
            bus.$emit("hide-loader")
            this.handleModalUpdatePassword()
            notifyService.success({
              hideLoader: true,
              message: response.message
            })
            this.isSubmitted = false
          })
          .catch(e => {
            bus.$emit("hide-loader")
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div class="theme--dark">
    <v-app-bar fixed app :class="['app-toolbar', {'-custom-layout': customLayout}]" clipped-left v-if="showHeader">
      <v-app-bar-nav-icon @click="toggleSidebar(!showSidebar)" class="white--text"></v-app-bar-nav-icon>
      <v-spacer/>
      <v-toolbar-title class="mr-2 ml-1 align-center white--text">
        <span class="app-header-title subheading">{{ pageTitle }}</span>
      </v-toolbar-title>
      <v-spacer/>
      <template v-if="isLogged">
        <app-global-filters v-if="false"/>
        <app-header-notifications/>
        <v-btn icon class="header-user-avatar mr-1" :title="userFullName" :to="{name: 'user.show'}">
          <v-avatar size="38" v-if="avatar">
            <img :src="avatar"/>
          </v-avatar>
          <span class="white--text title" v-if="!avatar">{{ initials }}</span>
        </v-btn>
      </template>
    </v-app-bar>
    <v-dialog v-model="showModalUpdatePassword"
              :width="isMobile().any() ? '90%' : '40%'">
      <v-card class="modal-alterar-senha">
        <v-card-title class="primary white--text">
          {{ $t("components.main.header.password.modal.title") }}
        </v-card-title>
        <v-card-text>
          <v-container fluid grid-list-md>
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                    v-model="form.password"
                    :type="showPassword ? 'text' : 'password'"
                    ref="password"
                    :error-messages="validationMessageField($v.form.password, ['required', 'minLength'])"
                    @input="$v.form.password.$touch()"
                    @blur="$v.form.password.$touch()"
                    :label="$t('components.main.header.password.modal.newPasswordLabel')"
                    required>
                  <template v-slot:append>
                    <div @click="showPassword = !showPassword">
                                            <span v-if="showPassword" key="passwordVisibility"><i
                                                class="fas fa-eye"></i></span>
                      <span v-else key="passwordVisibilityOff"><i
                          class="fas fa-eye-slash"></i></span>
                    </div>
                  </template>
                </v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                    v-model="form.password_confirmation"
                    :type="showPasswordConfirmation ? 'text' : 'password'"
                    ref="password"
                    :error-messages="validationMessageField($v.form.password_confirmation, ['required', 'minLength'])"
                    @input="$v.form.password_confirmation.$touch()"
                    @blur="$v.form.password_confirmation.$touch()"
                    :label="$t('components.main.header.password.modal.newPasswordConfirmationLabel')"
                    required>
                  <template v-slot:append>
                    <div @click="showPasswordConfirmation = !showPasswordConfirmation">
                                            <span v-if="showPasswordConfirmation" key="passwordVisibility"><i
                                                class="fas fa-eye"></i></span>
                      <span v-else key="passwordVisibilityOff"><i
                          class="fas fa-eye-slash"></i></span>
                    </div>
                  </template>
                </v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success"
                 @click="updatePassword"
                 :disabled="isSubmitted || $v.form.$invalid">
            {{ $t("components.main.header.password.modal.saveBtn") }}
          </v-btn>
          <v-btn text @click="handleModalUpdatePassword">{{ $t("app.texts.close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
