<script>
import {mapActions} from 'vuex'
import moment from 'moment';

import bus from "@utils/bus"
import WorkerInterval from "@utils/workerInterval";

const QRCode = require("qrcode-svg")

export default {
  name: 'transaction-payment',
  props: {
    transaction: {
      type: Object
    }
  },
  data() {
    return {
      pix: '',
      minutes: 0,
      diffInSeconds: 0,
      currentTimeFormat: '',
      interval: null,
    }
  },
  computed: {
    minutesToPay() {
      if (this.transaction && this.transaction.payment_limit_minutes !== null) {
        return this.transaction.payment_limit_minutes
      }
      return null
    },
    timerProgress() {
      return this.diffInSeconds * 100 / (this.minutes * 60)
    }
  },
  mounted() {
    this.openPixModal()
  },
  methods: {
    ...mapActions(['showNotification']),
    openPixModal() {
      setTimeout(() => {
        const svg = (new QRCode({
          content: this.transaction.gateway_data.pix_qr_code,
          padding: 0,
          width: 200,
          height: 200,
          color: "#000000",
          background: "#ffffff",
          ecl: "M",
        })).svg()
        if (this.transaction.payment_limit_minutes !== null && this.transaction.payment_limit_minutes > 0) {
          this.startCountdown(this.transaction.payment_limit_minutes)
        }
        this.$refs['pixContainer'].innerHTML = svg
        this.pix = this.transaction.gateway_data.pix_qr_code
      }, 100)
    },
    copyText(ref) {
      let testingCodeToCopy = this.$refs[ref]
      testingCodeToCopy.select()
      document.execCommand('copy')
      window.getSelection().removeAllRanges()
      this.showNotification({
        title: 'Código PIX Copia e Cola copiado!',
        timeout: 3000
      })
    },
    startCountdown(minutes) {
      const expirationDate = this.transaction.gateway_data.pix_expiration_date
      const currentDatetime = moment().subtract(2, 'seconds');
      this.minutes = minutes
      let startDuration = currentDatetime
      const endDuration = moment(expirationDate)
      if (startDuration.isSameOrBefore(endDuration)) {
        this.interval = new WorkerInterval(() => {
          this.diffInSeconds = endDuration.diff(startDuration, 'seconds')
          this.currentTimeFormat = moment.utc(this.diffInSeconds * 1000).format('mm:ss')
          startDuration.add(1, 'seconds')
          if (startDuration.isAfter(endDuration)) {
            this.minutes = 0
            this.diffInSeconds = 0
            if(this.interval) {
              this.interval?.stop()
              this.interval = null
            }
            this.showNotification({
              title: 'Código PIX expirado',
              type: 'warning',
              timeout: 3000
            })
            this.$emit('closeModal')
            bus.$emit('qrcode-timer-expired')
          }
        }, 1000);
      } else {
        this.minutes = 0
        if(this.interval) {
          this.interval?.stop()
          this.interval = null
        }
        this.$emit('closeModal')
        bus.$emit('qrcode-timer-expired')
      }
    }
  },
  destroyed() {
    if(this.interval) {
      this.interval?.stop()
      this.interval = null
    }
  }
}
</script>

<template>
  <div>
    <h2 class="text-center mb-3 primary--text">
      {{ $t('components.general.transactionPayment.amountText') }}: {{ transaction.amount | currencyEnToBr }}
    </h2>
    <div ref="pixContainer" class="text-center transaction-pix-image-qrcode">
      {{ $t('components.general.transactionPayment.generateQrCodeText') }}
    </div>
    <div class="mt-3">
      <v-text-field
          outlined
          v-model="pix"
          type="text"
          hide-details
          class="pix-qrcode"
      >
        <template v-slot:append-outer>
          <v-btn fab
                 small
                 @click="copyText('pixCopyCodeText')">
            <i class="fas fa-copy"></i>
          </v-btn>
        </template>
      </v-text-field>
    </div>
    <div class="mt-3" v-if="minutesToPay && minutes > 0">
      <div>O tempo para você pagar acaba em:</div>
      <div class="fs-18 font-weight-bold pb-1">
        {{ currentTimeFormat }}
      </div>
      <v-progress-linear
          height="6"
          color="error"
          :value="timerProgress"/>
    </div>
    <div class="mt-3">
      <input type="text" :value="transaction.gateway_data.pix_qr_code" ref="pixCopyCodeText"
             style="opacity: 0;position: absolute;">
      <v-btn color="blue" block class="white--text" @click="copyText('pixCopyCodeText')">
        {{ $t('components.general.transactionPayment.pixCopyCodeText') }}
      </v-btn>
    </div>
  </div>
</template>
