<script>
import EmptyList from '../../../components/general/EmptyList';
import LoadingContent from "../../../components/general/placeholders/LoadingContent";
import TransactionPaymentModal from "../../../components/general/TransactionPaymentModal"

import OrderService from '../../../services/domains/Order';

import bus from '@utils/bus';
import {sortBy} from 'lodash';
import moment from 'moment';
import * as notifyService from '@/app/services/common/notify';
import {mapGetters} from 'vuex'

const orderService = OrderService.build({});

export default {
  components: {LoadingContent, EmptyList, TransactionPaymentModal},
  data() {
    return {
      isLoadingData: true,
      filters: {
        club_id: '',
        dates: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
      },
      dependencies: {
        clubs: [],
        cities: [],
      },
      showModalDatePicker: false,
      data: {
        headers: [
          {
            text: '',
            value: 'order'
          },
          {
            text: this.$t('app.domains.order.components.list.table.headers.due_date'),
            value: 'due_date'
          },
          {
            text: this.$t('app.domains.order.components.list.table.headers.description'),
            value: 'description'
          },
          {
            text: this.$t('app.domains.order.components.list.table.headers.amount'),
            value: 'amount',
            align: 'right'
          },
          {
            text: '',
          }
        ],
        list: [],
        listPayments: [],
      },
      transaction: null,
      showPixModal: false,
      showProductsModal: false,
      products: [],
      selectedOrders: [],
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    filtersDates: {
      get() {
        if (this.filters.dates.length > 0) {
          if (this.filters.dates.length === 1) {
            return moment(this.filters.dates[0]).format('DD/MM/YYYY');
          } else {
            return 'De ' + moment(this.filters.dates[0]).format('DD/MM/YYYY') + ' até ' + 'De ' + moment(this.filters.dates[1]).format('DD/MM/YYYY');
          }
        }
        return '';
      },
      set(value) {
        if (!value) {
          this.filters.dates.splice(0);
        } else {
          this.filters.dates = [...value];
        }
      }
    },
    websocketKey() {
      return `transactionUpdate`
    },
    websocketChannelName() {
      return `${this.websocketKey}.${this.currentUser.id}`
    },
    websocketChannelListen() {
      return `.${this.websocketKey}`
    },
    getTotalSelectedOrders() {
      return this.selectedOrders.reduce((total, order) => total + order.amount, 0)
    },
    listCanBePaid() {
      return this.data.list.filter(item => this.canPayOrder(item))
    }
  },
  async mounted() {
    this.startWebsocket();
    await this.getClubs();
    this.loadOrders();
  },
  methods: {
    startWebsocket() {
      window.Echo.channel(this.websocketChannelName)
          .listen(this.websocketChannelListen, (event) => {
            this.loadOrders()
            this.showPixModal = false
            if (event.data && event.data.status) {
              const {status, message} = event.data
              status === 'success'
                  ? notifyService.success({message})
                  : notifyService.warning({message})
            }
          })
    },
    getClubs() {
      return new Promise(resolve => {
        this.isLoadingCities = true;
        orderService.clubs().then(response => {
          // TODO: Ajustar ordenação no back (Está sendo feito aqui pq por algum motivo bizarro a ordenação do back não ta funcionando)
          this.dependencies.clubs = [...sortBy(response, ['city', 'name'], ['desc', 'asc'])];
          resolve();
        });
      });
    },
    prepareSearchData() {
      const search = {};
      if (this.filters.club_id) {
        search.club_id = this.filters.club_id;
      }
      if (this.filters.dates.length > 0) {
        if (this.filters.dates.length === 1) {
          search.start_date = moment(this.filters.dates[0]).format('YYYY-MM-DD');
          search.end_date = moment(this.filters.dates[0]).format('YYYY-MM-DD');
        } else {
          search.start_date = moment(this.filters.dates[0]).format('YYYY-MM-DD');
          search.end_date = moment(this.filters.dates[1]).format('YYYY-MM-DD');
        }
      }
      return search;
    },
    loadOrders() {
      this.isLoadingData = true;
      this.data.list.splice(0);
      this.data.listPayments.splice(0);
      this.selectedOrders.splice(0);
      const data = this.prepareSearchData();
      if (!data.club_id) {
        this.isLoadingData = false;
        bus.$emit('hide-loader');
        return;
      }
      orderService.my(data)
          .then(response => {
            this.data.list = [...response.filter(item => !item.group)];
            this.data.listPayments = [...response.filter(item => item.group)];
            this.isLoadingData = false;
            bus.$emit('hide-loader');
          })
          .catch(e => {
            this.isLoadingData = false;
            bus.$emit('hide-loader');
            console.log(e);
          });
    },
    async generateTransaction() {
      const confirm = await notifyService.confirm({title: this.$t('app.domains.order.components.list.generateTransactionMessage')});
      if (confirm) {
        bus.$emit('show-loader');
        this.isLoadingData = true;
        const data = {
          club_id: this.filters.club_id,
          orders: this.selectedOrders.map(item => {
            return {portion_id: item.id}
          })
        }

        orderService.generateTransaction(data)
            .then((response) => {
              this.loadOrders();
              this.showPaymentModal(response.transaction);
              bus.$emit('hide-loader');
              this.isLoadingData = false;
            })
            .catch(e => {
              this.isLoadingData = false;
              bus.$emit('hide-loader');
              console.log(e);
            });
      }
    },
    showPaymentModal(transaction) {
      this.transaction = transaction
      this.showPixModal = true
    },
    showDetails(portion) {
      this.products = [...portion.products]
      this.showProductsModal = true
    },
    clearSelection() {
      this.selectedOrders.splice(0)
    },
    canPayOrder(item) {
      return !item.transaction || item.transaction.status === 'canceled'
    },
    selectAll() {
      if (this.selectedOrders.length) {
        this.selectedOrders.splice(0)
      } else {
        this.selectedOrders = [...this.listCanBePaid]
      }
    }
  }
};
</script>

<template>
  <div>
    <div v-if="!$vuetify.breakpoint.xs" class="page-header flex-header list-header">
      <h2 class="headline page-title">{{ $t('app.domains.order.components.list.pageTitle') }}</h2>
      <div></div>
    </div>
    <v-card :class="['page-content-card', {'-selection' : selectedOrders.length}]">
      <v-container fluid grid-list-md>
        <v-layout row wrap>
          <v-flex md4 xs12>
            <v-select :items="dependencies.clubs"
                      v-model="filters.club_id"
                      :label="$t('app.domains.order.components.list.filters.clubLabel')"
                      :placeholder="$t('app.domains.order.components.list.filters.clubLabel')"
                      :no-data-text="$t('app.domains.order.components.list.emptyListMessage')"
                      item-text="name"
                      item-value="id"
                      @change="loadOrders"
                      clearable
                      hide-details/>
          </v-flex>
          <v-flex md4 xs12>
            <v-menu ref="filtersStartDate"
                    :close-on-content-click="false"
                    v-model="showModalDatePicker"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                    :label="$t('app.domains.registration.components.list.filters.dateLabel')"
                    :placeholder="$t('app.domains.registration.components.list.filters.dateLabel')"
                    readonly
                    v-model="filtersDates"
                    class="v-field--hide-details"
                    clearable
                    v-on="on">
                  <template v-slot:prepend>
                    <i class="fas fa-calendar-alt"></i>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="filters.dates"
                             range
                             no-title
                             color="primary"
                             scrollable
                             :selected-items-text="filtersDates"
                             locale="pt-br"/>
            </v-menu>
          </v-flex>
          <v-flex md1 xs12>
            <v-btn color="secondary" class="mt-2"
                   :title="$t('app.domains.order.components.list.filters.filterBtn')"
                   @click="loadOrders">
              <i class="fas fa-filter"></i>
              <span>{{ $t('app.domains.order.components.list.filters.filterBtn') }}</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
      <v-divider></v-divider>
      <template v-if="data.listPayments.length">
        <v-container fluid grid-list-md>
          <v-layout row wrap>
            <v-flex xs12>
              <h4>Pagamentos Pendentes</h4>
            </v-flex>
            <template v-for="(payment, index) in data.listPayments">
              <v-flex xs12 :key="`${index}-amount`">
                <span class="pr-4">{{ payment.amount | currencyEnToBr }}</span>
                <span class="pr-4">{{ payment.description }}</span>
                <div class="d-inline-flex flex-column">
                  <v-btn color="success"
                         class="mb-1"
                         small
                         @click="showPaymentModal(payment.transaction)">
                    <i class="fas fa-money-bill-alt"></i>
                    {{ $t('app.domains.order.components.list.generatePaymentBtn') }}
                  </v-btn>
                  <div class="text-danger fs-14">
                    Expira em: <strong>{{ payment.transaction.payment_limit_datetime | dateTimeEnToBr }}h</strong>
                  </div>
                </div>
              </v-flex>
            </template>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
      </template>
      <v-data-table :headers="data.headers"
                    :items="data.list"
                    v-if="!isLoadingData && data.list.length > 0"
                    :no-data-text="$t('app.texts.listEmptyMessage')"
                    disable-sort
                    hide-default-footer>
        <template v-slot:[`header.order`]>
          <v-checkbox @click="selectAll"
                      :value="selectedOrders.length && selectedOrders.length === listCanBePaid.length">
          </v-checkbox>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
          <tr v-for="item in items" :key="item.id">
            <td>
              <v-checkbox v-if="canPayOrder(item)"
                          multiple
                          v-model="selectedOrders"
                          :value="item">
              </v-checkbox>
            </td>
            <td>{{ item.due_date | dateEnToBr }}</td>
            <td><span v-html="item.description"></span></td>
            <td style="min-width: 110px;" class="text-right">{{ item.amount | currencyEnToBr }}</td>
            <td>
              <v-btn color="info"
                     :class="[$vuetify.breakpoint.xs ? 'mb-1' : 'mr-1']"
                     :title="$t('app.domains.order.components.list.table.items.actions.showBtn')"
                     small
                     @click="showDetails(item)">
                <i class="fas fa-search"></i>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-data-table>
      <transaction-payment-modal :transaction="transaction"
                                 :show.sync="showPixModal"/>
      <v-dialog v-model="showProductsModal" max-width="600">
        <v-card>
          <v-card-title class="text-center">{{ $t('app.domains.order.components.productsTable.title') }}</v-card-title>
          <v-card-text class="pt-3">
            <table class="stage-groups-table" v-if="products.length">
              <thead>
              <tr>
                <th class="text-left">{{
                    $t('app.domains.order.components.productsTable.table.headers.description')
                  }}
                </th>
                <th class="text-center">{{
                    $t('app.domains.order.components.productsTable.table.headers.quantity')
                  }}
                </th>
                <th class="text-right">{{ $t('app.domains.order.components.productsTable.table.headers.price') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(product, index) in products" :key="index">
                <td class="text-left">{{ product.description }}</td>
                <td class="text-center">{{ product.quantity }}</td>
                <td class="text-right">{{ product.price | currencyEnToBr }}</td>
              </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-dialog>
      <empty-list v-if="!isLoadingData && !filters.club_id"
                  :message="$t('app.domains.order.components.list.clubNotSelected')"/>
      <empty-list v-if="!isLoadingData && data.list.length === 0 && filters.club_id"
                  :message="$t('app.domains.order.components.list.emptyListMessage')"/>
      <template v-if="isLoadingData">
        <loading-content :placeholders="3"/>
      </template>
    </v-card>
    <div class="payment-orders-box" v-if="selectedOrders.length">
      <div class="payment-orders-box-total">
        {{ $t('app.domains.order.components.list.totalLabel') }}: {{ getTotalSelectedOrders | currencyEnToBr }}
      </div>
      <div class="payment-orders-box-buttons text-center">
        <v-btn ripple :block="$vuetify.breakpoint.xs"
               :class="[
              'primary',
              {'mb-2': $vuetify.breakpoint.xs},
              {'mr-2': !$vuetify.breakpoint.xs}
            ]"
               @click="generateTransaction()">
          <i class="fas fa-check"></i>
          <span class="ml-1">
          {{ $t('app.domains.order.components.list.generatePaymentBtn') }}
        </span>
        </v-btn>
        <v-btn ripple :block="$vuetify.breakpoint.xs" @click="clearSelection()">
          <i class="fas fa-trash"></i>
          <span class="ml-1">
          {{ $t('app.domains.order.components.list.clearSelectionBtn') }}
        </span>
        </v-btn>
      </div>
    </div>
  </div>
</template>
